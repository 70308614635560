/// <reference types="./model.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $editor from "../../../elements/editor/editor.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";

export class Model extends $CustomType {
  constructor(editor, bold, italic, underline, ratio) {
    super();
    this.editor = editor;
    this.bold = bold;
    this.italic = italic;
    this.underline = underline;
    this.ratio = ratio;
  }
}

export function init() {
  return new Model(new $option.None(), false, false, false, 1.0);
}

export function set_editor(model, editor) {
  let editor$1 = new $option.Some(editor);
  let _record = model;
  return new Model(
    editor$1,
    _record.bold,
    _record.italic,
    _record.underline,
    _record.ratio,
  );
}

export function update_state(model) {
  let $ = model.editor;
  if ($ instanceof $option.None) {
    return model;
  } else {
    let editor = $[0];
    let bold = $editor.is_bold(editor);
    let italic = $editor.is_italic(editor);
    let underline = $editor.is_underline(editor);
    let _record = model;
    return new Model(_record.editor, bold, italic, underline, _record.ratio);
  }
}

export function require_editor(model, next) {
  let $ = model.editor;
  if ($ instanceof $option.Some) {
    let editor = $[0];
    return next(editor);
  } else {
    return [model, $effect.none()];
  }
}
