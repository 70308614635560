/// <reference types="./projects.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { makeError } from "../gleam.mjs";
import * as $add from "../view/projects/add.mjs";
import * as $overview from "../view/projects/overview.mjs";
import * as $show from "../view/projects/show.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.Projects)) {
    throw makeError(
      "let_assert",
      "view/projects",
      12,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  if (route instanceof $route.OnGoing) {
    return $overview.view(model);
  } else if (route instanceof $route.Previous) {
    return $overview.view(model);
  } else if (route instanceof $route.ProjectProposals &&
  route.proposal instanceof $route.ShowProposal) {
    let id = route.proposal.id;
    let sheet = route.proposal.sheet;
    let page = route.proposal.page;
    return $show.view(model, id, sheet, page);
  } else if (route instanceof $route.AddProposal) {
    let project_id = route.project_id;
    let loading = route.loading;
    let _pipe = $add.view(model, project_id, loading);
    return $el.map(
      _pipe,
      (var0) => { return new $msg.MsgHandledProposalBuilder(var0); },
    );
  } else {
    return $el.none();
  }
}
