import { Chart } from 'chart.js/auto'

class PieChart extends HTMLElement {
  #shadow: ShadowRoot
  #canvas?: HTMLCanvasElement
  datasets: any

  constructor() {
    super()
    this.#shadow = this.attachShadow({ mode: 'open' })
  }

  connectedCallback() {
    this.#render()
  }

  #render() {
    const datasets = this.datasets.toArray()
    const emptyState =
      datasets.length > 0
        ? datasets
        : [
            {
              colors: { toArray: () => ['#F5F6F6'] },
              data: { toArray: () => [100] },
            },
          ]
    const wrapper = document.createElement('div')
    wrapper.style.position = 'relative'
    wrapper.style.maxWidth = '400px'
    wrapper.style.maxHeight = '150px'
    this.#canvas = document.createElement('canvas')
    wrapper.appendChild(this.#canvas)
    this.#shadow.appendChild(wrapper)

    new Chart(this.#canvas, {
      type: 'doughnut',
      data: {
        labels: ['Won', 'Loss'],
        datasets: emptyState.map((dataset: any) => ({
          backgroundColor: dataset.colors.toArray(),
          data: dataset.data.toArray(),
        })),
      },
      options: {
        responsive: true,
        animation: false,
        events: [],
        datasets: {
          doughnut: { borderRadius: 5 },
        },
        elements: {
          arc: { borderAlign: 'inner', circular: true },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })
  }

  // Lifecycle functions.
  disconnectedCallback() {}
  adoptedCallback() {}
  attributeChangedCallback() {}
}

export function register() {
  customElements.define('pie-chart', PieChart)
}
