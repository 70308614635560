/** Define the possible observed attributes used to pass informations to
 * children editor elements. */
export type Attribute = (typeof attributes)[keyof typeof attributes]

/** Attributes is used to easily define observed attributes, and document
 * the different attributes. */
export const attributes = {
  /** `ratio` is a float, between 1.5 and 0.75. This represents the current
   * font-size as `rem`. */
  ratio: 'ratio',
  /** `headerColor` defines the header cells background color for tables. */
  headerColor: 'header-color',
  /** `borderColor` defines the border color for tables. */
  borderColor: 'border-color',
  /** `selectedCellColor` defines selected cells background for tables. */
  selectedCellColor: 'selected-cell-color',
} as const

// Formatting breaks the nice alignment here. Let's enjoy the improved
// readability by disabling prettier only for that function.
// prettier-ignore
export function styles(ratio: string) {
  const base = { '--font-size': `${ratio}rem` }
  switch (ratio) {
    case '0.75': return { ...base, '--line-height': '1.125rem' }
    case '1.25': return { ...base, '--line-height': '1.875rem' }
    case '1.5':  return { ...base, '--line-height': '2.25rem' }

    case '1.0':
    default:
      return { ...base, '--line-height': '1.5rem' }
  }
}

// prettier-ignore
export function getProperty(name: string) {
  switch (name) {
    case attributes.borderColor: return '--border-color'
    case attributes.headerColor: return '--header-background'
    case attributes.selectedCellColor: return '--selected-cell-background'
    default: throw new Error(`No property for name: ${name}`)
  }
}
