/// <reference types="./arrow_up_line.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function arrow_up_line() {
  let content = "<path d=\"M9.99999 9.64854L5.87499 13.7735L4.69666 12.5952L9.99999 7.29187L15.3033 12.5952L14.125 13.7735L9.99999 9.64854Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 21"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
