/// <reference types="./underline.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function underline() {
  let content = "<path d=\"M6.66665 3.12524V10.6252C6.66665 12.4662 8.15903 13.9586 9.99998 13.9586C11.8409 13.9586 13.3333 12.4662 13.3333 10.6252V3.12524H15V10.6252C15 13.3867 12.7614 15.6252 9.99998 15.6252C7.23855 15.6252 4.99998 13.3867 4.99998 10.6252V3.12524H6.66665ZM3.33331 17.2919H16.6666V18.9586H3.33331V17.2919Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 21"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
