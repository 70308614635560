/// <reference types="./connectors.d.mts" />
import * as $app_config from "../../app_config/app_config.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $confluence from "../../common/data/connector/confluence.mjs";
import * as $notion from "../../common/data/connector/notion.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $regexp from "../../gleam_regexp/gleam/regexp.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $window from "../../plinth/plinth/browser/window.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $ask from "../frontend/ask.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $error from "../frontend/error.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { toList, makeError, divideInt } from "../gleam.mjs";

function handle_fetch_connector_data(data, connector) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (_) => {
          let _pipe = $ask.to(new $ask.Heimdall(), toList(["/refresh-data"]));
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.query(
            _pipe$1,
            "connector",
            $connector.to_string(connector),
          );
          $ask.run(_pipe$2)
          return undefined;
        },
      );
    },
  );
}

function open_connector_popup(connector, access_token) {
  let connector$1 = $connector.to_string(connector);
  let $ = $uri.parse($app_config.loki_endpoint());
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "update/connectors",
      173,
      "open_connector_popup",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let uri = $[0];
  let query = (() => {
    let _pipe = $uri.query_to_string(
      toList([["access_token", access_token], ["connector", connector$1]]),
    );
    return new $option.Some(_pipe);
  })();
  let uri$1 = (() => {
    let _pipe = (() => {
      let _record = uri;
      return new $uri.Uri(
        _record.scheme,
        _record.userinfo,
        _record.host,
        _record.port,
        uri.path + "/authorize",
        query,
        _record.fragment,
      );
    })();
    return $uri.to_string(_pipe);
  })();
  let $1 = [400, 600];
  let width = $1[0];
  let height = $1[1];
  let win = $window.self();
  let left = $window.screen_x(win) + (divideInt(
    ($window.inner_width(win) - width),
    2
  ));
  let top = $window.screen_y(win) + (divideInt(
    ($window.inner_height(win) - height),
    2
  ));
  let _pipe = toList([
    "popup",
    "height=" + $int.to_string(height),
    "width=" + $int.to_string(width),
    "left=" + $int.to_string(left),
    "top=" + $int.to_string(top),
  ]);
  let _pipe$1 = $string.join(_pipe, ",");
  let _pipe$2 = ((_capture) => {
    return $window.open(uri$1, "steerlab:connectors:authorize:popup", _capture);
  })(_pipe$1);
  return $result.replace_error(
    _pipe$2,
    new $error.NilError("Opening new window"),
  );
}

function handle_connector_connection(data, connector) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          if (connector instanceof $connector.Steerlab) {
            return undefined;
          } else if (connector instanceof $connector.SteerlabQna) {
            return undefined;
          } else if (connector instanceof $connector.GoogleDrive) {
            let $ = open_connector_popup(connector, access_token);
            
            return undefined;
          } else if (connector instanceof $connector.OneDrive) {
            let $ = open_connector_popup(connector, access_token);
            
            return undefined;
          } else if (connector instanceof $connector.Slack) {
            let $ = open_connector_popup(connector, access_token);
            
            return undefined;
          } else if (connector instanceof $connector.Confluence) {
            let disabled = $confluence.is_submit_disabled(
              data.connectors.confluence,
            );
            return $bool.guard(
              disabled,
              undefined,
              () => {
                let connector$1 = $connector.to_string(connector);
                let _pipe = $ask.to(new $ask.Loki(), toList(["/api-token"]));
                let _pipe$1 = $ask.via(_pipe, new $http.Post());
                let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                let _pipe$3 = $ask.with$(
                  _pipe$2,
                  $json.object(
                    toList([
                      ["token", $confluence.encode(data.connectors.confluence)],
                      ["connector", $json.string(connector$1)],
                    ]),
                  ),
                );
                let _pipe$4 = $ask.notify(
                  _pipe$3,
                  (_) => { return dispatch(new $msg.FetchUserData()); },
                );
                $ask.run(_pipe$4)
                return undefined;
              },
            );
          } else {
            let disabled = $notion.is_submit_disabled(data.connectors.notion);
            return $bool.guard(
              disabled,
              undefined,
              () => {
                let connector$1 = $connector.to_string(connector);
                let _pipe = $ask.to(new $ask.Loki(), toList(["/api-token"]));
                let _pipe$1 = $ask.via(_pipe, new $http.Post());
                let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                let _pipe$3 = $ask.with$(
                  _pipe$2,
                  $json.object(
                    toList([
                      ["token", $notion.encode(data.connectors.notion)],
                      ["connector", $json.string(connector$1)],
                    ]),
                  ),
                );
                let _pipe$4 = $ask.notify(
                  _pipe$3,
                  (_) => { return dispatch(new $msg.FetchUserData()); },
                );
                $ask.run(_pipe$4)
                return undefined;
              },
            );
          }
        },
      );
    },
  );
}

function update_google_folder_id(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $effect.from(
            (_) => {
              let at = toList(["organizations", org_id, "connectors-settings"]);
              let body = (() => {
                let _pipe = data.connectors.google_folder_id;
                let _pipe$1 = new $connector_settings.GoogleDrive(_pipe);
                return $connector_settings.encode(_pipe$1);
              })();
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.with$(_pipe$1, body);
              let _pipe$3 = $ask.bearing(_pipe$2, access_token);
              let _pipe$4 = $ask.expect(_pipe$3, $connector_settings.decode);
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

function notion_page_regex() {
  let $ = $regexp.from_string(
    "https:\\/\\/www\\.notion\\.so\\/[a-zA-Z0-9_-]+\\/[a-zA-Z0-9_-]+-([a-f0-9]{32})$",
  );
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "update/connectors",
      218,
      "notion_page_regex",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let r = $[0];
  return r;
}

export function handle_on_connectors(data, msg) {
  if (msg instanceof $msg.UserFetchedConnectorData) {
    let connector = msg[0];
    let _pipe = handle_fetch_connector_data(data, connector);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserAskedConnectingTo) {
    let connector = msg[0];
    let _pipe = handle_connector_connection(data, connector);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.ApiReturnedConnectorData) {
    let connector = msg[0];
    let connected = msg[1];
    let _pipe = data.connectors.fetched;
    let _pipe$1 = $list.key_set(_pipe, connector, connected);
    let _pipe$2 = ((fetched) => {
      let _record = data.connectors;
      return new $data.Connectors(
        fetched,
        _record.confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })(_pipe$1);
    let _pipe$3 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe$2,
    );
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedConfluenceApiKey) {
    let api_key = msg[0];
    let _pipe = (() => {
      let _record = data.connectors.confluence;
      return new $confluence.Token(api_key, _record.domain, _record.user);
    })();
    let _pipe$1 = ((confluence) => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })(_pipe);
    let _pipe$2 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedConfluenceDomain) {
    let domain = msg[0];
    let _pipe = (() => {
      let _record = data.connectors.confluence;
      return new $confluence.Token(_record.api_key, domain, _record.user);
    })();
    let _pipe$1 = ((confluence) => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })(_pipe);
    let _pipe$2 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedConfluenceUser) {
    let user = msg[0];
    let _pipe = (() => {
      let _record = data.connectors.confluence;
      return new $confluence.Token(_record.api_key, _record.domain, user);
    })();
    let _pipe$1 = ((confluence) => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })(_pipe);
    let _pipe$2 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserToggledConfluencePanel) {
    let opened = data.connectors.confluence_opened;
    let _pipe = (() => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        _record.confluence,
        !opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })();
    let _pipe$1 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserToggledNotionPanel) {
    let opened = data.connectors.notion_opened;
    let _pipe = (() => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        _record.confluence,
        _record.confluence_opened,
        _record.notion,
        !opened,
        _record.google_folder_id,
        _record.settings,
      );
    })();
    let _pipe$1 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedNotionApiKey) {
    let api_key = msg[0];
    let _pipe = (() => {
      let _record = data.connectors.notion;
      return new $notion.Token(api_key, _record.page_ids);
    })();
    let _pipe$1 = ((notion) => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        _record.confluence,
        _record.confluence_opened,
        notion,
        _record.notion_opened,
        _record.google_folder_id,
        _record.settings,
      );
    })(_pipe);
    let _pipe$2 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedNotionPageId) {
    let page_ids_str = msg[0];
    let page_ids = $string.split(page_ids_str, ";");
    let re = notion_page_regex();
    let non_empty_page_ids = (() => {
      let _pipe = page_ids;
      return $list.filter(_pipe, (x) => { return x !== ""; });
    })();
    let nb_valid_inputs = (() => {
      let _pipe = non_empty_page_ids;
      let _pipe$1 = $list.filter(_pipe, (x) => { return $regexp.check(re, x); });
      return $list.length(_pipe$1);
    })();
    let $ = nb_valid_inputs === $list.length(non_empty_page_ids);
    if ($) {
      let _pipe = (() => {
        let _record = data.connectors.notion;
        return new $notion.Token(_record.api_key, page_ids);
      })();
      let _pipe$1 = ((notion) => {
        let _record = data.connectors;
        return new $data.Connectors(
          _record.fetched,
          _record.confluence,
          _record.confluence_opened,
          notion,
          _record.notion_opened,
          _record.google_folder_id,
          _record.settings,
        );
      })(_pipe);
      let _pipe$2 = ((_capture) => {
        return $data.set_connectors(data, _capture);
      })(_pipe$1);
      return $pair.new$(_pipe$2, $effect.none());
    } else {
      let _pipe = "Page ids of notion should be seperated by ';'";
      let _pipe$1 = $effects.toast(_pipe, new $level.Error());
      return ((_capture) => { return $pair.new$(data, _capture); })(_pipe$1);
    }
  } else if (msg instanceof $msg.UserUpdatedGoogleFolderId) {
    let google_folder_id = msg[0];
    let _pipe = (() => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        _record.confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        google_folder_id,
        _record.settings,
      );
    })();
    let _pipe$1 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserSubmitedGoogleFolderId) {
    return [data, update_google_folder_id(data)];
  } else {
    let settings = msg[0];
    let _pipe = (() => {
      let _record = data.connectors;
      return new $data.Connectors(
        _record.fetched,
        _record.confluence,
        _record.confluence_opened,
        _record.notion,
        _record.notion_opened,
        _record.google_folder_id,
        settings,
      );
    })();
    let _pipe$1 = ((_capture) => { return $data.set_connectors(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  }
}
