/// <reference types="./status.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class InProgress extends $CustomType {}

export class Won extends $CustomType {}

export class Lost extends $CustomType {}

export class Pending extends $CustomType {}

export class Cancelled extends $CustomType {}

export function is_ongoing(status) {
  let is_pending = isEqual(status, new Pending());
  let is_in_progress = isEqual(status, new InProgress());
  return is_pending || is_in_progress;
}

export function is_finished(status) {
  return !is_ongoing(status);
}

export function to_string(status) {
  if (status instanceof InProgress) {
    return "In progress";
  } else if (status instanceof Won) {
    return "Won";
  } else if (status instanceof Lost) {
    return "Lost";
  } else if (status instanceof Pending) {
    return "Pending";
  } else {
    return "Cancelled";
  }
}

export function to_json_string(status) {
  if (status instanceof InProgress) {
    return "in-progress";
  } else if (status instanceof Won) {
    return "won";
  } else if (status instanceof Lost) {
    return "lost";
  } else if (status instanceof Pending) {
    return "pending";
  } else {
    return "cancelled";
  }
}

export function encode(status) {
  let _pipe = status;
  let _pipe$1 = to_json_string(_pipe);
  return $json.string(_pipe$1);
}

function create_error(content) {
  let expected = "in-progress | won | lost | pending | cancelled";
  return new Error(
    toList([new $dynamic.DecodeError(expected, content, toList([]))]),
  );
}

export function decode(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk() && $[0] === "in-progress") {
    return new Ok(new InProgress());
  } else if ($.isOk() && $[0] === "won") {
    return new Ok(new Won());
  } else if ($.isOk() && $[0] === "lost") {
    return new Ok(new Lost());
  } else if ($.isOk() && $[0] === "pending") {
    return new Ok(new Pending());
  } else if ($.isOk() && $[0] === "cancelled") {
    return new Ok(new Cancelled());
  } else if ($.isOk()) {
    let content = $[0];
    return create_error(content);
  } else {
    let error = $[0];
    return new Error(error);
  }
}
