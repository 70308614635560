/// <reference types="./styles.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $shadows from "../../../ds/ds/shadows.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px, px_ } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function container(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.background($colors.light.grey_100),
      $s.border_radius(px(8)),
      $s.position("relative"),
      $s.overflow("hidden"),
      $s.box_shadow($shadows.s()),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width($size.percent(100)),
      $s.overflow("auto"),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      $s.padding_right(px(12)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_wrapper_spacer(attrs, children) {
  let padding = $h.div(
    $s.class$(toList([$s.width(px(12)), $s.flex_shrink(0.0)])),
    toList([]),
    toList([]),
  );
  let _pipe = $s.class$(
    toList([
      $s.width($size.percent(100)),
      $s.padding_("12px 0px"),
      $s.display("flex"),
    ]),
  );
  return $h.div(
    _pipe,
    attrs,
    toList([padding, $el.fragment(children), padding]),
  );
}

function button_base() {
  return $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.padding(px(8)),
      $s.border_radius(px(8)),
      $s.border("1px solid transparent"),
      $s.background("transparent"),
      $s.transition("border-color .2s, background .2s, color .2s"),
      $s.color($colors.light.grey_800),
      $s.hover(
        toList([
          $s.background($colors.light.white),
          $s.border_color($colors.light.grey_400),
        ]),
      ),
    ]),
  );
}

export function action_text_button(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($button.button_secondary()),
      $s.compose($text.sm($text.medium)),
      $s.border_color($colors.light.grey_400),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function action_button(attrs, icon) {
  let children = toList([$icons.medium(icon)]);
  let _pipe = $s.class$(
    toList([$s.compose(button_base()), $s.color($colors.light.onyx)]),
  );
  return $h.button(_pipe, attrs, children);
}

function active_button(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose(button_base()), $s.color($colors.light.blue_800)]),
  );
  return $h.button(_pipe, attrs, children);
}

function inactive_button(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(button_base())]));
  return $h.button(_pipe, attrs, children);
}

export function format_button(active, attrs, icon) {
  let children = toList([$icons.medium(icon)]);
  if (active) {
    return active_button(attrs, children);
  } else {
    return inactive_button(attrs, children);
  }
}

const px_per_centimeter = 37.7952755906;

export function page(ratio, attrs, children) {
  let width = ((21.0 * px_per_centimeter) * ratio) + 80.0;
  let _pipe = $s.class$(
    toList([
      $s.width(px_(width)),
      $s.background($colors.light.white),
      $s.border_radius(px(4)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.margin_("12px auto 0 auto"),
      $s.transition("all .2s"),
      $s.position("relative"),
      $s.flex_shrink(0.0),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_row(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(5)),
      $s.align_items("center"),
      $s.width(px_((21.0 * px_per_centimeter) + 40.0)),
      $s.margin_("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
