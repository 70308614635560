/// <reference types="./projects.d.mts" />
import * as $proposal from "../../../../common/data/proposal.mjs";
import * as $status from "../../../../common/data/status.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../../../gleam_stdlib/gleam/pair.mjs";
import * as $data from "../../../data/model/data.mjs";
import { toList, CustomType as $CustomType, divideInt } from "../../../gleam.mjs";

export class Projects extends $CustomType {
  constructor(all, ongoing, finished) {
    super();
    this.all = all;
    this.ongoing = ongoing;
    this.finished = finished;
  }
}

export function count(projects) {
  let all = $list.length(projects.all);
  let ongoing = $list.length(projects.ongoing);
  let finished = all - ongoing;
  return new Projects(all, ongoing, finished);
}

function compute_project_completion(proposals) {
  let _pipe = proposals;
  let _pipe$1 = $list.fold(
    _pipe,
    [0, 0],
    (acc, prop) => { return [acc[0] + prop.progress, acc[1] + 1]; },
  );
  return ((value) => { return divideInt(value[0], value[1]); })(_pipe$1);
}

function do_compute(data) {
  return $list.flat_map(
    data.projects,
    (project) => {
      let proposals = (() => {
        let _pipe = data.proposals;
        let _pipe$1 = $list.map(_pipe, $pair.second);
        return $list.filter(
          _pipe$1,
          (p) => { return p.project_id === project[1].id; },
        );
      })();
      return $bool.guard(
        $list.is_empty(proposals),
        toList([]),
        () => {
          let completion = compute_project_completion(proposals);
          return toList([[project[1], completion, proposals]]);
        },
      );
    },
  );
}

export function compute(data) {
  let all = do_compute(data);
  let ongoing = $list.filter(
    all,
    (p) => { return $status.is_ongoing(p[0].status); },
  );
  let finished = $list.filter(
    all,
    (p) => { return $status.is_finished(p[0].status); },
  );
  return new Projects(all, ongoing, finished);
}
