/// <reference types="./trash.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function trash() {
  let content = "<path d=\"M14.1665 4.99984H18.3332V6.6665H16.6665V17.4998C16.6665 17.7209 16.5787 17.9328 16.4224 18.0891C16.2661 18.2454 16.0542 18.3332 15.8332 18.3332H4.1665C3.94549 18.3332 3.73353 18.2454 3.57725 18.0891C3.42097 17.9328 3.33317 17.7209 3.33317 17.4998V6.6665H1.6665V4.99984H5.83317V2.49984C5.83317 2.27882 5.92097 2.06686 6.07725 1.91058C6.23353 1.7543 6.44549 1.6665 6.6665 1.6665H13.3332C13.5542 1.6665 13.7661 1.7543 13.9224 1.91058C14.0787 2.06686 14.1665 2.27882 14.1665 2.49984V4.99984ZM7.49984 9.1665V14.1665H9.1665V9.1665H7.49984ZM10.8332 9.1665V14.1665H12.4998V9.1665H10.8332ZM7.49984 3.33317V4.99984H12.4998V3.33317H7.49984Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
