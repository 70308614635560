/// <reference types="./bold.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function bold() {
  let content = "<path d=\"M6.66667 9.79195H10.4167C11.5672 9.79195 12.5 8.85921 12.5 7.70862C12.5 6.55803 11.5672 5.62528 10.4167 5.62528H6.66667V9.79195ZM15 13.542C15 15.613 13.3211 17.292 11.25 17.292H5V3.95862H10.4167C12.4878 3.95862 14.1667 5.63755 14.1667 7.70862C14.1667 8.71221 13.7724 9.6237 13.1303 10.2968C14.2483 10.9459 15 12.1561 15 13.542ZM6.66667 11.4586V15.6253H11.25C12.4006 15.6253 13.3333 14.6925 13.3333 13.542C13.3333 12.3914 12.4006 11.4586 11.25 11.4586H6.66667Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 21"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
