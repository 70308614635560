/// <reference types="./ffi.d.mts" />
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $event from "../../plinth/plinth/browser/event.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import {
  log,
  getDataFromEvent as get_data_from_event,
  blurActiveElement as blur_active_element,
  subscribeFeedScroll as subscribe_feed_scroll,
  scrollToQuestion as scroll_to_question,
  coerce,
  allSettled as all_settled,
  readFileEvent as read_file_event,
  getActiveElementNearestId as get_active_element_nearest_id,
  subscribeDOMClick as subscribe_dom_click,
  uuid,
  dlFile as dl_file,
  readLocalStorage as read_local_storage,
  writeLocalStorage as do_write_local_storage,
} from "../frontend.ffi.mjs";

export {
  all_settled,
  blur_active_element,
  coerce,
  dl_file,
  get_active_element_nearest_id,
  get_data_from_event,
  log,
  read_file_event,
  read_local_storage,
  scroll_to_question,
  subscribe_dom_click,
  subscribe_feed_scroll,
  uuid,
};

export function write_local_storage(content, key) {
  let _pipe = $json.to_string(content);
  return ((_capture) => { return do_write_local_storage(key, _capture); })(
    _pipe,
  );
}
