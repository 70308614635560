/// <reference types="./navbar.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function navbar(collapsed_navbar, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.padding_(
        (() => {
          if (collapsed_navbar) {
            return "30px 20px";
          } else {
            return "40px";
          }
        })(),
      ),
      $s.width(
        px(
          (() => {
            if (collapsed_navbar) {
              return 95;
            } else {
              return 350;
            }
          })(),
        ),
      ),
      $s.background($colors.light.grey_100),
      $s.gap(px(60)),
      $s.flex_direction("column"),
      $s.overflow("auto"),
      $s.z_index(90_005),
      $s.transition("width .6s"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function logo_style() {
  return $s.class$(
    toList([
      $s.display("flex"),
      $s.max_height(px(35)),
      $s.gap(px(14)),
      $s.width(percent(100)),
    ]),
  );
}

export function logo(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(logo_style())]));
  return $h.div(_pipe, attrs, children);
}

export function logo_text(attrs, children) {
  let _pipe = $s.class$(toList([$s.padding_("7px 0"), $s.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function clickable_logo(collapsed_navbar, href, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(logo_style()),
      $s.text_decoration("none"),
      $s.justify_content(
        (() => {
          if (collapsed_navbar) {
            return "center";
          } else {
            return "start";
          }
        })(),
      ),
    ]),
  );
  return $h.a(_pipe, toList([$a.href(href)]), children);
}

export function navbar_main(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(32))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function name_notifications(collapsed_navbar, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.justify_content("space-between"),
      $s.align_items("center"),
      $s.gap(px(19)),
      $s.flex_direction(
        (() => {
          if (collapsed_navbar) {
            return "column";
          } else {
            return "row";
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function name(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function nav_links(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(8))]),
  );
  return $h.nav(_pipe, attrs, children);
}

export function nav_link(color, active, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.lg($text.medium)),
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.border_radius(px(8)),
      $s.color(color),
      $s.text_decoration("none"),
      $s.padding(px(14)),
      $s.transition("all .3s"),
      $s.background(
        (() => {
          if (active) {
            return $colors.light.grey_200;
          } else {
            return $colors.light.transparent;
          }
        })(),
      ),
      $s.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return color;
          }
        })(),
      ),
      $s.hover(
        (() => {
          if (!active) {
            return toList([$s.background($colors.light.white)]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}

export function spacer() {
  let _pipe = $s.class$(toList([$s.flex("1")]));
  return $h.div(_pipe, toList([]), toList([]));
}

export function navbar_settings(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(32))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(child) {
  let _pipe = $s.class$(
    toList([$s.width(px(24)), $s.height(px(24)), $s.flex_shrink(0.0)]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function bell_button(active, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.padding(px(9)),
      $s.border_radius(px(50)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.background(
        (() => {
          if (!active) {
            return "transparent";
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $s.position("relative"),
      $s.hover(toList([$s.background($colors.light.white)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function bell_active_dot() {
  let _pipe = $s.class$(
    toList([
      $s.width(px(10)),
      $s.height(px(10)),
      $s.border_radius(px(8)),
      $s.background($colors.light.error_800),
      $s.position("absolute"),
      $s.top(px(2)),
      $s.right(px(2)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}
