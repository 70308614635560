/// <reference types="./new_question.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $content_library from "../../data/content_library.mjs";
import { ContentLibrary, NewQna } from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList, isEqual } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $s from "../../view/styles/content_library.mjs";

function is_question_edited(existing_document, question, answer) {
  if (existing_document instanceof None) {
    return false;
  } else {
    let source = existing_document[0];
    let question$1 = new Some(question);
    let answer$1 = new Some(answer);
    let map = (mapper) => { return $option.map(source.display.qna, mapper); };
    return (isEqual(map((q) => { return q.question; }), question$1)) && (isEqual(
      map((q) => { return q.answer; }),
      answer$1
    ));
  }
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let model$1 = $[0];
  let $1 = model$1.content_library;
  let new_qna = $1.new_qna;
  let answer = new_qna.answer;
  let existing_document = new_qna.existing_document;
  let loading = new_qna.loading;
  let question = new_qna.question;
  let disabled = (($string.is_empty(question) || $string.is_empty(answer)) || loading) || is_question_edited(
    existing_document,
    question,
    answer,
  );
  let is_edition = $option.is_some(existing_document);
  return $el.fragment(
    toList([
      $common.separator(),
      $s.new_question_wrapper(
        toList([
          $e.on_submit(
            new $msg.ContentLibrary(new $msg.UserSubmittedQuestionBankEdit()),
          ),
        ]),
        toList([
          $s.question_title(
            toList([]),
            toList([
              $h.text(
                (() => {
                  if (is_edition) {
                    return "Edit a question";
                  } else {
                    return "Add a new question";
                  }
                })(),
              ),
            ]),
          ),
          $input.input(
            toList([
              $input.multiline(),
              $input.label("Question"),
              $input.value(question),
              $input.on_input(
                (question) => {
                  return new $msg.ContentLibrary(
                    new $msg.UserUpdatedQuestionBankEditQuestion(question),
                  );
                },
              ),
            ]),
          ),
          $input.input(
            toList([
              $input.multiline(),
              $input.label("Answer"),
              $input.value(answer),
              $input.on_input(
                (answer) => {
                  return new $msg.ContentLibrary(
                    new $msg.UserUpdatedQuestionBankEditAnswer(answer),
                  );
                },
              ),
            ]),
          ),
          $layout.row(
            toList([$layout.justify("end")]),
            toList([]),
            toList([
              $button.primary(
                toList([$a.type_("submit"), $a.disabled(disabled)]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
