/// <reference types="./common.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";

export function choose_settings(state, active) {
  let light = $colors.light;
  if (state === "green") {
    return [light.success_800, light.success_50, light.success_800, active];
  } else if (state === "blue") {
    return [light.blue_800, light.blue_50, light.blue_800, active];
  } else if (state === "red") {
    return [light.error_800, light.error_50, light.error_800, active];
  } else if (state === "grey") {
    return [light.grey_800, light.grey_50, light.grey_800, active];
  } else {
    return [light.grey_800, light.grey_50, light.grey_50, active];
  }
}
