/// <reference types="./menu.d.mts" />
import * as $tg from "../../../../ds/ds/components/transition_group.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $lustre from "../../../../lustre/lustre.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $magic from "../../../../sketch_magic/sketch/magic.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../../../gleam.mjs";

class Model extends $CustomType {
  constructor(destroy) {
    super();
    this.destroy = destroy;
  }
}

class UserUpdatedDestroy extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

function init(_) {
  return [new Model(false), $effect.none()];
}

function update(_, msg) {
  {
    let destroy = msg[0];
    return [new Model(destroy), $effect.none()];
  }
}

function view(model) {
  return $magic.render(
    toList([$magic.node()]),
    () => {
      return $tg.transition_group(
        toList([
          $tg.destroy(model.destroy),
          $tg.duration(200),
          $tg.entering($s.class$(toList([$s.opacity(0.0)]))),
          $tg.enter(
            $s.class$(toList([$s.transition("opacity 200ms"), $s.opacity(1.0)])),
          ),
          $tg.entered($s.class$(toList([$s.opacity(1.0)]))),
          $tg.exiting($s.class$(toList([$s.opacity(1.0)]))),
          $tg.exit(
            $s.class$(toList([$s.transition("opacity 200ms"), $s.opacity(0.0)])),
          ),
          $tg.exited($s.class$(toList([$s.opacity(0.0)]))),
        ]),
        toList([
          (() => {
            let _pipe = $s.class$(
              toList([
                $s.display("flex"),
                $s.align_items("center"),
                $s.justify_content("center"),
                $s.width_("12px"),
                $s.height_("12px"),
                $s.color("#666"),
              ]),
            );
            return $h.div(
              _pipe,
              toList([]),
              toList([$icons.medium($icons.editor_menu())]),
            );
          })(),
        ]),
      );
    },
  );
}

export function register() {
  let _pipe = $dict.from_list(
    toList([
      [
        "destroy",
        (dyn) => {
          let $ = $dynamic.string(dyn);
          if ($.isOk() && $[0] === "true") {
            return new Ok(new UserUpdatedDestroy(true));
          } else if ($.isOk() && $[0] === "True") {
            return new Ok(new UserUpdatedDestroy(true));
          } else if ($.isOk() && $[0] === "false") {
            return new Ok(new UserUpdatedDestroy(false));
          } else if ($.isOk() && $[0] === "False") {
            return new Ok(new UserUpdatedDestroy(false));
          } else {
            return new Error(toList([]));
          }
        },
      ],
    ]),
  );
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, view, _capture);
  })(_pipe);
  return $lustre.register(_pipe$1, "editor-menu");
}
