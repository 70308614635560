/// <reference types="./computed.d.mts" />
import * as $source from "../../../common/data/data_source.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $projects from "../../data/model/computed/projects.mjs";
import { Projects } from "../../data/model/computed/projects.mjs";
import * as $questions from "../../data/model/computed/questions.mjs";
import * as $sources from "../../data/model/computed/sources.mjs";
import { Sources } from "../../data/model/computed/sources.mjs";
import * as $data from "../../data/model/data.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Computed extends $CustomType {
  constructor(displayed_questions, non_qna_sources, non_qna_sources_count, projects, projects_count, qna_sources, qna_sources_count) {
    super();
    this.displayed_questions = displayed_questions;
    this.non_qna_sources = non_qna_sources;
    this.non_qna_sources_count = non_qna_sources_count;
    this.projects = projects;
    this.projects_count = projects_count;
    this.qna_sources = qna_sources;
    this.qna_sources_count = qna_sources_count;
  }
}

export function new$() {
  return new Computed(
    toList([]),
    new Sources(toList([]), toList([]), toList([]), toList([]), toList([])),
    new Sources(0, 0, 0, 0, 0),
    new Projects(toList([]), toList([]), toList([])),
    new Projects(0, 0, 0),
    new Sources(toList([]), toList([]), toList([]), toList([]), toList([])),
    new Sources(0, 0, 0, 0, 0),
  );
}

export function projects(data, computed) {
  let projects$1 = $projects.compute(data);
  let projects_count = $projects.count(projects$1);
  let _record = computed;
  return new Computed(
    _record.displayed_questions,
    _record.non_qna_sources,
    _record.non_qna_sources_count,
    projects$1,
    projects_count,
    _record.qna_sources,
    _record.qna_sources_count,
  );
}

export function sources(data, computed) {
  let qna_sources = data.content_library.qna_sources;
  let non_qna_sources = data.content_library.non_qna_sources;
  let qna_sources$1 = $sources.compute(qna_sources);
  let qna_sources_count = $sources.count(qna_sources$1);
  let non_qna_sources$1 = $sources.compute(non_qna_sources);
  let non_qna_sources_count = $sources.count(non_qna_sources$1);
  let _record = computed;
  return new Computed(
    _record.displayed_questions,
    non_qna_sources$1,
    non_qna_sources_count,
    _record.projects,
    _record.projects_count,
    qna_sources$1,
    qna_sources_count,
  );
}

export function sources_selector(data) {
  let qna_sources = data.content_library.qna_sources;
  let non_qna_sources = data.content_library.non_qna_sources;
  return [qna_sources, non_qna_sources];
}

export function displayed_questions(data, computed) {
  let _pipe = $questions.displayed(data);
  let _pipe$1 = $result.map(
    _pipe,
    (q) => {
      let _record = computed;
      return new Computed(
        q,
        _record.non_qna_sources,
        _record.non_qna_sources_count,
        _record.projects,
        _record.projects_count,
        _record.qna_sources,
        _record.qna_sources_count,
      );
    },
  );
  return $result.unwrap(_pipe$1, computed);
}

export function filtered_non_qna_sources(data, computed) {
  let sources$1 = computed.non_qna_sources;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return data.content_library.non_qna_sources;
  }
}

export function filtered_qna_sources(data, computed) {
  let sources$1 = computed.qna_sources;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function filtered_non_qna_count(data, computed) {
  let sources$1 = computed.non_qna_sources_count;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function filtered_qna_count(data, computed) {
  let sources$1 = computed.qna_sources_count;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function all_non_qna_selected(data, computed) {
  let selected_count = $dict.size(data.content_library.selected);
  let displayed_count = filtered_non_qna_count(data, computed);
  return selected_count === displayed_count;
}

export function all_qna_selected(data, computed) {
  let selected_count = $dict.size(data.content_library.selected);
  let displayed_count = filtered_qna_count(data, computed);
  return selected_count === displayed_count;
}
