/// <reference types="./extra.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import { isEqual } from "../../gleam.mjs";

export function postpend(list, element) {
  let _pipe = list;
  let _pipe$1 = $list.reverse(_pipe);
  let _pipe$2 = $list.prepend(_pipe$1, element);
  return $list.reverse(_pipe$2);
}

export function insert(list, element) {
  let present = $list.contains(list, element);
  return $bool.guard(present, list, () => { return postpend(list, element); });
}

export function remove(list, element) {
  return $list.filter(list, (e) => { return !isEqual(e, element); });
}
