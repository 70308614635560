/// <reference types="./italic.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function italic() {
  let content = "<path d=\"M12.5 17.292H5.83331V15.6253H8.27215L10.0354 5.62528H7.49998V3.95862H14.1666V5.62528H11.7278L9.96456 15.6253H12.5V17.292Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 21"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
