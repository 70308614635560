/// <reference types="./content_library.d.mts" />
import * as $data_source from "../../common/data/data_source.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $content_library from "../data/content_library.mjs";
import { ContentLibrary } from "../data/content_library.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $loading from "../data/ui/loading.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $content_library_effects from "../frontend/effects/content_library.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { require_not_modifying_question } from "../frontend/middleware.mjs";
import { toList, isEqual } from "../gleam.mjs";
import * as $spreadsheet_selector from "../update/spreadsheet_selector.mjs";

function handle_content_library_spreadsheet_selector(data, msg) {
  if (msg instanceof $msg.UserSubmittedXlsx) {
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        true,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(
      _pipe$1,
      $content_library_effects.ingest_question_bank(data),
    );
  } else if (msg instanceof $msg.UserCancelledSpreadsheetSelection) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $modem.back(1));
  } else {
    let msg$1 = msg;
    let $ = data.content_library;
    let ingestion_spreadsheet = $.ingestion_spreadsheet;
    let _pipe = $spreadsheet_selector.update(data, ingestion_spreadsheet, msg$1);
    let _pipe$1 = $pair.map_second(
      _pipe,
      (e) => {
        let _pipe$1 = $effect.map(
          e,
          (var0) => {
            return new $msg.ContentLibraryHandledSpreadsheetSelector(var0);
          },
        );
        let _pipe$2 = $effect.map(
          _pipe$1,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
        let _pipe$3 = (() => {
          let $1 = $spreadsheet_selector.is_xlsx_fetched(msg$1);
          if (!$1) {
            return $list.wrap;
          } else {
            return (_capture) => {
              return $list.prepend(
                toList([
                  $route.push(new $route.ContentLibrary(new $route.Ingestion())),
                ]),
                _capture,
              );
            };
          }
        })()(_pipe$2);
        return $effect.batch(_pipe$3);
      },
    );
    return $pair.map_first(
      _pipe$1,
      (ingestion_spreadsheet) => {
        let _pipe$2 = (() => {
          let _record = data.content_library;
          return new ContentLibrary(
            _record.change_expiration_date_input,
            _record.change_expiration_date_popup_opened,
            _record.change_owner_popup_opened,
            _record.change_status_popup_opened,
            _record.change_tags_popup_opened,
            _record.dropdown_unsubscriber,
            _record.filter_selected,
            _record.ingestion_loading,
            ingestion_spreadsheet,
            _record.new_qna,
            _record.non_qna_sources,
            _record.non_qna_status,
            _record.qna_sources,
            _record.qna_status,
            _record.search,
            _record.selected,
          );
        })();
        return ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$2);
      },
    );
  }
}

export function update(data, msg) {
  if (msg instanceof $msg.ApiReturnedSource) {
    let source = msg.source;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.upsert_data_source(_pipe, source);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSources) {
    let sources = msg.sources;
    return require_not_modifying_question(
      data,
      () => {
        let _pipe = data.content_library;
        let _pipe$1 = $content_library.set_data_sources(_pipe, sources);
        let _pipe$2 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$1);
        let _pipe$3 = $data.mark_as_loaded(_pipe$2, $loading.content_library);
        return $pair.new$(_pipe$3, $effect.none());
      },
    );
  } else if (msg instanceof $msg.UserSelectedSource) {
    let id = msg.id;
    let checked = msg.checked;
    let handler = (() => {
      if (checked) {
        return $content_library.select;
      } else {
        return $content_library.deselect;
      }
    })();
    let _pipe = data.content_library;
    let _pipe$1 = handler(_pipe, id);
    let _pipe$2 = $content_library.close_popups(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeOwnerPopup) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_owner(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeStatusPopup) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_status(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeTags) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_tags(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeExpirationDatePopup) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_expiration_date(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserClickedOutside) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.DocumentReturnedUnsubscriber) {
    let dropdown_unsubscriber = msg[0];
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.set_dropdown_unsubscriber(
      _pipe,
      new Some(dropdown_unsubscriber),
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserClickedGlobalCheckbox) {
    let all_selected = msg.all_selected;
    let sources = msg.sources;
    let selected = $bool.guard(
      all_selected,
      $dict.new$(),
      () => {
        return $list.fold(
          sources,
          $dict.new$(),
          (selected, source) => {
            return $dict.insert(selected, source.id, true);
          },
        );
      },
    );
    let _pipe = (() => {
      let _record = data.content_library;
      return new $content_library.ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        selected,
      );
    })();
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserAssignedStatus) {
    let status = msg.status;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_status(_pipe, status);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $effect.batch(
        toList([
          $content_library_effects.unsubscribe_previous_dom_click(data),
          $content_library_effects.update_data_sources_status(data, status),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserToggledAssignUserPopup) {
    let user_id = msg.user_id;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_source(_pipe, user_id);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.update_data_sources_owner(data, user_id),
    );
  } else if (msg instanceof $msg.UserAssignedTag) {
    let tag = msg.tag;
    let add_remove = msg.add_remove;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_tag(_pipe, tag, add_remove);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.add_remove_data_sources_tag(
        data,
        tag,
        add_remove,
      ),
    );
  } else if (msg instanceof $msg.UserAssignedExpirationDate) {
    let expiration_date = msg.expiration_date;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_expiration_date(
      _pipe,
      expiration_date,
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    let _pipe$3 = update(
      _pipe$2,
      new $msg.UserToggledChangeExpirationDatePopup(),
    );
    return $pair.map_second(
      _pipe$3,
      (effects) => {
        let unsub = $content_library_effects.unsubscribe_previous_dom_click(
          data,
        );
        let _pipe$4 = expiration_date;
        let _pipe$5 = ((_capture) => {
          return $content_library_effects.update_data_sources_expiration_date(
            data,
            _capture,
          );
        })(_pipe$4);
        let _pipe$6 = ((_capture) => {
          return $list.prepend(toList([effects, unsub]), _capture);
        })(_pipe$5);
        return $effect.batch(_pipe$6);
      },
    );
  } else if (msg instanceof $msg.UserSelectedFilter) {
    let filter = msg.filter;
    let filter$1 = new Some(filter);
    let _pipe = (() => {
      let $ = isEqual(data.content_library.filter_selected, filter$1);
      if ($) {
        return (_capture) => {
          return $content_library.set_filter(_capture, new $option.None());
        };
      } else {
        return (_capture) => {
          return $content_library.set_filter(_capture, filter$1);
        };
      }
    })();
    let _pipe$1 = ((f) => { return f(data.content_library); })(_pipe);
    let _pipe$2 = $content_library.deselect_all(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedSearch) {
    let search = msg.search;
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        search,
        _record.selected,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedExpirationDateInput) {
    let value = msg.value;
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        value,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUploadedSourceFiles) {
    let sources = msg.sources;
    let _pipe = $content_library_effects.upload_content_library_sources(
      data,
      sources,
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.ApiSuccessfullyProcessedFiles) {
    let total = msg.total;
    let _pipe = $content_library.set_non_qna_status(
      data.content_library,
      (() => {
        let _record = data.content_library.non_qna_status;
        return new $data_source.StatusStats(
          _record.verified,
          _record.pending,
          data.content_library.non_qna_status.not_verified + total,
          _record.outdated,
        );
      })(),
    );
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditQuestion) {
    let question = msg.question;
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        _record.loading,
        question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditAnswer) {
    let answer = msg.answer;
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        answer,
        _record.existing_document,
        _record.loading,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserSubmittedQuestionBankEdit) {
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        true,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.upload_question_bank_question(data),
    );
  } else if (msg instanceof $msg.ApiRejectedUploadingQuestion) {
    let cl = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        false,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
      );
    })();
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        false,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(cl, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      (() => {
        let _pipe$3 = "Internal server error. Please, retry later.";
        return $effects.toast(_pipe$3, new $level.Error());
      })(),
    );
  } else if (msg instanceof $msg.ApiReturnedUploadingQuestion) {
    let ingestion = msg.ingestion;
    let text = (() => {
      if (ingestion) {
        return "Questions";
      } else {
        return "Question";
      }
    })();
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $effect.batch(
        toList([
          $effects.toast(text + " successfully added!", new $level.Success()),
          (() => {
            let $ = data.route;
            if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.NewQuestion) {
              return $modem.back(1);
            } else if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.Ingestion) {
              return $modem.back(1);
            } else {
              return $effect.none();
            }
          })(),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserCreatedQuestion) {
    let _pipe = $content_library.new_qna();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
    );
  } else if (msg instanceof $msg.UserEdittedQuestion) {
    let question_id = msg.question_id;
    let all = data.content_library.qna_sources;
    let _pipe = $list.find(all, (s) => { return s.id === question_id; });
    let _pipe$1 = $result.map(
      _pipe,
      (source) => {
        let qna = source.display.qna;
        let map = (mapper) => {
          let _pipe$1 = $option.map(qna, mapper);
          return $option.unwrap(_pipe$1, "");
        };
        let question = map((q) => { return q.question; });
        let answer = map((q) => { return q.answer; });
        let _pipe$1 = new $content_library.NewQna(
          answer,
          new Some(source),
          false,
          question,
        );
        let _pipe$2 = ((_capture) => {
          return $content_library.set_new_qna(data.content_library, _capture);
        })(_pipe$1);
        let _pipe$3 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$2);
        return $pair.new$(
          _pipe$3,
          $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
        );
      },
    );
    return $result.unwrap(_pipe$1, [data, $effect.none()]);
  } else if (msg instanceof $msg.ContentLibraryHandledSpreadsheetSelector) {
    let msg$1 = msg.msg;
    return handle_content_library_spreadsheet_selector(data, msg$1);
  } else {
    let non_qna_status = msg[0][0];
    let qna_status = msg[0][1];
    return require_not_modifying_question(
      data,
      () => {
        let _pipe = (() => {
          let _record = data.content_library;
          return new ContentLibrary(
            _record.change_expiration_date_input,
            _record.change_expiration_date_popup_opened,
            _record.change_owner_popup_opened,
            _record.change_status_popup_opened,
            _record.change_tags_popup_opened,
            _record.dropdown_unsubscriber,
            _record.filter_selected,
            _record.ingestion_loading,
            _record.ingestion_spreadsheet,
            _record.new_qna,
            _record.non_qna_sources,
            non_qna_status,
            _record.qna_sources,
            qna_status,
            _record.search,
            _record.selected,
          );
        })();
        let _pipe$1 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe);
        return $pair.new$(_pipe$1, $effect.none());
      },
    );
  }
}
