/// <reference types="./editor.d.mts" />
import * as $effect from "../../../lustre/lustre/effect.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";
import {
  getJSON as get_json,
  isActive as is_active,
  bold as do_bold,
  italic as do_italic,
  underline as do_underline,
} from "../../tiptap.ffi.mjs";

export { get_json };

export class BeforeCreate extends $CustomType {}

export class Blur extends $CustomType {}

export class ContentError extends $CustomType {}

export class Create extends $CustomType {}

export class Destroy extends $CustomType {}

export class Drop extends $CustomType {}

export class Focus extends $CustomType {}

export class Paste extends $CustomType {}

export class SelectionUpdate extends $CustomType {}

export class Transaction extends $CustomType {}

export class Update extends $CustomType {}

export class Connected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function bold(editor) {
  return $effect.from((_) => { return do_bold(editor); });
}

export function is_bold(editor) {
  return is_active(editor, "bold");
}

export function italic(editor) {
  return $effect.from((_) => { return do_italic(editor); });
}

export function is_italic(editor) {
  return is_active(editor, "italic");
}

export function underline(editor) {
  return $effect.from((_) => { return do_underline(editor); });
}

export function is_underline(editor) {
  return is_active(editor, "underline");
}

export const events = /* @__PURE__ */ toList([
  ["beforecreate", /* @__PURE__ */ new BeforeCreate()],
  ["blur", /* @__PURE__ */ new Blur()],
  ["contenterror", /* @__PURE__ */ new ContentError()],
  ["create", /* @__PURE__ */ new Create()],
  ["destroy", /* @__PURE__ */ new Destroy()],
  ["drop", /* @__PURE__ */ new Drop()],
  ["focus", /* @__PURE__ */ new Focus()],
  ["paste", /* @__PURE__ */ new Paste()],
  ["selectionupdate", /* @__PURE__ */ new SelectionUpdate()],
  ["transaction", /* @__PURE__ */ new Transaction()],
  ["update", /* @__PURE__ */ new Update()],
]);
