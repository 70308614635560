/// <reference types="./project.d.mts" />
import * as $project from "../../../common/data/project.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_projects(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let _pipe = $ask.to(new $ask.Heimdall(), toList(["projects"]));
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(_pipe$1, $dynamic.list($project.decode));
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (p) => { return dispatch(new $msg.ApiReturnedProjects(p)); },
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}

export function update_project(data, id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      let $ = $list.key_find(data.projects, id);
      if (!$.isOk()) {
        return $effect.none();
      } else {
        let project = $[0];
        return $effect.from(
          (dispatch) => {
            let _pipe = $ask.to(
              new $ask.Heimdall(),
              toList(["projects", project.id]),
            );
            let _pipe$1 = $ask.with$(
              _pipe,
              $json.object(toList([["project", $project.encode(project)]])),
            );
            let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
            let _pipe$3 = $ask.bearing(_pipe$2, access_token);
            let _pipe$4 = $ask.expect(
              _pipe$3,
              $dynamic.field("project", $project.decode),
            );
            let _pipe$5 = $ask.notify(
              _pipe$4,
              (p) => { return dispatch(new $msg.ApiReturnedProject(p)); },
            );
            $ask.run(_pipe$5)
            return undefined;
          },
        );
      }
    },
  );
}

export function toggle_project_collaborator(data, project_id, user_id) {
  let project = $list.key_find(data.projects, project_id);
  return $middleware.require_access_token(
    data,
    (access_token) => {
      if (!project.isOk()) {
        return $effect.none();
      } else {
        let project$1 = project[0];
        return $effect.from(
          (dispatch) => {
            let new$ = $project.toggle_collaborator(project$1, user_id);
            let _pipe = $ask.to(
              new $ask.Heimdall(),
              toList(["projects", project_id]),
            );
            let _pipe$1 = $ask.bearing(_pipe, access_token);
            let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
            let _pipe$3 = $ask.with$(
              _pipe$2,
              $json.object(toList([["project", $project.encode(new$)]])),
            );
            let _pipe$4 = $ask.expect(
              _pipe$3,
              $dynamic.field("project", $project.decode),
            );
            let _pipe$5 = $ask.notify(
              _pipe$4,
              (p) => {
                return dispatch(new $msg.ApiReturnedProjects(toList([p])));
              },
            );
            $ask.run(_pipe$5)
            return undefined;
          },
        );
      }
    },
  );
}
