import { Node } from '@tiptap/core'
import { class_, div, text } from './html'

const tag = 'context-view'

export const ContextView = Node.create({
  name: 'contextView',
  group: 'block',
  content: 'block*',
  parseHTML() {
    return [{ tag }]
  },
  renderHTML({ HTMLAttributes }) {
    return [tag, HTMLAttributes, 0]
  },
  addNodeView() {
    return ({ editor }) => {
      const contentDOM = div([class_('context-content')], [])
      // prettier-ignore
      const dom =
        div([class_('context-view')], [
          div([class_('context-label')], [text(`Context`)]),
          div([class_('context-content-wrapper')], [contentDOM])
        ])
      return { dom, contentDOM }
    }
  },
})

export default ContextView
