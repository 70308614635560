import * as tiptap from '@tiptap/core'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import FontSize from 'tiptap-extension-font-size'
import ContextView from './editor/context-view'
import HoverBlock from './editor/hover-block'
import * as html from './editor/html'
import LineHeight from './editor/line-height'
import { mock } from './editor/mock'
import inlined from './editor/styling.css?inline'
import Table from './editor/table'
import * as utils from './editor/utils'
import { Attribute } from './editor/utils'

export function register() {
  customElements.define('tiptap-editor', SteerlabEditor)
}

class SteerlabEditor extends HTMLElement {
  #shadow: ShadowRoot
  #container: HTMLElement
  #editor?: tiptap.Editor

  static get observedAttributes() {
    const observed = Object.values(utils.attributes)
    return observed
  }

  constructor() {
    super()
    this.#shadow = this.attachShadow({ mode: 'open' })
    this.#container = html.div([], [])
    this.#container.style.setProperty('--ratio', '1.0')
    this.#container.style.setProperty('--font-size', '1.0rem')
    this.#container.style.setProperty('--border-color', '#ccc')
    this.#container.style.setProperty('--header-background', '#eee')
    this.#container.style.setProperty('--selected-cell-background', '#ccf2')
    this.#container.setAttribute('class', 'tiptap-editor')
    this.#shadow.appendChild(this.#container)
    this.#appendStyles()
  }

  connectedCallback() {
    const types = ['heading', 'paragraph', 'textStyle']
    this.#editor = new tiptap.Editor({
      element: this.#container,
      extensions: [
        StarterKit,
        HoverBlock,
        Table.configure({ resizable: true }),
        TableHeader,
        TableRow,
        TableCell,
        ContextView,
        Underline,
        TextStyle,
        TextAlign.configure({ types }),
        FontSize.configure({ types }),
        LineHeight.configure({ types }),
      ],
      content: mock,
      injectCSS: false,
      editable: this.#readEditableState(),
      onBeforeCreate: () => this.#dispatchEvent('beforecreate'),
      onBlur: () => this.#dispatchEvent('blur'),
      onContentError: () => this.#dispatchEvent('contenterror'),
      onCreate: () => this.#dispatchEvent('create'),
      onDestroy: () => this.#dispatchEvent('destroy'),
      onDrop: () => this.#dispatchEvent('drop'),
      onFocus: () => this.#dispatchEvent('focus'),
      onPaste: () => this.#dispatchEvent('paste'),
      onSelectionUpdate: () => this.#dispatchEvent('selectionupdate'),
      onTransaction: () => this.#dispatchEvent('transaction'),
      onUpdate: () => this.#dispatchEvent('update'),
    })
    this.#dispatchEvent('connected')
  }

  attributeChangedCallback(name: Attribute, _previous: string, next: string) {
    switch (name) {
      case utils.attributes.ratio: {
        const ratio = next
        this.#container.style.setProperty('--ratio', ratio)
        return Object.entries(utils.styles(ratio)).forEach(([key, value]) => {
          this.#container.style.setProperty(key, value)
        })
      }
      default:
        return this.#setProperty(name, next)
    }
  }

  disconnectedCallback() {
    this.#editor?.destroy()
  }

  async #appendStyles() {
    const stylesheet = new CSSStyleSheet()
    await stylesheet.replace(inlined)
    this.#shadow.adoptedStyleSheets.push(stylesheet)
  }

  #dispatchEvent(name: string, details: object = {}) {
    console.log(name)
    const content = { detail: { editor: this.#editor, ...details } }
    const event = new CustomEvent(name, content)
    this.dispatchEvent(event)
  }

  #setProperty(name: string, value: string) {
    const property = utils.getProperty(name)
    this.#container.style.setProperty(property, value)
  }

  #readEditableState() {
    const attribute = this.attributes.getNamedItem('editable')
    const value = attribute?.value
    if (value === 'false') return false
    return true
  }
}
