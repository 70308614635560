/// <reference types="./sources.d.mts" />
import * as $source from "../../../../common/data/data_source.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import { CustomType as $CustomType, isEqual } from "../../../gleam.mjs";

export class Sources extends $CustomType {
  constructor(all, verified, pending, not_verified, outdated) {
    super();
    this.all = all;
    this.verified = verified;
    this.pending = pending;
    this.not_verified = not_verified;
    this.outdated = outdated;
  }
}

export function compute(all) {
  let not_verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $source.NotVerified()); },
  );
  let outdated = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $source.Outdated()); },
  );
  let pending = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $source.Pending()); },
  );
  let verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $source.Verified()); },
  );
  return new Sources(all, verified, pending, not_verified, outdated);
}

export function count(sources) {
  let all = $list.length(sources.all);
  let not_verified = $list.length(sources.not_verified);
  let outdated = $list.length(sources.outdated);
  let pending = $list.length(sources.pending);
  let verified = $list.length(sources.verified);
  return new Sources(all, verified, pending, not_verified, outdated);
}
