/// <reference types="./notification.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Notification extends $CustomType {
  constructor(id, read, content, date) {
    super();
    this.id = id;
    this.read = read;
    this.content = content;
    this.date = date;
  }
}

export class Question extends $CustomType {
  constructor(project_id, proposal_id, question_id) {
    super();
    this.project_id = project_id;
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class ProjectAssigned extends $CustomType {
  constructor(project_id) {
    super();
    this.project_id = project_id;
  }
}

export class ProposalAssigned extends $CustomType {
  constructor(project_id, proposal_id) {
    super();
    this.project_id = project_id;
    this.proposal_id = proposal_id;
  }
}

export function encode_content(content) {
  if (content instanceof Question) {
    let project_id = content.project_id;
    let proposal_id = content.proposal_id;
    let question_id = content.question_id;
    return $json.object(
      toList([
        ["type", $json.string("question")],
        ["question_id", $json.string(question_id)],
        ["proposal_id", $json.string(proposal_id)],
        ["project_id", $json.string(project_id)],
      ]),
    );
  } else if (content instanceof ProposalAssigned) {
    let project_id = content.project_id;
    let proposal_id = content.proposal_id;
    return $json.object(
      toList([
        ["type", $json.string("project-assigned")],
        ["project_id", $json.string(project_id)],
        ["proposal_id", $json.string(proposal_id)],
      ]),
    );
  } else {
    let project_id = content.project_id;
    return $json.object(
      toList([
        ["type", $json.string("proposal-assigned")],
        ["project_id", $json.string(project_id)],
      ]),
    );
  }
}

export function encode(notification) {
  return $json.object(
    toList([
      ["id", $json.string(notification.id)],
      ["read", $json.bool(notification.read)],
      ["content", encode_content(notification.content)],
      ["date", $json.string($birl.to_iso8601(notification.date))],
    ]),
  );
}

export function decode_question(dyn) {
  let $ = $dynamic.field("type", $dynamic.string)(dyn);
  if ($.isOk() && $[0] === "question") {
    let _pipe = $decode.into(
      $decode.parameter(
        (project_id) => {
          return $decode.parameter(
            (proposal_id) => {
              return $decode.parameter(
                (question_id) => {
                  return new Question(project_id, proposal_id, question_id);
                },
              );
            },
          );
        },
      ),
    );
    let _pipe$1 = $decode.field(_pipe, "project_id", $decode.string);
    let _pipe$2 = $decode.field(_pipe$1, "proposal_id", $decode.string);
    let _pipe$3 = $decode.field(_pipe$2, "question_id", $decode.string);
    return $decode.from(_pipe$3, dyn);
  } else {
    return new Error(toList([]));
  }
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (read) => {
            return $decode.parameter(
              (content) => {
                return $decode.parameter(
                  (date) => { return new Notification(id, read, content, date); },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "read", $decode.bool);
  let _pipe$3 = $decode.field(_pipe$2, "content", $decode_.json(decode_content));
  let _pipe$4 = $decode.field(_pipe$3, "date", $decode_.time());
  return $decode.from(_pipe$4, dyn);
}

export function decode_content(dyn) {
  return $dynamic.any(toList([decode_question, decode_proposal_assigned]))(dyn);
}

export function decode_proposal_assigned(dyn) {
  let $ = $dynamic.field("type", $dynamic.string)(dyn);
  if ($.isOk() && $[0] === "proposal-assigned") {
    let _pipe = $decode.into(
      $decode.parameter(
        (project_id) => {
          return $decode.parameter(
            (proposal_id) => {
              return new ProposalAssigned(project_id, proposal_id);
            },
          );
        },
      ),
    );
    let _pipe$1 = $decode.field(_pipe, "project_id", $decode.string);
    let _pipe$2 = $decode.field(_pipe$1, "proposal_id", $decode.string);
    return $decode.from(_pipe$2, dyn);
  } else if ($.isOk() && $[0] === "project-assigned") {
    let _pipe = $decode.into(
      $decode.parameter(
        (project_id) => { return new ProjectAssigned(project_id); },
      ),
    );
    let _pipe$1 = $decode.field(_pipe, "project_id", $decode.string);
    return $decode.from(_pipe$1, dyn);
  } else {
    return new Error(toList([]));
  }
}
