/// <reference types="./integrations.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $connector from "../../../common/data/connector.mjs";
import * as $confluence from "../../../common/data/connector/confluence.mjs";
import * as $notion from "../../../common/data/connector/notion.mjs";
import * as $connector_settings from "../../../common/data/connector_settings.mjs";
import * as $brands from "../../../ds/ds/brands.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $model from "../../data/model.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { Ok, toList, isEqual } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $s from "../../view/styles/organization.mjs";

function view_connector(icon, title, description, children) {
  let child = children[0];
  let column = children[1];
  return $layout.column(
    toList([$layout.gap(12)]),
    toList([]),
    toList([
      $s.connectors_row(
        toList([]),
        toList([
          $s.connectors_description(
            toList([]),
            toList([
              $s.connectors_icon(icon()),
              $s.connectors_title(
                toList([]),
                toList([
                  $s.connectors_main_title(
                    toList([]),
                    toList([$el.text(title)]),
                  ),
                  $el.text(description),
                ]),
              ),
            ]),
          ),
          child,
        ]),
      ),
      column,
    ]),
  );
}

function view_refresh_data_button(connected, connector) {
  return $bool.guard(
    !connected,
    $el.none(),
    () => {
      let refresh_handler = $e.on_click(
        new $msg.UserFetchedConnectorData(connector),
      );
      return $button.secondary(
        toList([refresh_handler]),
        toList([$h.text("Refresh data")]),
      );
    },
  );
}

function view_confluence_inputs_actions(connector, opened, connected) {
  let toggle_handler = $e.on_click(new $msg.UserToggledConfluencePanel());
  return $s.connector_row_actions(
    toList([]),
    toList([
      view_refresh_data_button(connected, connector),
      $button.secondary(
        toList([$a.disabled(connected), toggle_handler]),
        toList([
          $h.text(
            (() => {
              if (connected) {
                return "Connected";
              } else if (!connected && opened) {
                return "Close";
              } else {
                return "Open";
              }
            })(),
          ),
        ]),
      ),
    ]),
  );
}

function view_confluence_inputs(connectors) {
  let connector = new $connector.Confluence();
  let opened = connectors.confluence_opened;
  let connected = (() => {
    let _pipe = connectors.fetched;
    let _pipe$1 = $list.key_find(_pipe, connector);
    return $result.unwrap(_pipe$1, false);
  })();
  let _pipe = view_confluence_inputs_actions(connector, opened, connected);
  return $pair.new$(
    _pipe,
    $bool.guard(
      connected,
      $el.none(),
      () => {
        return $bool.guard(
          !opened,
          $el.none(),
          () => {
            let disabled = $confluence.is_submit_disabled(connectors.confluence);
            let on_submit = $e.on_submit(
              new $msg.UserAskedConnectingTo(new $connector.Confluence()),
            );
            return $layout.column(
              toList([$layout.gap(12), $layout.tag("form")]),
              toList([on_submit]),
              toList([
                $input.input(
                  toList([
                    $input.label("API Key"),
                    $input.placeholder("xxxxxxx"),
                    $input.on_input(
                      (var0) => {
                        return new $msg.UserUpdatedConfluenceApiKey(var0);
                      },
                    ),
                    $input.value(connectors.confluence.api_key),
                  ]),
                ),
                $input.input(
                  toList([
                    $input.label("Domain"),
                    $input.placeholder("my_domain"),
                    $input.on_input(
                      (var0) => {
                        return new $msg.UserUpdatedConfluenceDomain(var0);
                      },
                    ),
                    $input.value(connectors.confluence.domain),
                  ]),
                ),
                $input.input(
                  toList([
                    $input.label("User"),
                    $input.placeholder("john@doe.com"),
                    $input.on_input(
                      (var0) => {
                        return new $msg.UserUpdatedConfluenceUser(var0);
                      },
                    ),
                    $input.value(connectors.confluence.user),
                  ]),
                ),
                $layout.row(
                  toList([$layout.justify("end")]),
                  toList([]),
                  toList([
                    $button.primary(
                      toList([$a.type_("submit"), $a.disabled(disabled)]),
                      toList([$h.text("Submit")]),
                    ),
                  ]),
                ),
              ]),
            );
          },
        );
      },
    ),
  );
}

function view_notion_input_actions(connector, opened, connected) {
  let toggle_handler = $e.on_click(new $msg.UserToggledNotionPanel());
  return $s.connector_row_actions(
    toList([]),
    toList([
      view_refresh_data_button(connected, connector),
      $button.secondary(
        toList([$a.disabled(connected), toggle_handler]),
        toList([
          $h.text(
            (() => {
              if (connected) {
                return "Connected";
              } else if (!connected && opened) {
                return "Close";
              } else {
                return "Open";
              }
            })(),
          ),
        ]),
      ),
    ]),
  );
}

function view_notion_inputs(connectors) {
  let connector = new $connector.Notion();
  let opened = connectors.notion_opened;
  let connected = (() => {
    let _pipe = connectors.fetched;
    let _pipe$1 = $list.key_find(_pipe, connector);
    return $result.unwrap(_pipe$1, false);
  })();
  let _pipe = view_notion_input_actions(connector, opened, connected);
  return $pair.new$(
    _pipe,
    $bool.guard(
      connected,
      $el.none(),
      () => {
        return $bool.guard(
          !opened,
          $el.none(),
          () => {
            let disabled = $notion.is_submit_disabled(connectors.notion);
            let on_submit = $e.on_submit(
              new $msg.UserAskedConnectingTo(new $connector.Notion()),
            );
            return $layout.column(
              toList([$layout.gap(12), $layout.tag("form")]),
              toList([on_submit]),
              toList([
                $input.input(
                  toList([
                    $input.label("API Key"),
                    $input.placeholder("xxxxxxx"),
                    $input.on_input(
                      (var0) => {
                        return new $msg.UserUpdatedNotionApiKey(var0);
                      },
                    ),
                    $input.value(connectors.notion.api_key),
                  ]),
                ),
                $input.input(
                  toList([
                    $input.label("Page ids"),
                    $input.placeholder(
                      "https://notion.so/workspace/page_1;https://notion.so/workspace/page_2",
                    ),
                    $input.on_input(
                      (var0) => {
                        return new $msg.UserUpdatedNotionPageId(var0);
                      },
                    ),
                    $input.value($string.join(connectors.notion.page_ids, ";")),
                  ]),
                ),
                $layout.row(
                  toList([$layout.justify("end")]),
                  toList([]),
                  toList([
                    $button.primary(
                      toList([$a.type_("submit"), $a.disabled(disabled)]),
                      toList([$h.text("Submit")]),
                    ),
                  ]),
                ),
              ]),
            );
          },
        );
      },
    ),
  );
}

function view_folder_select_input(connected, connectors) {
  return $bool.guard(
    !connected,
    $el.none(),
    () => {
      let value = (() => {
        let $ = connectors.google_folder_id;
        if ($ === "") {
          let _pipe = connectors.settings;
          let _pipe$1 = $list.find(_pipe, $connector_settings.is_google_drive);
          let _pipe$2 = $result.then$(
            _pipe$1,
            $connector_settings.google_folder_id,
          );
          return $result.unwrap(_pipe$2, "");
        } else {
          let value = $;
          return value;
        }
      })();
      return $input.input(
        toList([
          $input.placeholder("Folder ID"),
          $input.value(value),
          $input.on_input(
            (var0) => { return new $msg.UserUpdatedGoogleFolderId(var0); },
          ),
          $input.on_submit(new $msg.UserSubmitedGoogleFolderId()),
          $input.enter_submit(),
        ]),
      );
    },
  );
}

function view_gdrive_inputs(connectors, connector) {
  let connected = (() => {
    let _pipe = connectors.fetched;
    let _pipe$1 = $list.key_find(_pipe, connector);
    return $result.unwrap(_pipe$1, false);
  })();
  let connect_handler = $e.on_click(new $msg.UserAskedConnectingTo(connector));
  return $s.connector_row_actions(
    toList([]),
    toList([
      view_refresh_data_button(connected, connector),
      view_folder_select_input(connected, connectors),
      $button.secondary(
        toList([$a.disabled(connected), connect_handler]),
        toList([
          $el.text(
            (() => {
              if (connected) {
                return "Connected";
              } else {
                return "Connect";
              }
            })(),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      $el.fragment(
        toList([
          $common.underlined_title(
            toList([]),
            toList([$el.text("Apps"), $common.separator()]),
          ),
          $s.connectors_block(
            toList([]),
            toList([
              $s.connectors_card(
                toList([]),
                toList([
                  $s.connectors_card_icon($brands.slack()),
                  $s.connector_title(toList([]), toList([$el.text("Slack")])),
                  $s.connector_content(
                    toList([]),
                    toList([
                      $el.text(
                        "Connect your Slack, and ask questions to Steerlab",
                      ),
                      $el.text(" directly in your channel!"),
                    ]),
                  ),
                  (() => {
                    let fetched = (() => {
                      let _pipe = data.connectors.fetched;
                      return $list.key_find(_pipe, new $connector.Slack());
                    })();
                    return $button.secondary(
                      toList([
                        $e.on_click(
                          new $msg.UserAskedConnectingTo(new $connector.Slack()),
                        ),
                        $a.disabled(isEqual(fetched, new Ok(true))),
                      ]),
                      toList([
                        $el.text(
                          (() => {
                            if (fetched.isOk() && fetched[0]) {
                              return "Installed";
                            } else {
                              return "Install";
                            }
                          })(),
                        ),
                      ]),
                    );
                  })(),
                ]),
              ),
              $s.connectors_spacer(toList([]), toList([])),
              $s.connectors_spacer(toList([]), toList([])),
            ]),
          ),
        ]),
      ),
      $common.underlined_title(
        toList([]),
        toList([$el.text("Connections"), $common.separator()]),
      ),
      view_connector(
        $brands.drive,
        "Google Drive",
        "Connect to your Google Drive",
        [
          view_gdrive_inputs(data.connectors, new $connector.GoogleDrive()),
          $el.none(),
        ],
      ),
      view_connector(
        $brands.confluence,
        "Confluence",
        "Connect to your Confluence",
        view_confluence_inputs(data.connectors),
      ),
      view_connector(
        $brands.notion,
        "Notion",
        "Connect to your notion",
        view_notion_inputs(data.connectors),
      ),
      (() => {
        let _pipe = view_connector(
          $brands.onedrive,
          "One Drive",
          "Connect to your One Drive",
          [
            view_gdrive_inputs(data.connectors, new $connector.OneDrive()),
            $el.none(),
          ],
        );
        return ((_) => { return $el.none(); })(_pipe);
      })(),
    ]),
  );
}
