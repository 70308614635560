/// <reference types="./analytics.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $shadows from "../../../ds/ds/shadows.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";

export class Validated extends $CustomType {}

export class InReview extends $CustomType {}

export function section(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("start"),
      $s.gap(px(16)),
    ]),
  );
  return $h.section(_pipe, attrs, children);
}

export function section_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function section_content(attrs, children) {
  let _pipe = $s.class$(toList([$s.width(percent(100))]));
  return $h.div(_pipe, attrs, children);
}

export function statistics_content(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($common.card()),
      $s.padding(px(64)),
      $s.display("flex"),
      $s.width(percent(100)),
      $s.justify_content("center"),
      $s.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(16)),
      $s.justify_content("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_empty_text(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.color($colors.light.grey_800),
      $s.max_width(px(200)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_value(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_label(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.regular)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_color(color) {
  let _pipe = $s.class$(
    toList([
      $s.background(color),
      $s.width(px(16)),
      $s.height(px(16)),
      $s.border_radius(px(4)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function statistics_colored_label(color, attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose($text.xs($text.regular)), $s.color(color)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_content_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.gap(px(64)), $s.min_width(px(550))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $s.class$(toList([$s.width(px(20)), $s.height(px(20))]));
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_element(color, kind, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.regular)),
      $s.text_align("center"),
      $s.background(color),
      $s.white_space("nowrap"),
      $s.overflow("hidden"),
      (() => {
        if (kind instanceof Validated) {
          return $s.border_top_left_radius(px(16));
        } else {
          return $s.border_top_right_radius(px(16));
        }
      })(),
      (() => {
        if (kind instanceof Validated) {
          return $s.border_bottom_left_radius(px(16));
        } else {
          return $s.border_bottom_right_radius(px(16));
        }
      })(),
      $s.text_overflow("ellipsis"),
      $s.padding(px(8)),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function progress_bar(attrs, children) {
  let _pipe = $s.class$(toList([$s.display("flex")]));
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_label(attrs, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.align_items("center")]));
  return $h.div(_pipe, attrs, children);
}

export function progress_collaborator(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.overflow("hidden"),
      $s.gap(px(16)),
      $s.justify_content("space-between"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function progress_content_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_columns("auto 1fr"),
      $s.width(percent(100)),
      $s.flex_direction("column"),
      $s.gap(px(64)),
      $s.min_width(px(550)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_tooltip(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.regular)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.padding(px(12)),
      $s.gap(px(12)),
      $s.border_radius(px(8)),
      $s.background($colors.light.grey_50),
      $s.box_shadow($shadows.m()),
      $s.color($colors.light.grey_800),
      $s.max_height(px(350)),
      $s.overflow("auto"),
      $s.white_space("pre-wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
