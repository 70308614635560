/// <reference types="./proposal_builder.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $form_data from "../../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import { find_column_index, find_nth } from "../../common/utils.mjs";
import * as $spreadsheet_selector from "../../data/ephemeral/spreadsheet_selector.mjs";
import * as $project from "../../data/project.mjs";
import * as $proposal from "../../data/proposal.mjs";
import * as $qualification_matrix from "../../data/qualification_matrix.mjs";
import * as $question from "../../data/question.mjs";
import * as $spreadsheet from "../../data/spreadsheet.mjs";
import {
  AnswerColumn,
  MultipleChoiceColumn,
  QuestionColumn,
  SectionRow,
  UndefinedColumn,
  UndefinedRow,
  Worksheet,
  YesNoColumn,
} from "../../data/spreadsheet.mjs";
import * as $status from "../../data/status.mjs";
import * as $tag from "../../data/tag.mjs";
import * as $translate from "../../data/translate.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError, isEqual } from "../../gleam.mjs";
import * as $list_ from "../../gleam/list/extra.mjs";

export class ProposalBuilder extends $CustomType {
  constructor(step, project_id, project_client, project_collaborators, project_deadline, project_languages, project_tags, project_name, project_attachments, proposal_collaborators, proposal_languages, proposal_name, proposal_qualification_matrix, proposal_spreadsheet) {
    super();
    this.step = step;
    this.project_id = project_id;
    this.project_client = project_client;
    this.project_collaborators = project_collaborators;
    this.project_deadline = project_deadline;
    this.project_languages = project_languages;
    this.project_tags = project_tags;
    this.project_name = project_name;
    this.project_attachments = project_attachments;
    this.proposal_collaborators = proposal_collaborators;
    this.proposal_languages = proposal_languages;
    this.proposal_name = proposal_name;
    this.proposal_qualification_matrix = proposal_qualification_matrix;
    this.proposal_spreadsheet = proposal_spreadsheet;
  }
}

export function init(project_id) {
  return new ProposalBuilder(
    (() => {
      let _pipe = project_id;
      let _pipe$1 = $option.map(_pipe, (_) => { return 2; });
      return $option.unwrap(_pipe$1, 1);
    })(),
    project_id,
    "",
    toList([]),
    $birl.now(),
    toList([]),
    toList([]),
    "",
    toList([]),
    toList([]),
    toList([]),
    "",
    new $option.None(),
    $spreadsheet_selector.new$(),
  );
}

export function add_project_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.project_collaborators;
    let _pipe$1 = $list_.insert(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        c,
        _record.project_deadline,
        _record.project_languages,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function add_proposal_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.proposal_collaborators;
    let _pipe$1 = $list_.insert(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        c,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function add_project_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.insert(_pipe, language);
    return ((l) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        l,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function add_proposal_language(language) {
  return (builder) => {
    let _pipe = builder.proposal_languages;
    let _pipe$1 = $list_.insert(_pipe, language);
    return ((l) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        l,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function add_project_tag(tag) {
  return (builder) => {
    let _pipe = builder.project_tags;
    let _pipe$1 = $list_.insert(_pipe, tag);
    return ((t) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        t,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function add_project_attachments(attachments) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_tags,
      _record.project_name,
      attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
    );
  };
}

export function remove_project_attachment(index) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_tags,
      _record.project_name,
      (() => {
        let _pipe = builder.project_attachments;
        let _pipe$1 = $list.index_map(_pipe, $pair.new$);
        let _pipe$2 = $list.filter(
          _pipe$1,
          (file) => { return file[1] !== index; },
        );
        return $list.map(_pipe$2, $pair.first);
      })(),
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
    );
  };
}

export function remove_project_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.remove(_pipe, language);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        c,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function remove_proposal_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.remove(_pipe, language);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        c,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function remove_project_tag(tag) {
  return (builder) => {
    let _pipe = builder.project_tags;
    let _pipe$1 = $list_.remove(_pipe, tag);
    return ((t) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        t,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function remove_project_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.project_collaborators;
    let _pipe$1 = $list_.remove(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        c,
        _record.project_deadline,
        _record.project_languages,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function remove_proposal_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.proposal_collaborators;
    let _pipe$1 = $list_.remove(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        c,
        _record.proposal_languages,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
      );
    })(_pipe$1);
  };
}

export function update_project_client(project_client) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
    );
  };
}

export function update_project_deadline(project_deadline) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      project_deadline,
      _record.project_languages,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
    );
  };
}

export function update_project_name(project_name) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_tags,
      project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
    );
  };
}

export function next_step(builder) {
  let step = builder.step + 1;
  let _record = builder;
  return new ProposalBuilder(
    step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_name,
    _record.proposal_qualification_matrix,
    _record.proposal_spreadsheet,
  );
}

export function update_qualification_matrix_item(id, answer) {
  return (builder) => {
    let proposal_qualification_matrix = builder.proposal_qualification_matrix;
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_name,
      $option.map(
        proposal_qualification_matrix,
        (qualification_matrix) => {
          return $list.map(
            qualification_matrix,
            (qm) => {
              return $bool.guard(
                qm[0].id !== id,
                qm,
                () => { return [qm[0], answer]; },
              );
            },
          );
        },
      ),
      _record.proposal_spreadsheet,
    );
  };
}

export function use_qualification_matrix(builder, qualification_matrix) {
  let proposal_qualification_matrix = new $option.Some(qualification_matrix);
  let _record = builder;
  return new ProposalBuilder(
    _record.step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_name,
    proposal_qualification_matrix,
    _record.proposal_spreadsheet,
  );
}

export function back_to_upload(builder) {
  let step = builder.step - 1;
  let proposal_spreadsheet = $spreadsheet_selector.new$();
  let _record = builder;
  return new ProposalBuilder(
    step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_name,
    _record.proposal_qualification_matrix,
    proposal_spreadsheet,
  );
}

export function encode_project(builder, org_id, owner) {
  return $project.encode(
    new $project.Project(
      "",
      builder.project_name,
      owner,
      org_id,
      builder.project_deadline,
      builder.project_client,
      builder.project_collaborators,
      new $project.Metadata(builder.project_languages),
      $birl.utc_now(),
      new $status.InProgress(),
    ),
  );
}

function find_default_dict(dicts, name) {
  let _pipe = $list.key_find(dicts, name);
  return $result.unwrap(_pipe, $dict.new$());
}

function default_get(dict, key, default$) {
  let _pipe = $dict.get(dict, key);
  return $result.unwrap(_pipe, default$);
}

function encode_question_metadata(cell, choices, x, y) {
  return $json.object(
    toList([
      ["x", $json.int(x)],
      ["y", $json.int(y)],
      ["question", $json.nullable(cell.value, $json.string)],
      ["choices", $json.array(choices, $json.string)],
    ]),
  );
}

function find_choices_associated_to_question(types, row, cell, x, y) {
  let _pipe = find_column_index(
    $dict.to_list(types),
    new MultipleChoiceColumn(),
  );
  let _pipe$1 = $result.then$(
    _pipe,
    (_capture) => {
      return find_nth(
        $list.map(row, (x) => { return x.dropdown; }),
        _capture,
        0,
      );
    },
  );
  let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
  return ((_capture) => {
    return encode_question_metadata(cell, _capture, x, y);
  })(_pipe$2);
}

function encode_sheet_questions(content, invalid, types) {
  return $list.flatten(
    $list.index_map(
      content,
      (row, y) => {
        return $list.flatten(
          $list.index_map(
            row,
            (cell, x) => {
              let is_section = isEqual(
                default_get(invalid, y, new UndefinedRow()),
                new SectionRow()
              );
              let is_undefined = isEqual(
                default_get(invalid, y, new UndefinedRow()),
                new UndefinedRow()
              );
              let is_question = isEqual(
                default_get(types, x, new UndefinedColumn()),
                new QuestionColumn()
              );
              let is_invalid = (is_section || is_undefined) || !is_question;
              return $bool.guard(
                is_invalid,
                toList([]),
                () => {
                  return toList([
                    find_choices_associated_to_question(types, row, cell, x, y),
                  ]);
                },
              );
            },
          ),
        );
      },
    ),
  );
}

export function encode_questions(data, builder) {
  let proposal_spreadsheet = builder.proposal_spreadsheet;
  return $json.preprocessed_array(
    $list.filter_map(
      data,
      (_use0) => {
        let name = _use0.name;
        let start_index = _use0.start_index;
        let content = _use0.content;
        let types = find_default_dict(proposal_spreadsheet.columns_type, name);
        let invalid = find_default_dict(
          proposal_spreadsheet.invalid_lines,
          name,
        );
        let questions = encode_sheet_questions(content, invalid, types);
        return $bool.guard(
          $list.is_empty(questions),
          new Error(undefined),
          () => {
            let _pipe = $json.object(
              toList([
                ["sheet", $json.string(name)],
                ["start_index", $json.int(start_index)],
                ["questions", $json.preprocessed_array(questions)],
              ]),
            );
            return new Ok(_pipe);
          },
        );
      },
    ),
  );
}

export function require_qualification_matrix(builder, next) {
  let $ = builder.proposal_qualification_matrix;
  if ($ instanceof $option.None) {
    throw makeError(
      "panic",
      "data/ephemeral/proposal_builder",
      394,
      "require_qualification_matrix",
      "Qualification matrix not selected",
      {}
    )
  } else {
    let qualification_matrix = $[0];
    return next(qualification_matrix);
  }
}

const valid_columns = /* @__PURE__ */ toList([
  /* @__PURE__ */ new AnswerColumn(),
  /* @__PURE__ */ new YesNoColumn(),
  /* @__PURE__ */ new MultipleChoiceColumn(),
]);

function encode_proposal_questionnaire_responses(builder) {
  let proposal_spreadsheet = builder.proposal_spreadsheet;
  return $list.flat_map(
    proposal_spreadsheet.columns_type,
    (_use0) => {
      let name = _use0[0];
      let columns = _use0[1];
      let is_valid_column = (_capture) => {
        return $list.contains(valid_columns, _capture);
      };
      let _pipe = columns;
      let _pipe$1 = $dict.to_list(_pipe);
      let _pipe$2 = $list.filter(
        _pipe$1,
        (a) => { return is_valid_column(a[1]); },
      );
      return $list.map(
        _pipe$2,
        (column) => {
          return new $proposal.QuestionnaireResponse(
            name,
            column[0],
            (() => {
              let $ = column[1];
              if ($ instanceof AnswerColumn) {
                return new $question.Long();
              } else if ($ instanceof YesNoColumn) {
                return new $question.YesNo();
              } else if ($ instanceof MultipleChoiceColumn) {
                return new $question.MultipleChoice();
              } else {
                throw makeError(
                  "panic",
                  "data/ephemeral/proposal_builder",
                  319,
                  "",
                  "Unexpected behaviour",
                  {}
                )
              }
            })(),
          );
        },
      );
    },
  );
}

export function encode_proposal(builder, org_id, owner, xlsx) {
  return $proposal.encode(
    new $proposal.Proposal(
      "",
      new $proposal.Display("questionnaire"),
      org_id,
      builder.proposal_name,
      builder.project_deadline,
      owner,
      builder.project_client,
      builder.proposal_collaborators,
      0,
      new $status.InProgress(),
      new $proposal.Questionnaire(
        (() => {
          let _pipe = builder.proposal_spreadsheet.xlsx;
          let _pipe$1 = $option.map(_pipe, (a) => { return a.data; });
          let _pipe$2 = $option.map(
            _pipe$1,
            (_capture) => {
              return $list.map(_capture, (a) => { return a.name; });
            },
          );
          return $option.unwrap(_pipe$2, toList([]));
        })(),
        encode_proposal_questionnaire_responses(builder),
        builder.proposal_qualification_matrix,
        xlsx.data,
        builder.proposal_languages,
        builder.project_tags,
      ),
      $birl.utc_now(),
      new $proposal.Sheets(),
      $option.unwrap(builder.project_id, ""),
      new $option.None(),
    ),
  );
}

export function to_form_data(builder, org_id, user_id) {
  let _pipe = $option.map(
    builder.proposal_spreadsheet.xlsx,
    (xlsx) => {
      let owner = user_id;
      let _pipe = $json.object(
        toList([
          ["proposal", encode_proposal(builder, org_id, owner, xlsx)],
          ["spreadsheet", $spreadsheet.encode(xlsx)],
          ["questions", encode_questions(xlsx.data, builder)],
        ]),
      );
      let _pipe$1 = $json.to_string(_pipe);
      let _pipe$2 = ((_capture) => {
        return $form_data.append($form_data.new$(), "content", _capture);
      })(_pipe$1);
      let _pipe$3 = $form_data.append(_pipe$2, "filename", xlsx.name);
      let _pipe$4 = $form_data.append_bits(_pipe$3, "blob", xlsx.blob);
      return ((_capture) => {
        return $list.fold(
          builder.project_attachments,
          _capture,
          (data, file) => {
            let name = "attachment_" + file.name;
            return $form_data.append_bits(data, name, file.content);
          },
        );
      })(_pipe$4);
    },
  );
  return $option.to_result(_pipe, undefined);
}
