import { Chart } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

class BarChart extends HTMLElement {
  #shadow: ShadowRoot
  #canvas?: HTMLCanvasElement
  datasets: any
  orientation: string

  constructor() {
    super()
    this.#shadow = this.attachShadow({ mode: 'open' })
    this.orientation = 'horizontal'
  }

  connectedCallback() {
    this.#render()
  }

  #render() {
    const datasets = this.datasets.toArray()
    const orientation = this.orientation
    const isHorizontal = orientation === 'horizontal'
    console.log(isHorizontal)
    const emptyState =
      datasets.length > 0
        ? datasets
        : [
            {
              colors: { toArray: () => ['#D3D3D3'] },
              data: { toArray: () => [0] },
              labels: { toArray: () => ['Default'] },
            },
          ]
    const wrapper = document.createElement('div')
    wrapper.style.position = 'relative'
    wrapper.style.maxWidth = '400px'
    wrapper.style.maxHeight = '300px'
    this.#canvas = document.createElement('canvas')
    wrapper.appendChild(this.#canvas)
    this.#shadow.appendChild(wrapper)

    new Chart(this.#canvas, {
      type: 'bar',
      data: {
        labels: emptyState[0].labels.toArray(),
        datasets: emptyState.map((dataset: any) => ({
          backgroundColor: dataset.colors.toArray(),
          data: dataset.data.toArray(),
          labels: dataset.labels.toArray(),
        })),
      },
      options: {
        indexAxis: isHorizontal ? 'y' : 'x',
        responsive: true,
        animation: false,
        events: [],
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: (value: number) => value.toFixed(0), // Format numbers as integers
            color: '#000', // Text color
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Disable grid lines on the x-axis
            },
          },
          y: {
            grid: {
              display: false, // Disable grid lines on the y-axis
            },
            ticks: {
              display: false, // Remove the labels on the y-axis
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    })
  }

  // Lifecycle functions.
  disconnectedCallback() {}
  adoptedCallback() {}
  attributeChangedCallback() {}
}

export function register() {
  customElements.define('bar-chart', BarChart)
}
