/// <reference types="./editor_menu.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function editor_menu() {
  let content = "<path d=\"M7 11.6147C6.40905 11.6147 5.82389 11.4984 5.27792 11.2722C4.73196 11.0461 4.23588 10.7146 3.81802 10.2967C3.40016 9.87886 3.06869 9.38279 2.84254 8.83682C2.6164 8.29086 2.5 7.70569 2.5 7.11475C2.5 6.5238 2.6164 5.93864 2.84254 5.39267C3.06869 4.84671 3.40016 4.35063 3.81802 3.93277C4.23588 3.5149 4.73196 3.18343 5.27792 2.95729C5.82389 2.73114 6.40905 2.61475 7 2.61475C8.19347 2.61475 9.33807 3.08885 10.182 3.93277C11.0259 4.77668 11.5 5.92127 11.5 7.11475C11.5 8.30822 11.0259 9.45281 10.182 10.2967C9.33807 11.1406 8.19347 11.6147 7 11.6147ZM7 21.6147C5.80653 21.6147 4.66193 21.1406 3.81802 20.2967C2.97411 19.4528 2.5 18.3082 2.5 17.1147C2.5 15.9213 2.97411 14.7767 3.81802 13.9328C4.66193 13.0889 5.80653 12.6147 7 12.6147C8.19347 12.6147 9.33807 13.0889 10.182 13.9328C11.0259 14.7767 11.5 15.9213 11.5 17.1147C11.5 18.3082 11.0259 19.4528 10.182 20.2967C9.33807 21.1406 8.19347 21.6147 7 21.6147ZM17 11.6147C16.4091 11.6147 15.8239 11.4984 15.2779 11.2722C14.732 11.0461 14.2359 10.7146 13.818 10.2967C13.4002 9.87886 13.0687 9.38279 12.8425 8.83682C12.6164 8.29086 12.5 7.70569 12.5 7.11475C12.5 6.5238 12.6164 5.93864 12.8425 5.39267C13.0687 4.84671 13.4002 4.35063 13.818 3.93277C14.2359 3.5149 14.732 3.18343 15.2779 2.95729C15.8239 2.73114 16.4091 2.61475 17 2.61475C18.1935 2.61475 19.3381 3.08885 20.182 3.93277C21.0259 4.77668 21.5 5.92127 21.5 7.11475C21.5 8.30822 21.0259 9.45281 20.182 10.2967C19.3381 11.1406 18.1935 11.6147 17 11.6147ZM17 21.6147C15.8065 21.6147 14.6619 21.1406 13.818 20.2967C12.9741 19.4528 12.5 18.3082 12.5 17.1147C12.5 15.9213 12.9741 14.7767 13.818 13.9328C14.6619 13.0889 15.8065 12.6147 17 12.6147C18.1935 12.6147 19.3381 13.0889 20.182 13.9328C21.0259 14.7767 21.5 15.9213 21.5 17.1147C21.5 18.3082 21.0259 19.4528 20.182 20.2967C19.3381 21.1406 18.1935 21.6147 17 21.6147Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
