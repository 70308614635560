/// <reference types="./slack_login.d.mts" />
import * as $logo from "../../ds/ds/logo.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import { toList } from "../gleam.mjs";
import * as $s from "../view/styles/login.mjs";

export function view(_) {
  return $s.wrapper(
    toList([]),
    toList([
      $s.background(toList([$a.src("/assets/background.png")])),
      $s.topbar(
        toList([]),
        toList([
          $s.topbar_logo(
            toList([$a.style(toList([]))]),
            toList([$logo.light()]),
          ),
          $s.topbar_name(toList([]), toList([$html.text("Steerlab")])),
        ]),
      ),
      $s.body(
        toList([]),
        toList([
          $s.body_glass(
            toList([]),
            toList([
              $s.body_title(
                toList([]),
                toList([$html.text("Your Slack profile has been saved.")]),
              ),
              $html.div(
                toList([]),
                toList([
                  $html.text(
                    "Your Slack profile is now linked to your Steerlab account. ",
                  ),
                  $html.text(
                    "You can now talk to Steerlab directly inside Slack! ",
                  ),
                  $html.text("You can close that window now. "),
                  $html.text("If you have issues, please, contact us at "),
                  $html.a(
                    toList([$a.href("mailto:support@steerlab.ai")]),
                    toList([$html.text("support@steerlab.ai")]),
                  ),
                  $html.text("."),
                ]),
              ),
              $s.thanks(toList([]), toList([$html.text("Thanks!")])),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
