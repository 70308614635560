/// <reference types="./window.d.mts" />
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $route from "../../data/route.mjs";
import { updateTitle as do_update_title } from "../../frontend.ffi.mjs";

export function update_page_title(route) {
  return $effect.from(
    (_) => {
      let _pipe = $route.to_title(route);
      let _pipe$1 = ((_capture) => {
        return $string.append("Steerlab — ", _capture);
      })(_pipe);
      return do_update_title(_pipe$1);
    },
  );
}
