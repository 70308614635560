/// <reference types="./loading.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Loading extends $CustomType {
  constructor(connectors, connectors_settings, content_library, copilot_questions, notifications, organization_members, projects, proposals, questions, users) {
    super();
    this.connectors = connectors;
    this.connectors_settings = connectors_settings;
    this.content_library = content_library;
    this.copilot_questions = copilot_questions;
    this.notifications = notifications;
    this.organization_members = organization_members;
    this.projects = projects;
    this.proposals = proposals;
    this.questions = questions;
    this.users = users;
  }
}

export function new$() {
  return new Loading(
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    true,
  );
}

export function connectors(loading) {
  let _record = loading;
  return new Loading(
    false,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function connectors_settings(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    false,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function content_library(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    false,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function copilot_questions(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    false,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function notifications(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    false,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function organization_members(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    false,
    _record.projects,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function projects(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    false,
    _record.proposals,
    _record.questions,
    _record.users,
  );
}

export function proposals(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    false,
    _record.questions,
    _record.users,
  );
}

export function questions_loaded(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    false,
    _record.users,
  );
}

export function questions_loading(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    true,
    _record.users,
  );
}

export function users(loading) {
  let _record = loading;
  return new Loading(
    _record.connectors,
    _record.connectors_settings,
    _record.content_library,
    _record.copilot_questions,
    _record.notifications,
    _record.organization_members,
    _record.projects,
    _record.proposals,
    _record.questions,
    true,
  );
}
