/// <reference types="./model.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $data from "../data/model/data.mjs";
import * as $route from "../data/route.mjs";
import { makeError } from "../gleam.mjs";

export function replace_proposal_question_page(data, computed) {
  return $bool.guard(
    data.loading.questions,
    $effect.none(),
    () => {
      let $ = data.route;
      if ($ instanceof $route.Projects &&
      $[0] instanceof $route.ProjectProposals &&
      $[0].proposal instanceof $route.ShowProposal) {
        let pid = $[0].id;
        let route = $[0].proposal;
        let $1 = (() => {
          let _pipe = computed.displayed_questions;
          let _pipe$1 = $list.length(_pipe);
          return $int.divide(_pipe$1, data.proposal_block_page_size);
        })();
        if (!$1.isOk()) {
          throw makeError(
            "let_assert",
            "data/model",
            22,
            "",
            "Pattern match failed, no pattern matched the value.",
            { value: $1 }
          )
        }
        let page_max = $1[0];
        return $bool.guard(
          route.page <= page_max,
          $effect.none(),
          () => {
            let _pipe = new $route.ShowProposal(route.id, route.sheet, page_max);
            let _pipe$1 = ((_capture) => {
              return new $route.ProjectProposals(pid, _capture);
            })(_pipe);
            let _pipe$2 = new $route.Projects(_pipe$1);
            return $route.replace(_pipe$2);
          },
        );
      } else {
        return $effect.none();
      }
    },
  );
}

export function find_question(data, proposal_id, question_id) {
  let _pipe = data.questions.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, question_id); },
  );
}

export function unsubscribe_more_proposal(data) {
  return $effect.from(
    (_) => {
      let unsub = $option.unwrap(
        data.more_proposal_unsubscriber,
        () => { return undefined; },
      );
      return unsub();
    },
  );
}
