/// <reference types="./illustrations.d.mts" />
import * as $ai_search from "../ds/illustrations/ai_search.mjs";
import * as $documents_stack from "../ds/illustrations/documents_stack.mjs";
import * as $lightbulb_flash from "../ds/illustrations/lightbulb_flash.mjs";
import * as $refresh_book from "../ds/illustrations/refresh_book.mjs";

export const ai_search = $ai_search.ai_search;

export const documents_stack = $documents_stack.documents_stack;

export const lightbulb_flash = $lightbulb_flash.lightbulb_flash;

export const refresh_book = $refresh_book.refresh_book;
