/// <reference types="./copilot.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $copilot_question from "../../../common/data/copilot_question.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $ffi from "../../frontend/ffi.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";

export function submit_copilot(data, thread_id) {
  let input = data.copilot_input;
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  let question = new $copilot_question.CopilotQuestion(
                    $ffi.uuid(),
                    thread_id,
                    new $copilot_question.Display(new $option.None()),
                    input.value,
                    user_id,
                    new $copilot_question.Answer(
                      new $option.None(),
                      new $option.None(),
                      new $option.None(),
                      new $option.None(),
                    ),
                    org_id,
                    true,
                    toList([]),
                    $birl.utc_now(),
                    true,
                  );
                  dispatch(
                    new $msg.OnCopilotQuestionUpdated(
                      [question, toList([]), toList([])],
                    ),
                  );
                  let at = toList(["copilot", thread_id]);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                  let _pipe$3 = $ask.with$(
                    _pipe$2,
                    $json.object(
                      toList([["question", $copilot_question.encode(question)]]),
                    ),
                  );
                  let _pipe$4 = $ask.expect(
                    _pipe$3,
                    $dynamic.decode3(
                      (a, b, c) => { return [a, b, c]; },
                      $dynamic.field("question", $copilot_question.decode),
                      $dynamic.field(
                        "data_sources",
                        $dynamic.list($data_source.decode),
                      ),
                      $dynamic.field(
                        "data_points",
                        $dynamic.list($data_point.decode),
                      ),
                    ),
                  );
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (q) => {
                      return dispatch(new $msg.OnCopilotQuestionUpdated(q));
                    },
                  );
                  $ask.run(_pipe$5)
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_copilot_questions(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let decode_data_sources = $dynamic.dict(
            $dynamic.string,
            $dynamic.list($data_source.decode),
          );
          let decode_data_points = $dynamic.dict(
            $dynamic.string,
            $dynamic.list($data_point.decode),
          );
          let _pipe = $ask.to(new $ask.Heimdall(), toList(["copilot"]));
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(
            _pipe$1,
            $dynamic.decode3(
              (a, b, c) => { return [a, b, c]; },
              $dynamic.field(
                "questions",
                $dynamic.list($copilot_question.decode),
              ),
              $dynamic.field("data_sources", decode_data_sources),
              $dynamic.field("data_points", decode_data_points),
            ),
          );
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (t) => { return dispatch(new $msg.OnCopilotThreads(t)); },
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}
