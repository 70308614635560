/// <reference types="./feed.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $duration from "../../birl/birl/duration.mjs";
import * as $bright from "../../bright/bright.mjs";
import * as $notification from "../../common/data/notification.mjs";
import { ProjectAssigned, ProposalAssigned } from "../../common/data/notification.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $skeleton from "../../ds/ds/components/skeleton.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, makeError } from "../gleam.mjs";
import * as $common from "../view/common.mjs";
import * as $s from "../view/styles/feed.mjs";

class Loading extends $CustomType {}

class Loaded extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class NotLoaded extends $CustomType {}

function select_active_dot(notification) {
  let $ = notification.read;
  if (!$) {
    return $s.active_dot;
  } else {
    return $s.inactive_dot;
  }
}

function view_loading() {
  return $layout.column(
    toList([$layout.gap(4)]),
    toList([]),
    toList([
      $skeleton.skeleton(250, 10),
      $skeleton.skeleton(200, 10),
      $skeleton.skeleton(200, 10),
    ]),
  );
}

function view_timestamp(notification) {
  let delta = $birl.difference($birl.utc_now(), notification.date);
  let hours = $duration.blur_to(delta, new $duration.Hour());
  if (hours < 24) {
    let x = hours;
    return "Today";
  } else if (hours < 48) {
    let x = hours;
    return "Yesterday";
  } else {
    let month = $birl.short_string_month(notification.date);
    let date = $int.to_string($birl.get_day(notification.date).date);
    return $string.join(toList([month, date]), " ");
  }
}

function view_notification_scaffold(notification, loading) {
  let handler = $e.on_click(new $msg.UserClickedNotification(notification));
  let active_dot = select_active_dot(notification);
  return $layout.row(
    toList([$layout.gap(32), $layout.padding(16)]),
    toList([handler]),
    toList([
      $layout.row(
        toList([$layout.gap(16), $layout.flex("1")]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.justify("center")]),
            toList([]),
            toList([active_dot()]),
          ),
          (() => {
            if (loading instanceof Loading) {
              return view_loading();
            } else if (loading instanceof NotLoaded) {
              return $s.text($h.text("An error occured. Please, retry later."));
            } else {
              let msg = loading[0];
              let $ = notification.read;
              if ($) {
                return $s.dim_text($h.text(msg));
              } else {
                return $s.text($h.text(msg));
              }
            }
          })(),
        ]),
      ),
      $s.date(toList([]), toList([$h.text(view_timestamp(notification))])),
    ]),
  );
}

function view_question(model, notification) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = notification.content;
  if (!($1 instanceof $notification.Question)) {
    throw makeError(
      "let_assert",
      "view/feed",
      81,
      "view_question",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let proposal_id = $1.proposal_id;
  let proposal = $list.key_find(data.proposals, proposal_id);
  let $2 = data.loading.proposals;
  if ($2) {
    return view_notification_scaffold(notification, new Loading());
  } else if (!$2 && !proposal.isOk()) {
    return view_notification_scaffold(notification, new NotLoaded());
  } else {
    let proposal$1 = proposal[0];
    let msg = ("You've been assigned to a question on " + proposal$1.name) + ".";
    return view_notification_scaffold(notification, new Loaded(msg));
  }
}

function view_proposal_assigned(model, notification) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = notification.content;
  if (!($1 instanceof ProposalAssigned)) {
    throw makeError(
      "let_assert",
      "view/feed",
      95,
      "view_proposal_assigned",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let proposal_id = $1.proposal_id;
  let proposal = $list.key_find(data.proposals, proposal_id);
  let $2 = data.loading.proposals;
  if ($2) {
    return view_notification_scaffold(notification, new Loading());
  } else if (!$2 && !proposal.isOk()) {
    return view_notification_scaffold(notification, new NotLoaded());
  } else {
    let proposal$1 = proposal[0];
    let msg = ("You've been assigned to the proposal " + proposal$1.name) + ".";
    return view_notification_scaffold(notification, new Loaded(msg));
  }
}

function view_project_assigned(model, notification) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = notification.content;
  if (!($1 instanceof ProjectAssigned)) {
    throw makeError(
      "let_assert",
      "view/feed",
      109,
      "view_project_assigned",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let project_id = $1.project_id;
  let project = $list.key_find(data.projects, project_id);
  let $2 = data.loading.projects;
  if ($2) {
    return view_notification_scaffold(notification, new Loading());
  } else if (!$2 && !project.isOk()) {
    return view_notification_scaffold(notification, new NotLoaded());
  } else {
    let project$1 = project[0];
    let msg = ("You've been assigned to the project " + project$1.name) + ".";
    return view_notification_scaffold(notification, new Loaded(msg));
  }
}

function view_notification(model, notification) {
  let $ = notification.content;
  if ($ instanceof $notification.Question) {
    return view_question(model, notification);
  } else if ($ instanceof ProposalAssigned) {
    return view_proposal_assigned(model, notification);
  } else {
    return view_project_assigned(model, notification);
  }
}

export function feed(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let left = (() => {
    let $1 = data.feed_opened;
    let $2 = data.collapsed_navbar;
    if ($1 && !$2) {
      return "350px";
    } else if ($1 && $2) {
      return "95px";
    } else if (!$1 && !$2) {
      return "-100px";
    } else {
      return "-400px";
    }
  })();
  let enabled = $list.any(data.notifications, (n) => { return !n.read; });
  return $s.wrapper(
    toList([$a.id("feed-wrapper"), $a.style(toList([["left", left]]))]),
    listPrepend(
      $s.feed_title(
        toList([$a.id("feed-title")]),
        toList([
          $s.title(toList([]), toList([$h.text("Feed")])),
          $s.mark_all_as_read(
            toList([
              $a.disabled(!enabled),
              $e.on_click(new $msg.MarkAllNotificationsAsRead()),
            ]),
            toList([
              $s.icon_wrapper($icons.double_fill()),
              $h.text("Mark all as read"),
            ]),
          ),
        ]),
      ),
      (() => {
        let _pipe = $list.map(
          data.notifications,
          (_capture) => { return view_notification(model, _capture); },
        );
        return $list.intersperse(_pipe, $common.separator());
      })(),
    ),
  );
}
