import { Extension } from '@tiptap/core'
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state'
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view'
import * as html from './html'

export type HoverBlockOptions = {}

export const HoverBlock = Extension.create<HoverBlockOptions>({
  name: 'hoverBlock',

  addOptions() {
    return {}
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),
        state: {
          init(_config, instance) {
            return DecorationSet.create(instance.doc, [])
          },
          apply(tr, decorations) {
            const from = tr.getMeta('pos')
            switch (tr.getMeta('event')) {
              case 'mouseenter':
                return onMouseEnter(tr, from, decorations)
              case 'mouseleave':
                return onMouseLeave(decorations)
              case 'remove-everything':
                return remove(tr, decorations)
              default:
                return decorations
            }
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)
          },
          handleDOMEvents: {
            mouseover: (view, event) => {
              emitTr(view, event, 'mouseenter')
              setTimeout(() => {
                const tr = view.state.tr.setMeta('event', 'remove-everything')
                view.dispatch(tr)
              }, 300)
            },
            mouseleave: (view, _event) => {
              view.dispatch(view.state.tr.setMeta('event', 'mouseleave'))
              setTimeout(() => {
                const tr = view.state.tr.setMeta('event', 'remove-everything')
                view.dispatch(tr)
              }, 300)
            },
          },
        },
      }),
    ]
  },
})

function onMouseEnter(
  tr: Transaction,
  from: number,
  decorations: DecorationSet
) {
  const existingDecorations = decorations.find(from)
  if (existingDecorations.find(d => d.from === from)) return decorations
  decorations.find().map(d => d.spec.destroyMenu())
  const [wrapper, menu] = menuButton()
  const decoration = Decoration.widget(from, wrapper, {
    destroyMenu: () => menu.setAttribute('destroy', 'true'),
    isDeleted: () => menu.getAttribute('destroy') === 'true',
  })
  return decorations.add(tr.doc, [decoration])
}

function onMouseLeave(decorations: DecorationSet) {
  decorations.find().map(d => d.spec.destroyMenu())
  return decorations
}

function remove(tr: Transaction, decorations: DecorationSet) {
  const alive = decorations.find().filter(d => !d.spec.isDeleted())
  return DecorationSet.create(tr.doc, alive)
}

function menuButton() {
  const menu = html.element('editor-menu', [], [])
  const wrapper = html.button([html.class_('menu-button-wrapper')], [menu])
  return [wrapper, menu] as const
}

function emitTr(view: EditorView, event: MouseEvent, eventName: string) {
  // target is the main ProseMirror view.
  if (event.target === view.dom) return
  if (!(event.target instanceof HTMLElement)) return
  const pos = view.posAtDOM(event.target, 0)
  view.dispatch(view.state.tr.setMeta('pos', pos).setMeta('event', eventName))
}

export default HoverBlock
