/// <reference types="./zones.d.mts" />
import { toList } from "../gleam.mjs";

export const list = /* @__PURE__ */ toList([
  ["Africa/Abidjan", 0],
  ["Africa/Algiers", 3600],
  ["Africa/Bissau", 0],
  ["Africa/Cairo", 7200],
  ["Africa/Casablanca", 3600],
  ["Africa/Ceuta", 3600],
  ["Africa/El_Aaiun", 3600],
  ["Africa/Johannesburg", 7200],
  ["Africa/Juba", 7200],
  ["Africa/Khartoum", 7200],
  ["Africa/Lagos", 3600],
  ["Africa/Maputo", 7200],
  ["Africa/Monrovia", 0],
  ["Africa/Nairobi", 10_800],
  ["Africa/Ndjamena", 3600],
  ["Africa/Sao_Tome", 0],
  ["Africa/Tripoli", 7200],
  ["Africa/Tunis", 3600],
  ["Africa/Windhoek", 7200],
  ["America/Adak", -36_000],
  ["America/Anchorage", -32_400],
  ["America/Araguaina", -10_800],
  ["America/Argentina/Buenos_Aires", -10_800],
  ["America/Argentina/Catamarca", -10_800],
  ["America/Argentina/Cordoba", -10_800],
  ["America/Argentina/Jujuy", -10_800],
  ["America/Argentina/La_Rioja", -10_800],
  ["America/Argentina/Mendoza", -10_800],
  ["America/Argentina/Rio_Gallegos", -10_800],
  ["America/Argentina/Salta", -10_800],
  ["America/Argentina/San_Juan", -10_800],
  ["America/Argentina/San_Luis", -10_800],
  ["America/Argentina/Tucuman", -10_800],
  ["America/Argentina/Ushuaia", -10_800],
  ["America/Asuncion", -14_400],
  ["America/Bahia", -10_800],
  ["America/Bahia_Banderas", -21_600],
  ["America/Barbados", -14_400],
  ["America/Belem", -10_800],
  ["America/Belize", -21_600],
  ["America/Boa_Vista", -14_400],
  ["America/Bogota", -18_000],
  ["America/Boise", -25_200],
  ["America/Cambridge_Bay", -25_200],
  ["America/Campo_Grande", -14_400],
  ["America/Cancun", -18_000],
  ["America/Caracas", -14_400],
  ["America/Cayenne", -10_800],
  ["America/Chicago", -21_600],
  ["America/Chihuahua", -21_600],
  ["America/Ciudad_Juarez", -25_200],
  ["America/Costa_Rica", -21_600],
  ["America/Cuiaba", -14_400],
  ["America/Danmarkshavn", 0],
  ["America/Dawson", -25_200],
  ["America/Dawson_Creek", -25_200],
  ["America/Denver", -25_200],
  ["America/Detroit", -18_000],
  ["America/Edmonton", -25_200],
  ["America/Eirunepe", -18_000],
  ["America/El_Salvador", -21_600],
  ["America/Fort_Nelson", -25_200],
  ["America/Fortaleza", -10_800],
  ["America/Glace_Bay", -14_400],
  ["America/Goose_Bay", -14_400],
  ["America/Grand_Turk", -18_000],
  ["America/Guatemala", -21_600],
  ["America/Guayaquil", -18_000],
  ["America/Guyana", -14_400],
  ["America/Halifax", -14_400],
  ["America/Havana", -18_000],
  ["America/Hermosillo", -25_200],
  ["America/Indiana/Indianapolis", -18_000],
  ["America/Indiana/Knox", -21_600],
  ["America/Indiana/Marengo", -18_000],
  ["America/Indiana/Petersburg", -18_000],
  ["America/Indiana/Tell_City", -21_600],
  ["America/Indiana/Vevay", -18_000],
  ["America/Indiana/Vincennes", -18_000],
  ["America/Indiana/Winamac", -18_000],
  ["America/Inuvik", -25_200],
  ["America/Iqaluit", -18_000],
  ["America/Jamaica", -18_000],
  ["America/Juneau", -32_400],
  ["America/Kentucky/Louisville", -18_000],
  ["America/Kentucky/Monticello", -18_000],
  ["America/La_Paz", -14_400],
  ["America/Lima", -18_000],
  ["America/Los_Angeles", -28_800],
  ["America/Maceio", -10_800],
  ["America/Managua", -21_600],
  ["America/Manaus", -14_400],
  ["America/Martinique", -14_400],
  ["America/Matamoros", -21_600],
  ["America/Mazatlan", -25_200],
  ["America/Menominee", -21_600],
  ["America/Merida", -21_600],
  ["America/Metlakatla", -32_400],
  ["America/Mexico_City", -21_600],
  ["America/Miquelon", -10_800],
  ["America/Moncton", -14_400],
  ["America/Monterrey", -21_600],
  ["America/Montevideo", -10_800],
  ["America/New_York", -18_000],
  ["America/Nome", -32_400],
  ["America/Noronha", -7200],
  ["America/North_Dakota/Beulah", -21_600],
  ["America/North_Dakota/Center", -21_600],
  ["America/North_Dakota/New_Salem", -21_600],
  ["America/Nuuk", -7200],
  ["America/Ojinaga", -21_600],
  ["America/Panama", -18_000],
  ["America/Paramaribo", -10_800],
  ["America/Phoenix", -25_200],
  ["America/Port-au-Prince", -18_000],
  ["America/Porto_Velho", -14_400],
  ["America/Puerto_Rico", -14_400],
  ["America/Punta_Arenas", -10_800],
  ["America/Rankin_Inlet", -21_600],
  ["America/Recife", -10_800],
  ["America/Regina", -21_600],
  ["America/Resolute", -21_600],
  ["America/Rio_Branco", -18_000],
  ["America/Santarem", -10_800],
  ["America/Santiago", -14_400],
  ["America/Santo_Domingo", -14_400],
  ["America/Sao_Paulo", -10_800],
  ["America/Scoresbysund", -7200],
  ["America/Sitka", -32_400],
  ["America/St_Johns", -12_600],
  ["America/Swift_Current", -21_600],
  ["America/Tegucigalpa", -21_600],
  ["America/Thule", -14_400],
  ["America/Tijuana", -28_800],
  ["America/Toronto", -18_000],
  ["America/Vancouver", -28_800],
  ["America/Whitehorse", -25_200],
  ["America/Winnipeg", -21_600],
  ["America/Yakutat", -32_400],
  ["Antarctica/Casey", 28_800],
  ["Antarctica/Davis", 25_200],
  ["Antarctica/Macquarie", 36_000],
  ["Antarctica/Mawson", 18_000],
  ["Antarctica/Palmer", -10_800],
  ["Antarctica/Rothera", -10_800],
  ["Antarctica/Troll", 0],
  ["Antarctica/Vostok", 18_000],
  ["Asia/Almaty", 18_000],
  ["Asia/Amman", 10_800],
  ["Asia/Anadyr", 43_200],
  ["Asia/Aqtau", 18_000],
  ["Asia/Aqtobe", 18_000],
  ["Asia/Ashgabat", 18_000],
  ["Asia/Atyrau", 18_000],
  ["Asia/Baghdad", 10_800],
  ["Asia/Baku", 14_400],
  ["Asia/Bangkok", 25_200],
  ["Asia/Barnaul", 25_200],
  ["Asia/Beirut", 7200],
  ["Asia/Bishkek", 21_600],
  ["Asia/Chita", 32_400],
  ["Asia/Colombo", 19_800],
  ["Asia/Damascus", 10_800],
  ["Asia/Dhaka", 21_600],
  ["Asia/Dili", 32_400],
  ["Asia/Dubai", 14_400],
  ["Asia/Dushanbe", 18_000],
  ["Asia/Famagusta", 7200],
  ["Asia/Gaza", 7200],
  ["Asia/Hebron", 7200],
  ["Asia/Ho_Chi_Minh", 25_200],
  ["Asia/Hong_Kong", 28_800],
  ["Asia/Hovd", 25_200],
  ["Asia/Irkutsk", 28_800],
  ["Asia/Jakarta", 25_200],
  ["Asia/Jayapura", 32_400],
  ["Asia/Jerusalem", 7200],
  ["Asia/Kabul", 16_200],
  ["Asia/Kamchatka", 43_200],
  ["Asia/Karachi", 18_000],
  ["Asia/Kathmandu", 20_700],
  ["Asia/Khandyga", 32_400],
  ["Asia/Kolkata", 19_800],
  ["Asia/Krasnoyarsk", 25_200],
  ["Asia/Kuching", 28_800],
  ["Asia/Macau", 28_800],
  ["Asia/Magadan", 39_600],
  ["Asia/Makassar", 28_800],
  ["Asia/Manila", 28_800],
  ["Asia/Nicosia", 7200],
  ["Asia/Novokuznetsk", 25_200],
  ["Asia/Novosibirsk", 25_200],
  ["Asia/Omsk", 21_600],
  ["Asia/Oral", 18_000],
  ["Asia/Pontianak", 25_200],
  ["Asia/Pyongyang", 32_400],
  ["Asia/Qatar", 10_800],
  ["Asia/Qostanay", 18_000],
  ["Asia/Qyzylorda", 18_000],
  ["Asia/Riyadh", 10_800],
  ["Asia/Sakhalin", 39_600],
  ["Asia/Samarkand", 18_000],
  ["Asia/Seoul", 32_400],
  ["Asia/Shanghai", 28_800],
  ["Asia/Singapore", 28_800],
  ["Asia/Srednekolymsk", 39_600],
  ["Asia/Taipei", 28_800],
  ["Asia/Tashkent", 18_000],
  ["Asia/Tbilisi", 14_400],
  ["Asia/Tehran", 12_600],
  ["Asia/Thimphu", 21_600],
  ["Asia/Tokyo", 32_400],
  ["Asia/Tomsk", 25_200],
  ["Asia/Ulaanbaatar", 28_800],
  ["Asia/Urumqi", 21_600],
  ["Asia/Ust-Nera", 36_000],
  ["Asia/Vladivostok", 36_000],
  ["Asia/Yakutsk", 32_400],
  ["Asia/Yangon", 23_400],
  ["Asia/Yekaterinburg", 18_000],
  ["Asia/Yerevan", 14_400],
  ["Atlantic/Azores", -3600],
  ["Atlantic/Bermuda", -14_400],
  ["Atlantic/Canary", 0],
  ["Atlantic/Cape_Verde", -3600],
  ["Atlantic/Faroe", 0],
  ["Atlantic/Madeira", 0],
  ["Atlantic/South_Georgia", -7200],
  ["Atlantic/Stanley", -10_800],
  ["Australia/Adelaide", 34_200],
  ["Australia/Brisbane", 36_000],
  ["Australia/Broken_Hill", 34_200],
  ["Australia/Darwin", 34_200],
  ["Australia/Eucla", 31_500],
  ["Australia/Hobart", 36_000],
  ["Australia/Lindeman", 36_000],
  ["Australia/Lord_Howe", 37_800],
  ["Australia/Melbourne", 36_000],
  ["Australia/Perth", 28_800],
  ["Australia/Sydney", 36_000],
  ["Etc/GMT", 0],
  ["Etc/GMT+1", -3600],
  ["Etc/GMT+10", -36_000],
  ["Etc/GMT+11", -39_600],
  ["Etc/GMT+12", -43_200],
  ["Etc/GMT+2", -7200],
  ["Etc/GMT+3", -10_800],
  ["Etc/GMT+4", -14_400],
  ["Etc/GMT+5", -18_000],
  ["Etc/GMT+6", -21_600],
  ["Etc/GMT+7", -25_200],
  ["Etc/GMT+8", -28_800],
  ["Etc/GMT+9", -32_400],
  ["Etc/GMT-1", 3600],
  ["Etc/GMT-10", 36_000],
  ["Etc/GMT-11", 39_600],
  ["Etc/GMT-12", 43_200],
  ["Etc/GMT-13", 46_800],
  ["Etc/GMT-14", 50_400],
  ["Etc/GMT-2", 7200],
  ["Etc/GMT-3", 10_800],
  ["Etc/GMT-4", 14_400],
  ["Etc/GMT-5", 18_000],
  ["Etc/GMT-6", 21_600],
  ["Etc/GMT-7", 25_200],
  ["Etc/GMT-8", 28_800],
  ["Etc/GMT-9", 32_400],
  ["Etc/UTC", 0],
  ["Europe/Andorra", 3600],
  ["Europe/Astrakhan", 14_400],
  ["Europe/Athens", 7200],
  ["Europe/Belgrade", 3600],
  ["Europe/Berlin", 3600],
  ["Europe/Brussels", 3600],
  ["Europe/Bucharest", 7200],
  ["Europe/Budapest", 3600],
  ["Europe/Chisinau", 7200],
  ["Europe/Dublin", 3600],
  ["Europe/Gibraltar", 3600],
  ["Europe/Helsinki", 7200],
  ["Europe/Istanbul", 10_800],
  ["Europe/Kaliningrad", 7200],
  ["Europe/Kirov", 10_800],
  ["Europe/Kyiv", 7200],
  ["Europe/Lisbon", 0],
  ["Europe/London", 0],
  ["Europe/Madrid", 3600],
  ["Europe/Malta", 3600],
  ["Europe/Minsk", 10_800],
  ["Europe/Moscow", 10_800],
  ["Europe/Paris", 3600],
  ["Europe/Prague", 3600],
  ["Europe/Riga", 7200],
  ["Europe/Rome", 3600],
  ["Europe/Samara", 14_400],
  ["Europe/Saratov", 14_400],
  ["Europe/Simferopol", 10_800],
  ["Europe/Sofia", 7200],
  ["Europe/Tallinn", 7200],
  ["Europe/Tirane", 3600],
  ["Europe/Ulyanovsk", 14_400],
  ["Europe/Vienna", 3600],
  ["Europe/Vilnius", 7200],
  ["Europe/Volgograd", 10_800],
  ["Europe/Warsaw", 3600],
  ["Europe/Zurich", 3600],
  ["Indian/Chagos", 21_600],
  ["Indian/Maldives", 18_000],
  ["Indian/Mauritius", 14_400],
  ["Pacific/Apia", 46_800],
  ["Pacific/Auckland", 43_200],
  ["Pacific/Bougainville", 39_600],
  ["Pacific/Chatham", 45_900],
  ["Pacific/Easter", -21_600],
  ["Pacific/Efate", 39_600],
  ["Pacific/Fakaofo", 46_800],
  ["Pacific/Fiji", 43_200],
  ["Pacific/Galapagos", -21_600],
  ["Pacific/Gambier", -32_400],
  ["Pacific/Guadalcanal", 39_600],
  ["Pacific/Guam", 36_000],
  ["Pacific/Honolulu", -36_000],
  ["Pacific/Kanton", 46_800],
  ["Pacific/Kiritimati", 50_400],
  ["Pacific/Kosrae", 39_600],
  ["Pacific/Kwajalein", 43_200],
  ["Pacific/Marquesas", -34_200],
  ["Pacific/Nauru", 43_200],
  ["Pacific/Niue", -39_600],
  ["Pacific/Norfolk", 39_600],
  ["Pacific/Noumea", 39_600],
  ["Pacific/Pago_Pago", -39_600],
  ["Pacific/Palau", 32_400],
  ["Pacific/Pitcairn", -28_800],
  ["Pacific/Port_Moresby", 36_000],
  ["Pacific/Rarotonga", -36_000],
  ["Pacific/Tahiti", -36_000],
  ["Pacific/Tarawa", 43_200],
  ["Pacific/Tongatapu", 46_800],
]);
