/// <reference types="./organization.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $tab_bar from "../view/components/tab_bar.mjs";
import * as $collaborators from "../view/organization/collaborators.mjs";
import * as $integrations from "../view/organization/integrations.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let is_admin = $list.contains(data.permissions, "admin:organization");
  return $bool.guard(
    !is_admin,
    $el.none(),
    () => {
      let $1 = data.route;
      if (!($1 instanceof $route.Organization)) {
        throw makeError(
          "let_assert",
          "view/organization",
          17,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $1 }
        )
      }
      let route = $1[0];
      return $el.fragment(
        toList([
          $tab_bar.tab_bar(
            toList([
              $tab_bar.tab(
                new $route.Organization(new $route.Collaborators()),
                toList([$h.text("Collaborators")]),
              ),
              $tab_bar.tab(
                new $route.Organization(new $route.Integrations()),
                toList([$h.text("Integrations")]),
              ),
            ]),
          ),
          (() => {
            if (route instanceof $route.Collaborators) {
              return $collaborators.view(model);
            } else {
              return $el.map(
                $integrations.view(model),
                (var0) => { return new $msg.OnConnectors(var0); },
              );
            }
          })(),
        ]),
      );
    },
  );
}
