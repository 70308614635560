/// <reference types="./translated_text.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $data from "../../data/model/data.mjs";
import { Ok, toList } from "../../gleam.mjs";

export function id_builder(id, l, k) {
  let language = $translate.decode_language_iso639_1(l);
  let kind = $translate.decode_translation_target_small(k);
  let _pipe = toList([language, kind, id]);
  return $string.join(_pipe, "_");
}

export function translate_or_default(id, default$, data) {
  return $bool.guard(
    $data.is_translations_hidden(data),
    default$,
    () => {
      let _pipe = data.translations;
      let _pipe$1 = $dict.get(_pipe, id);
      let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
      return $result.unwrap(_pipe$2, default$);
    },
  );
}

export function translated_question(question, model, language) {
  let _pipe = id_builder(question.id, language, new $translate.Question());
  let _pipe$1 = translate_or_default(_pipe, question.content, model);
  return $h.text(_pipe$1);
}

export function translated_answer(question, model, language) {
  let _pipe = id_builder(question.id, language, new $translate.Answer());
  let _pipe$1 = translate_or_default(
    _pipe,
    $question.get_answer_text(question.answer),
    model,
  );
  return $h.text(_pipe$1);
}

export function translated_data_point(point, model, language) {
  let _pipe = id_builder(point.id, language, new $translate.DataPoint());
  return translate_or_default(
    _pipe,
    (() => {
      let _pipe$1 = point.display.as_html;
      return $option.unwrap(_pipe$1, point.text);
    })(),
    model,
  );
}
