/// <reference types="./dom.d.mts" />
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ffi from "../../frontend/ffi.mjs";

export function subscribe_dom_click(on_unsubscriber, on_click) {
  return $effect.from(
    (dispatch) => {
      let unsubscriber = $ffi.subscribe_dom_click(
        () => { return dispatch(on_click); },
      );
      return dispatch(on_unsubscriber(unsubscriber));
    },
  );
}
