/// <reference types="./modal.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $shadows from "../../ds/shadows.mjs";
import * as $display from "../../ds/typography/display.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function modal(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.position("absolute"),
      $s.z_index(100_000_000),
      $s.top(px(0)),
      $s.bottom(px(0)),
      $s.right(px(0)),
      $s.left(px(0)),
      $s.property("backdrop-filter", "blur(4px)"),
      $s.background("rgba(255, 255, 255, 0.1)"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function body(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.max_width_("90vw"),
      $s.max_height_("90vh"),
      $s.background($colors.light.white),
      $s.padding(px(48)),
      $s.border_radius(px(10)),
      $s.width(px(450)),
      $s.box_shadow($shadows.m()),
      $s.gap(px(48)),
      $s.border("1px solid " + $colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function title(attrs, children) {
  return $h.div($s.class$(toList([$s.compose($display.md())])), attrs, children);
}

export function explanations(attrs, children) {
  return $h.div(
    $s.class$(toList([$s.compose($text.md($text.regular))])),
    attrs,
    children,
  );
}

export function actions(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.display("flex"),
      $s.gap(px(10)),
      $s.justify_content("end"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
