/// <reference types="./ingestion.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $spreadsheet_selector from "../../view/components/spreadsheet_selector.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let model$1 = $[0];
  let $1 = model$1.content_library.ingestion_loading;
  if ($1) {
    return $el.text("Loading, please wait…");
  } else {
    let _pipe = model$1.content_library.ingestion_spreadsheet;
    let _pipe$1 = $spreadsheet_selector.select_columns(
      _pipe,
      "Import questions",
      true,
    );
    let _pipe$2 = $el.map(
      _pipe$1,
      (var0) => {
        return new $msg.ContentLibraryHandledSpreadsheetSelector(var0);
      },
    );
    return $el.map(_pipe$2, (var0) => { return new $msg.ContentLibrary(var0); });
  }
}
