/// <reference types="./label.d.mts" />
import * as $status from "../../../common/data/status.mjs";
import * as $label from "../../../ds/ds/components/label.mjs";

export function from_status(status) {
  let content = $status.to_string(status);
  let status$1 = (() => {
    if (status instanceof $status.Won) {
      return new $label.Success();
    } else if (status instanceof $status.Cancelled) {
      return new $label.Error();
    } else if (status instanceof $status.InProgress) {
      return new $label.Current();
    } else if (status instanceof $status.Lost) {
      return new $label.Error();
    } else {
      return new $label.Neutral();
    }
  })();
  return $label.label(status$1, content);
}
