/// <reference types="./view.d.mts" />
import * as $dropdown from "../../../ds/ds/components/dropdown.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dyn from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $editor from "../../elements/editor/editor.mjs";
import { Connected } from "../../elements/editor/editor.mjs";
import * as $model from "../../elements/editor/editor/model.mjs";
import { Model } from "../../elements/editor/editor/model.mjs";
import * as $s from "../../elements/editor/styles.mjs";
import * as $ffi from "../../frontend/ffi.mjs";
import {
  Ok,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  makeError,
} from "../../gleam.mjs";

class EditorEmitted extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserClickedBold extends $CustomType {}

class UserClickedItalic extends $CustomType {}

class UserClickedUnderline extends $CustomType {}

class UserSelectedRatio extends $CustomType {
  constructor(ratio) {
    super();
    this.ratio = ratio;
  }
}

class Bold extends $CustomType {}

class Italic extends $CustomType {}

class Underline extends $CustomType {}

function update_text_style(model, editor, style) {
  if (style instanceof Bold) {
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          model.bold,
          _record.italic,
          _record.underline,
          _record.ratio,
        );
      })(),
      $editor.bold(editor),
    ];
  } else if (style instanceof Italic) {
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          model.italic,
          _record.underline,
          _record.ratio,
        );
      })(),
      $editor.italic(editor),
    ];
  } else {
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          model.underline,
          _record.ratio,
        );
      })(),
      $editor.underline(editor),
    ];
  }
}

function update_editor_required(model, msg) {
  return $model.require_editor(
    model,
    (editor) => {
      if (msg instanceof UserClickedBold) {
        return update_text_style(model, editor, new Bold());
      } else if (msg instanceof UserClickedItalic) {
        return update_text_style(model, editor, new Italic());
      } else if (msg instanceof UserClickedUnderline) {
        return update_text_style(model, editor, new Underline());
      } else if (msg instanceof EditorEmitted) {
        let _pipe = model;
        let _pipe$1 = $model.update_state(_pipe);
        return $pair.new$(_pipe$1, $effect.none());
      } else {
        throw makeError(
          "panic",
          "elements/editor/view",
          60,
          "",
          "[editor/view] Should be handled outside",
          {}
        )
      }
    },
  );
}

function update(model, msg) {
  if (msg instanceof UserSelectedRatio) {
    let ratio = msg.ratio;
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          _record.underline,
          ratio,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof EditorEmitted && msg[0] instanceof Connected) {
    let editor = msg[0][0];
    let _pipe = model;
    let _pipe$1 = $model.set_editor(_pipe, editor);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    return update_editor_required(model, msg);
  }
}

function ratio_selector(model) {
  let state = $dropdown.selected(new $option.Some(model.ratio));
  let on_select = $dropdown.on_selected(
    (var0) => { return new UserSelectedRatio(var0); },
  );
  let style = $dropdown.input_class(
    $sketch.class$(toList([$sketch.width($size.ch(9))])),
  );
  return $dropdown.dropdown(
    toList([state, $dropdown.show_arrow(), on_select, style]),
    toList([
      $dropdown.choice("75 %", 0.75),
      $dropdown.choice("100 %", 1.0),
      $dropdown.choice("125 %", 1.25),
      $dropdown.choice("150 %", 1.5),
    ]),
  );
}

function formatting_ribbon(model) {
  let on_bold = $event.on_click(new UserClickedBold());
  let on_italic = $event.on_click(new UserClickedItalic());
  let on_underline = $event.on_click(new UserClickedUnderline());
  return $s.format_wrapper(
    toList([]),
    toList([
      $s.format_wrapper_spacer(
        toList([]),
        toList([
          $s.format_row(
            toList([]),
            toList([
              $dropdown.dropdown(
                toList([
                  $dropdown.placeholder("Insert"),
                  $dropdown.icon($icons.add()),
                ]),
                toList([
                  $dropdown.choice("Title", ""),
                  $dropdown.choice("Paragraph", ""),
                ]),
              ),
              $s.action_button(toList([on_bold]), $icons.shining()),
              ratio_selector(model),
              $s.format_button(model.bold, toList([on_bold]), $icons.bold()),
              $s.format_button(
                model.italic,
                toList([on_italic]),
                $icons.italic(),
              ),
              $s.format_button(
                model.underline,
                toList([on_underline]),
                $icons.underline(),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function generate_editor_handlers() {
  let _pipe = $editor.events;
  let _pipe$1 = $list.map(
    _pipe,
    (e) => {
      return $event.on(e[0], (_) => { return new Ok(new EditorEmitted(e[1])); });
    },
  );
  return $list.prepend(
    _pipe$1,
    (() => {
      let _pipe$2 = (dyn) => {
        return new EditorEmitted(new Connected($ffi.coerce(dyn)));
      };
      let _pipe$3 = $dyn.decode1(
        _pipe$2,
        $dyn.field("detail", $dyn.field("editor", $dyn.dynamic)),
      );
      return ((_capture) => { return $event.on("connected", _capture); })(
        _pipe$3,
      );
    })(),
  );
}

function view_(model) {
  return $magic.render(
    toList([$magic.node()]),
    () => {
      return $s.container(
        toList([]),
        toList([
          formatting_ribbon(model),
          $s.format_wrapper(
            toList([]),
            toList([
              $s.format_wrapper_spacer(
                toList([]),
                toList([
                  $s.page(
                    model.ratio,
                    toList([]),
                    toList([
                      (() => {
                        let _pipe = listPrepend(
                          $a.attribute("ratio", $float.to_string(model.ratio)),
                          listPrepend(
                            $a.attribute("border-color", "#ccc"),
                            listPrepend(
                              $a.attribute("header-color", "#eee"),
                              listPrepend(
                                $a.attribute("selected-cell-color", "#ccf2"),
                                listPrepend(
                                  $a.attribute("editable", "false"),
                                  generate_editor_handlers(),
                                ),
                              ),
                            ),
                          ),
                        );
                        return ((_capture) => {
                          return $el.element(
                            "tiptap-editor",
                            _capture,
                            toList([]),
                          );
                        })(_pipe);
                      })(),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}

const tag_name = "steerlab-editor";

export function register() {
  let init = (_) => { return [$model.init(), $effect.none()]; };
  let on_attribute_changes = $dict.new$();
  let _pipe = $lustre.component(init, update, view_, on_attribute_changes);
  return $lustre.register(_pipe, tag_name);
}

export function view() {
  return $el.element(tag_name, toList([]), toList([]));
}
