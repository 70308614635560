/// <reference types="./overview.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $computed from "../../data/model/computed.mjs";
import * as $route from "../../data/route.mjs";
import { toList, makeError } from "../../gleam.mjs";
import * as $projects_table from "../../view/components/projects_table.mjs";
import * as $tab_bar from "../../view/components/tab_bar.mjs";

function to() {
  let _pipe = new $route.AddProposal(new $option.None(), false);
  let _pipe$1 = new $route.Projects(_pipe);
  let _pipe$2 = $route.to_uri(_pipe$1);
  let _pipe$3 = $uri.to_string(_pipe$2);
  return $a.href(_pipe$3);
}

function select_projects(route, no_ongoing, no_previous, computed) {
  if (route instanceof $route.OnGoing && (!no_ongoing)) {
    return computed.projects.ongoing;
  } else if (route instanceof $route.Previous && (!no_previous)) {
    return computed.projects.finished;
  } else {
    return toList([]);
  }
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let $1 = data.route;
  if (!($1 instanceof $route.Projects)) {
    throw makeError(
      "let_assert",
      "view/projects/overview",
      20,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  let no_ongoing = computed.projects_count.ongoing === 0;
  let no_previous = computed.projects_count.finished === 0;
  let ongoing_count = $int.to_string(computed.projects_count.ongoing);
  let finished_count = $int.to_string(computed.projects_count.finished);
  return $el.fragment(
    toList([
      $tab_bar.tab_bar(
        toList([
          (() => {
            let _pipe = new $route.Projects(new $route.OnGoing());
            let _pipe$1 = $tab_bar.tab(
              _pipe,
              toList([$el.text(("Ongoing (" + ongoing_count) + ")")]),
            );
            return $tab_bar.disabled(_pipe$1, no_ongoing);
          })(),
          (() => {
            let _pipe = new $route.Projects(new $route.Previous());
            let _pipe$1 = $tab_bar.tab(
              _pipe,
              toList([$el.text(("Previous (" + finished_count) + ")")]),
            );
            return $tab_bar.disabled(_pipe$1, no_previous);
          })(),
          $tab_bar.element(
            $layout.row(
              toList([$layout.flex("1"), $layout.justify("end")]),
              toList([]),
              toList([
                $button.primary_link(
                  toList([to()]),
                  toList([
                    $button.icon($icons.add()),
                    $el.text("Add a proposal"),
                  ]),
                ),
              ]),
            ),
          ),
        ]),
      ),
      $projects_table.table(
        data.loading.proposals,
        select_projects(route, no_ongoing, no_previous, computed),
        data.opened_projects,
        data.users,
        new $projects_table.ShowTrashButton(),
      ),
    ]),
  );
}
