/// <reference types="./ai_response.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $duration from "../../../birl/birl/duration.mjs";
import * as $bright from "../../../bright/bright.mjs";
import * as $copilot_question from "../../../common/data/copilot_question.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $tooltip from "../../../ds/ds/components/tooltip.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList, prepend as listPrepend, makeError, isEqual } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $s from "../../view/styles/copilot.mjs";
import * as $proposals from "../../view/styles/proposals.mjs";

function is_before(date, days) {
  let _pipe = $birl.difference(date, $birl.utc_now());
  let _pipe$1 = $duration.blur_to(_pipe, new $duration.Day());
  return ((a) => { return a < days; })(_pipe$1);
}

export function history(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let grouped_by_order = (() => {
    let _pipe = data.copilot_threads;
    let _pipe$1 = $list.filter(
      _pipe,
      (thread) => { return !$list.is_empty(thread[1]); },
    );
    let _pipe$2 = $list.group(
      _pipe$1,
      (thread) => {
        let $1 = $list.first(thread[1]);
        if (!$1.isOk()) {
          throw makeError(
            "let_assert",
            "view/copilot/ai_response",
            38,
            "",
            "Pattern match failed, no pattern matched the value.",
            { value: $1 }
          )
        }
        let question = $1[0];
        let date = question.created_at;
        return $bool.guard(
          is_before(date, 1),
          "Today",
          () => {
            return $bool.guard(
              is_before(date, 8),
              "Previous 7 days",
              () => {
                return $bool.guard(
                  is_before(date, 31),
                  "Previous 30 days",
                  () => {
                    let $2 = is_before(date, 365);
                    if ($2) {
                      return $birl.string_month(date);
                    } else {
                      return $int.to_string($birl.get_day(date).year);
                    }
                  },
                );
              },
            );
          },
        );
      },
    );
    return $dict.map_values(
      _pipe$2,
      (_, threads) => {
        return $list.sort(
          threads,
          (a, b) => {
            let $1 = $list.first(a[1]);
            if (!$1.isOk()) {
              throw makeError(
                "let_assert",
                "view/copilot/ai_response",
                50,
                "",
                "Pattern match failed, no pattern matched the value.",
                { value: $1 }
              )
            }
            let a$1 = $1[0];
            let $2 = $list.first(b[1]);
            if (!$2.isOk()) {
              throw makeError(
                "let_assert",
                "view/copilot/ai_response",
                51,
                "",
                "Pattern match failed, no pattern matched the value.",
                { value: $2 }
              )
            }
            let b$1 = $2[0];
            return $birl.compare(b$1.created_at, a$1.created_at);
          },
        );
      },
    );
  })();
  let to_display = $dict.to_list(grouped_by_order);
  return $s.history_column(
    toList([]),
    $bool.guard(
      $list.is_empty(to_display),
      toList([$s.history_day_title(toList([]), toList([$h.text("No history")]))]),
      () => {
        return $list.map(
          to_display,
          (_use0) => {
            let when = _use0[0];
            let threads = _use0[1];
            return $el.fragment(
              listPrepend(
                $s.history_day_title(toList([]), toList([$h.text(when)])),
                $list.map(
                  threads,
                  (thread) => {
                    let thread_id = thread[0];
                    let questions = thread[1];
                    let $1 = $list.first(questions);
                    if (!$1.isOk()) {
                      throw makeError(
                        "let_assert",
                        "view/copilot/ai_response",
                        65,
                        "",
                        "Pattern match failed, no pattern matched the value.",
                        { value: $1 }
                      )
                    }
                    let question = $1[0];
                    return $s.history_question(
                      toList([$a.href("/co-pilot/" + thread_id)]),
                      toList([$h.text(question.content)]),
                    );
                  },
                ),
              ),
            );
          },
        );
      },
    ),
  );
}

function get_questions_related(data, question_id) {
  let _pipe = data;
  let _pipe$1 = $dict.get(_pipe, question_id);
  return $result.unwrap(_pipe$1, toList([]));
}

function view_sources(model, question) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let qid = question.id;
  let data_sources = get_questions_related(data.questions.data_sources, qid);
  let data_points = get_questions_related(data.questions.data_points, qid);
  let data_points_id = $list.take(question.data_points_id, 3);
  return $layout.column(
    toList([$layout.gap(16)]),
    toList([]),
    toList([
      $s.response_sources_title(toList([]), toList([$h.text("Sources")])),
      $el.fragment(
        $result.values(
          $list.index_map(
            data_points_id,
            (_use0, index) => {
              let id = _use0[0];
              return $result.try$(
                $list.find(data_points, (dp) => { return dp.id === id; }),
                (point) => {
                  return $result.map(
                    $list.find(
                      data_sources,
                      (ds) => { return ds.id === point.source_id; },
                    ),
                    (source) => {
                      let icon = $common.select_icon(source.display.mime_type);
                      return $tooltip.tooltip(
                        toList([
                          $a.style(
                            toList([["z-index", $int.to_string(100 - index)]]),
                          ),
                        ]),
                        $s.response_source(
                          toList([]),
                          toList([
                            $common.small_icon(icon),
                            (() => {
                              let $1 = source.display.mime_type;
                              if ($1 instanceof $mime_types.Qna) {
                                return $h.text("Question bank");
                              } else {
                                return $h.text(source.display.name);
                              }
                            })(),
                          ]),
                        ),
                        $proposals.question_source_tooltip(
                          toList([]),
                          toList([
                            $h.div(
                              toList([
                                $a.attribute(
                                  "dangerous-unescaped-html",
                                  $option.unwrap(
                                    point.display.as_html,
                                    point.text,
                                  ),
                                ),
                              ]),
                              toList([]),
                            ),
                          ]),
                        ),
                      );
                    },
                  );
                },
              );
            },
          ),
        ),
      ),
    ]),
  );
}

export function view(model, id) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $s.card_wrapper(
    toList([]),
    toList([
      history(model),
      $layout.column(
        toList([$layout.flex("1"), $layout.overflow("auto"), $layout.gap(32)]),
        toList([]),
        toList([
          (() => {
            let $1 = $list.key_find(data.copilot_threads, id);
            if (!$1.isOk()) {
              return $layout.column(
                toList([$layout.gap(32), $layout.flex("1")]),
                toList([]),
                toList([]),
              );
            } else {
              let questions = $1[0];
              return $layout.column(
                toList([$layout.gap(32), $layout.flex("1")]),
                toList([]),
                $list.map(
                  questions,
                  (question) => {
                    let answer = (() => {
                      let _pipe = question.answer.long;
                      let _pipe$1 = $option.or(_pipe, question.answer.short);
                      return $option.or(_pipe$1, question.answer.yes_no);
                    })();
                    return $el.fragment(
                      toList([
                        $s.question_bubble(
                          toList([]),
                          toList([$h.text(question.content)]),
                        ),
                        (() => {
                          let $2 = question.loading && $option.is_none(answer);
                          if ($2) {
                            return $s.loading_indicator();
                          } else {
                            return $el.fragment(
                              toList([
                                (() => {
                                  let $3 = question.display.score;
                                  if ($3 instanceof $option.Some &&
                                  $3[0] === 0.0) {
                                    return $s.error_response_bubble(
                                      toList([]),
                                      toList([
                                        $h.text(
                                          "Steerlab is unable to get an answer for you.\n",
                                        ),
                                        $h.text("Please, ask another question."),
                                      ]),
                                    );
                                  } else if (answer instanceof $option.None) {
                                    return $s.error_response_bubble(
                                      toList([]),
                                      toList([
                                        $h.text(
                                          "Steerlab is unable to get an answer for you.\n",
                                        ),
                                        $h.text("Please, ask another question."),
                                      ]),
                                    );
                                  } else {
                                    let answer$1 = answer[0];
                                    return $s.response_bubble(
                                      toList([]),
                                      toList([$h.text(answer$1)]),
                                    );
                                  }
                                })(),
                                (() => {
                                  let $3 = $list.is_empty(
                                    question.data_points_id,
                                  ) || (isEqual(
                                    question.display.score,
                                    new $option.Some(0.0)
                                  ));
                                  if ($3) {
                                    return $el.none();
                                  } else {
                                    return view_sources(model, question);
                                  }
                                })(),
                              ]),
                            );
                          }
                        })(),
                      ]),
                    );
                  },
                ),
              );
            }
          })(),
          $h.form(
            toList([$e.on_submit(new $msg.OnCopilotSubmit())]),
            toList([
              $input.input(
                toList([
                  $input.multiline(),
                  $input.placeholder("Ask steerlab a question…"),
                  $input.icon($icons.paper_plane()),
                  $input.on_input(
                    (var0) => { return new $msg.OnCopilotInput(var0); },
                  ),
                  $input.on_submit(new $msg.OnCopilotSubmit()),
                  $input.value(data.copilot_input.value),
                ]),
              ),
            ]),
          ),
          $s.ai_search_disclaimer(
            toList([]),
            toList([$el.text("Make sure to verify AI generated answers.")]),
          ),
        ]),
      ),
    ]),
  );
}
