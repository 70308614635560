export type Element = HTMLElement | Text | SVGElement
export type Attribute = { name: string; value: string }

function el(tagName: string) {
  return function (attributes: Attribute[], children: Element[]) {
    const node = document.createElement(tagName)
    attributes.forEach(({ name, value }) => node.setAttribute(name, value))
    node.append(...children)
    return node
  }
}

export function element(
  tagName: string,
  attributes: Attribute[],
  children: Element[]
) {
  return el(tagName)(attributes, children)
}

export const div = el('div')
export const button = el('button')
export const span = el('span')
export const table = el('table')
export const tbody = el('tbody')
export const thead = el('thead')
export const tr = el('tr')
export const td = el('td')
export const th = el('th')

export function text(content: string) {
  return document.createTextNode(content)
}

export function class_(name: string) {
  return { name: 'class', value: name }
}

export function id(name: string) {
  return { name: 'id', value: name }
}

export function style(styles: { [key: string]: string | number }) {
  const value = Object.entries(styles)
    .map(([key, value]) => {
      key = uncamelize(key)
      if (typeof value === 'number') value = `${value}px`
      return `${key}: ${value}`
    })
    .join(';')
  return { name: 'style', value }
}

function uncamelize(text: string, separator = '-') {
  return text
    .replace(/[A-Z]/g, letter => separator + letter.toLowerCase())
    .replace('/^' + separator + '/', '')
}
