/// <reference types="./shining.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function shining() {
  let content = "<path d=\"M11.625 4.37524L9.75 8.87524L5.25 10.7502L9.75 12.6252L11.625 17.1252L13.5 12.6252L18 10.7502L13.5 8.87524L11.625 4.37524ZM3.09375 6.53149L4.125 9.62524L5.15625 6.53149L8.25 5.50024L5.15625 4.46899L4.125 1.37524L3.09375 4.46899L0 5.50024L3.09375 6.53149ZM4.78125 14.594L4.125 12.6252L3.46875 14.594L1.5 15.2502L3.46875 15.9065L4.125 17.8752L4.78125 15.9065L6.75 15.2502L4.78125 14.594Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 18 19"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
