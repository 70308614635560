/// <reference types="./tab_bar.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function tab_bar(attrs, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.margin_top(px(-16))]));
  return $h.nav(_pipe, attrs, children);
}

export function tab(active, attrs, children) {
  let border = (() => {
    if (active) {
      return $colors.light.onyx;
    } else {
      return "transparent";
    }
  })();
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.padding_("10px 24px"),
      $s.border_bottom("2px solid " + border),
      $s.text_decoration("none"),
      $s.transition("all .3s"),
      $s.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_800;
          }
        })(),
      ),
      $s.pseudo_selector(
        "[disabled='True']",
        toList([
          $s.color($colors.light.grey_800),
          $s.border_bottom("none"),
          $s.pointer_events("none"),
        ]),
      ),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}
