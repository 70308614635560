/// <reference types="./copilot.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $ai_response from "../view/copilot/ai_response.mjs";
import * as $ai_search from "../view/copilot/ai_search.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.CoPilot)) {
    throw makeError(
      "let_assert",
      "view/copilot",
      10,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  return $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.AiSearch) {
          return $ai_search.view(model);
        } else {
          let id = route[0];
          return $ai_response.view(model, id);
        }
      })(),
    ]),
  );
}
