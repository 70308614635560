export const mock = `[
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 0,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": " ", "style": { "font-size": "2.18em" } }],
    "alignment": "center",
    "metadata": {},
    "index": 1,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 2,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 3,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 4,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Request for Proposals", "style": { "font-size": "2.55em" } }],
    "alignment": "center",
    "metadata": {},
    "index": 5,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Mobile Workforce Management Solution ", "style": { "font-size": "2.55em" } }],
    "alignment": "center",
    "metadata": {},
    "index": 6,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 7,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 8,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 9,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 10,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 11,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 12,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 13,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 14,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 15,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 16,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 17,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 18,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 19,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 20,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 21,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Release Date: 5.21.24", "style": { "font-size": "1.27em" } }],
    "alignment": "center",
    "metadata": {},
    "index": 22,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 23,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 24,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 25,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 26 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "\nRFP GENERAL INFORMATION", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 27,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 28,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Revision History", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 29,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Version", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "Date", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Author(s)", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "Summary of Changes", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.0", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "06/04/2024", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "J. Tilley", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "Initial version", "style": { "font-size": "1.09em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 30
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Executive Summary", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 31,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "This Request for Proposal (RFP) seeks to select a vendor solution for a mobile workforce management solution (MWMS) for Jackson Energy Authority (JEA). JEA is seeking a complete MWMS solution that interfaces with our existing CIS system and Telecom BSS/OSS solution.  ",
        "style": { "font-size": "1.09em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 32,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA serves the City of Jackson, Tennessee and surrounding areas with electric, gas, water, wastewater, propane, cable, internet and telephone services. Internet and telephone are delivered over JEA’s high-capacity fiber optic broadband system through strategic partnerships with outside firms. JEA is a publicly owned utility operating as a governmental authority. We serve approximately 45,000 unique customers at about 51,300 individual locations. Our mission is to provide exceptional utility services that create value for our customers and our community. Our vision as a utility is to go beyond customer service to improve quality of life for the communities we serve.",
        "style": { "font-size": "1.09em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 33,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Purpose", "style": { "font-size": "1.09em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 34,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The objective of this RFP is to evaluate and select the vendors/products that best meet the functional and business requirements of JEA as stated in the Requirements Section of this RFP. It is JEA’s intent to license the selected application(s) and to contract with the selected vendor for both implementation services and typical post-implementation support & maintenance services under this initial contract. JEA is looking for a total MDMS solution that meets the requirements stated herein. JEA will provide its internal network, workstations, and server infrastructure as needed and will manage its internal network security with expert guidance from the vendor. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 35,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "As stated above, JEA expects proposals for a complete solution, which would include both application software and the services to implement the software at JEA. As such, JEA expects to receive proposals where one entity is proposing both software and the services required to implement the software solution, OR joint proposals from multiple companies where the software and services components are delivered by multiple companies.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 36,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA is not requesting proposals for anything other than a complete solution. Proposals that only represent components of an overall solution, whether they are software or services, will not be considered.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 37,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 38 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Vendors should note that, in the cases where multiple vendors are jointly proposing a solution, JEA will inquire about the working history between the vendors and will encourage partnerships where the parties have a well-referenced history together.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 39,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA expects that some joint proposals will require JEA to enter into separate contractual relationships with the software and services vendors. In addition, JEA expects other proposals where the software and the services would be provided by a software company and proposals where the services vendor is a reseller of the software. JEA has experience with each of these scenarios.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 40,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Background", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 41,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Since 2014, JEA has utilized the Service Optimization suite from Clicksoftware.  This application is used across all divisions of the utility to schedule and dispatch service orders / work orders originating from the CIS and BSS (Telecom) systems.  The current implementation is an on-premise deployment. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 42,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "If awarded, the contract shall commence on the date it is signed by all required parties.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 43,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Project Driver(s)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 44,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Key drivers for this project include:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 45,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Improved ROI through efficiency gains within the respective workgroups ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 46,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Technical obsolescence of the current platform", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 47,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "RFP Process Information", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 48,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Anticipated Calendar of Events", "style": { "font-weight": "bold", "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 49,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 50,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Event Description", "style": { "font-weight": "bold", "font-size": "1.27em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "Target Date(s)", "style": { "font-weight": "bold", "font-size": "1.27em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [
              {
                "text": "Release of RFP to Qualified Vendors",
                "style": { "font-weight": "bold", "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "6.10.24", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Deadline for Vendor Questions", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "6.24.24", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Response to Vendor Questions", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "7.01.24", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Proposals Due", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "7.15.24", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "On-site / Remote Presentations", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "TBD", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Best and Final Offer (BAFO) due", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "TBD", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Contract Negotiations", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "TBD", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Board approval", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "TBD", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Projected Project Kick-off", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 2,
            "children": [{ "text": "TBD", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 51
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 52,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 53,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Solution Providers’ Written RFP Clarification Requests Due",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 54,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 55,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "By the end of business Central Time (5:00 p.m.) on the date noted herein all questions and clarifications regarding the RFP and the process are due via email to jtilley@jaxenergy.com. The JEA team will review and research each written request. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 56,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 57,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 58 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Proposal Addendum Issues",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 59,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 60,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Any changes or corrections to or clarification of this RFP, in addition to a copy of all written clarification requests and the associated answers, will be provided to all who have received the RFP. No changes, corrections or explanations that are presented verbally will be binding.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 61,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 62,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Proposals Due",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 63,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 64,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "All Proposals are due in the format specified herein by 5:00 p.m. Central Time on the date specified for this event in the Calendar of Events table herein. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 65,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 66,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Oral Presentation and Demonstration Participants ",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 67,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 68,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will evaluate the proposed solutions to identify a list of Solution Providers to participate in Oral Presentation and Demonstration activities. Please note that the procurement process will not be complete until final definitive contracts are executed with the selected Solution Provider.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 69,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 70,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Product & Vendor Demonstrations",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 71,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 72,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Product demonstrations and presentations of the solutions will be conducted in Jackson, TN. Solution providers are responsible for arranging with the applicable software product vendor for the product demonstrations and presentations.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 73,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 74,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Solution Confirmation",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 75,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 76,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA, together with the selected Solution Provider (if any), will conduct various working sessions and detailed product reviews. The intent is to develop the scope of the Proposed Solution more fully and to refine pricing and total cost of ownership in preparation for the creation of Best and Final Offers (BAFOs) and final contract negotiations.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 77,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 78,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Submittal Requirement",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 79,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 80,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Interested parties should submit an RFP Response, which", "style": { "font-size": "1.00em" } },
      {
        "text": " includes a one-page cover letter plus a comprehensive response to address all items in the following sections of this RFP.",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      },
      {
        "text": " The cover letter shall include an affirmation that there is not a conflict of interest of the firm and the proposed team, including subcontractors in performing work for JEA or identify any possible conflicts that might impair their ability to perform if awarded the contract, including any familiar or business relationships that the firm, the proposed subcontractors, and their principals have with JEA, its Board, and its employees. Proposals should be clearly marked “RFP – MWMS Solution” and emailed to: jtilley@jaxenergy.com. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 81,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 82,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Evaluation of experience and qualification of firms submitting under this announcement will be based on the information listed above. This RFP is an open solicitation, but only properly qualified and experienced firms will be considered.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 83,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 84,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 85 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Liability Insurance Requirements", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 86,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Vendor shall maintain at his own expense and in good standing, such insurance as will protect himself, JEA, their officers, officials, board, employees, commissions, and volunteers, as well as any subcontractors, from and against any and all claims for damages to public or private property or personal injury, including death, to employees or the public, which may arise from any operations under this contract or any of its subcontracts. The coverage shall contain no special limitations on the scope of protection afforded to JEA. JEA shall appear as \"Additional Insured’s\" on all Commercial General Liability, and Commercial Automobile Liability Insurance for an amount of not less than $2,000,000. In addition, the vendor shall maintain Professional Liability including errors and omissions and completed operations coverage in the amount of $5,000,000. Any failure to comply with reporting provisions of the policy shall not affect coverage provided JEA, their officers, officials, employees, boards and commissions, and volunteers. The Vendor's insurance shall apply separately to each insured against whom a claim is made, or suit is brought, except with respect to the limits of the insurer's liability.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 87,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Vendor, Subcontractors, and their insurers shall agree to waive all rights of subrogation against JEA and their officers, officials, employees, boards and commissions, and volunteers for losses arising from work performed by the Vendor for JEA. Each insurance policy required by this contract shall be endorsed to state that coverage shall not be suspended, voided, canceled by either party, or reduced in coverage or in limits except after thirty (30) days' prior written notice by certified mail, return receipt requested, has been given to the Risk Manager of JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 88,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "In general, insurance is to be placed with insurers with an A.M. Best's rating of A- : VII, although this requirement may be reviewed and modified by the Risk Manager in the best interest of JEA. The Risk Manager may also consider performing such a review upon the request from the Vendor. The Vendor shall furnish JEA with certificates of insurance affecting coverage required by this contract.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 89,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The certificates for each insurance policy are to be signed by a person authorized by that insurer to bind coverage on its behalf. The certificates of insurance are to be received and approved by the Risk Manager of JEA at the time the contract is signed. JEA reserves the right to require complete, certified copies of all required insurance policies at any time.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 90,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "In addition, the Vendor shall be required to furnish the Risk Manager all copies of investigative reports regarding any and all claims filed with the Vendor and its insurance carriers relative to the contract, with the exception of claims filed against their Workers' Compensation Insurance. Such reports shall include date, location, and description of loss as well as amounts of settlements or judgments in order that annual aggregate limits may be monitored by JEA for the Vendor's compliance with these specifications.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 91,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The furnishing of insurance as provided above shall not relieve the Vendor of his responsibility for losses not covered by insurance. Prior to the signing of the contract, evidence of all applicable insurance satisfactory to JEA shall be filed with the Risk Manager of JEA. All policies shall be with insurance companies authorized to do business in Tennessee and shall remain in full force and effect until the final completion of the work and acceptance thereof by the authority of JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 92,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 93 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Vendor and/or its insurer shall notify the Risk Manager of JEA at least thirty (30) days in advance of any insurance coverage to be cancelled or of any insurance coverage that will expire. The Vendor shall simultaneously furnish JEA evidence of new coverage to be effective the same day and hour of the expired or canceled coverage. In event the Vendor fails to submit this evidence of new coverage five (5) days prior to cancelation date or expiration date of any policy or policies, JEA will obtain the required coverage to become effective on the date of cancellation or expiration of said policies. The cost of such new coverage shall be at the expense of the Vendor and any expenditure incurred by JEA for this coverage will be deducted from any balance due to the Vendor. Should JEA be unable to secure new coverage to take the place of the expired or canceled policy or policies, a \"stop work\" order will be issued and all work on the contract shall cease on the same date and hour as the coverage ceases. Should the Vendor fail or refuse to secure coverage within five (5) days after the date of the \"stop work\" order, then in such case the Vendor shall be declared to be in default, and the contract between the parties shall be considered canceled and of no force or effect between the parties reserving all rights of JEA against the Vendor and his surety.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 94,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Basis for Award of Contract ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 95,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will assign an Evaluation Committee to review all responses to this RFP. The evaluation committee will review and evaluate each proposal and recommend to JEA management the best vendor solution based on a number of factors including, but not in any particular order of importance: functionality, vendor technology platform/roadmap, cost, strategic fit, ease of integrations, applications user friendliness, vendor experience and feedback from references. The top bidders may be asked to make an oral presentation/demo. A contract will be awarded to the company delivering the most value to JEA as solely determined by JEA management. Depending on the cost of the project, the contract may require Board approval.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 96,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 97,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "JEA reserves the right to:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 98,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Select for contract or for negotiations, a proposal other than that with the lowest cost",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 99,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Accept/Reject any or all proposals received in response to this RFP, for any reason",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 100,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Issue a new RFP if none of the proposals are accepted", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 101,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Waive irregularities and informalities in any proposal that is submitted ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 102,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Request any modifications to proposals from any or all firms during the review and negotiation",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 103,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Negotiate any aspects of the proposals with any firm and negotiate with more than one firm at the same time",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 104,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 105,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 106,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 107 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "\nRFP AND SUBMISSION REQUIREMENTS", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 108,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "To achieve a uniform review process and obtain the maximum degree of comparability, it is required that the RFPs be organized in the manner specified below. Each RFP response shall include all of the following:",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 109,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Title Page", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 110,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Show the subject title, the name of your firm, address, email address, telephone number(s), facsimile machine number(s), name of contact person and submittal date. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 111,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Table of Contents", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 112,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Clearly identify the materials by section and page number. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 113,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Letter of Transmittal (limited to one page)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 114,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Briefly state your firm’s understanding of the products to be provided and the services to be performed and make a positive commitment to provide services as specified. Give the name(s) of the person(s) who is/are authorized to make representations for your firm, their title, address, email address, telephone number(s) and facsimile number(s). ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 115,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "This letter must be signed by a corporate officer or other individual who has the authority to bind the firm. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 116,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Proposal Contents", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 117,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Proposals should contain a clear and comprehensive response to all requirements/questions in SECTION A3 ”General Questions” and SECTION B “Technical and Functional Requirements” in the order contained herein. All responses should be identified by the corresponding section and item number and all major sections separated by ",
        "style": { "font-size": "1.00em" }
      },
      { "text": "tabs.", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      {
        "text": " Responses to SECTION B must be returned in the table format provided under each “Response” line item and include whether this information is available “out of the box” or as a “customization” by placing an ‘x’ in the corresponding section of the grid.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 118,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Any proprietary information or data should be clearly marked as such.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 119,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Your response should contain the following exhibits:", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 120,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Exhibit 1: Reference pages for your itemized comments associated to your responses in the functional requirements section.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 121,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Exhibit 2: Sample license and Maintenance/Support agreements for all products proposed.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 122,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Exhibit 3: Actual Product release notes for the most recent point release version of your proposed products.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 123,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 124 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "\nGeneral Questions", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 125,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "MANDATORY",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      },
      {
        "text": ": All Respondents must complete this Section. The Prime Vendor must provide a response to every numbered line item in this Section. ",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 126,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "The Prime Vendor ", "style": { "font-weight": "bold", "font-size": "1.00em" } },
      { "text": "must", "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" } },
      { "text": " provide comparable information for ", "style": { "font-weight": "bold", "font-size": "1.00em" } },
      {
        "text": "all subcontractors and application software providers",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      },
      {
        "text": " utilized in its proposal. If an item does not apply, indicate so with “N/A” (not applicable). ",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 127,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Company Profile \t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 128,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 129,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A brief history of company(s) including a summary of all products and services offered.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 130,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Number of years the organization(s) has been in business as a MWMS software vendor/implementer.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 131,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The address and phone number of the U.S. based corporate office(s).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 132,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A full MWMS client list indicating number of active utility accounts by type of service for each, initial implementation date and current version of the product(s) in use. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 133,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "If available, benefit v. cost ratio(s) for a previous client or clients that identify concrete MWMS operational cost savings. Estimations are acceptable if supported by evidence. Alternative benefit/cost analyses are acceptable if ratios are not obtainable.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 134,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A list of three client references of comparable size to JEA including name, address, version number of product, point of contact, and phone number for the products proposed. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 135,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A list of three client references of comparable size to JEA name, address, version number of product, point of contact, and phone number of sites where you have worked ",
        "style": { "font-size": "1.00em" }
      },
      { "text": "with the subcontractor", "style": { "font-style": "italic", "font-size": "1.00em" } },
      { "text": " (if applicable) implementing the products proposed. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 136,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A list of three potential site visit locations of comparable size to JEA including name, address, version number of product, point of contact, and phone number.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 137,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Disclosure and description of all litigation, arbitration, or mediation that you have been involved in for the last ten years. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 138,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Disclosure of any contracts that you have had terminated by the client or that you have terminated within the last ten years. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 139,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 140,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 141 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Organizational Information\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 142,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 143,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An organizational chart for the division of the company(s) directly responsible for the product or services proposed in this RFP. Indicate total employees in the development, sales, support and service (implementation support) departments. Indicate the total number of employees, their distribution by function, and their base location.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 144,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Total Employees.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 145,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Sales.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 146,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Technical Support.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 147,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Research and Development.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 148,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Maintenance.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 149,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Implementation.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 150,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Management.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 151,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "By the above functional areas, the total employee head count ", "style": { "font-size": "1.00em" } },
      { "text": "for your division", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      {
        "text": " by year for the last five years. Identify, with an asterisk, those categories comprised of people serving multiple roles.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 152,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Financial Condition\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 153,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "RFP respondents are required to provide a statement indicating their willingness to provide:",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 154,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Annual reports and/or audited financial statements for the division of the company directly responsible for the product or services proposed in this RFP for each of the last three fiscal years or other pertinent financial information requested that would assure JEA the vendor has the financial ability to perform the requirements of this RFP. ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "A statement indicating your willingness to comply in a timely manner with this requirement and any special conditions (proposed method of providing this information) affecting JEA’s ability to review the requested financial information during the RFP process is required for this section.",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      },
      { "text": " ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 155,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "User Group Information\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 156,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 157,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "The following information regarding user groups:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 158,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "When was the user group organized? ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 159,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "How many members belong to the group?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 160,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "How often does the user group meet?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 161,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "What were the attendee registration costs and where were the locations of the last three meetings?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 162,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Name, phone number and email address of the group president.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 163,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 164 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Are there membership fees?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 165,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Is there a user group bulletin board/newsgroup on the internet?", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 166,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "How often is this bulletin board/newsgroup updated?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 167,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Does the user group provide input concerning product enhancements?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 168,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "What percentage of your product enhancements come from user group suggestions?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 169,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Two examples of enhancements as a result of user group input.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 170,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Is the user group a national organization or are there regional chapters?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 171,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Please provide a copy of the agenda(s) for the most recent national and regional (if applicable) meeting(s). ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 172,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Number Of Systems Implemented\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 173,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 174,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The number of implementations of the version being proposed. Include each application implemented ",
        "style": { "font-size": "1.00em" }
      },
      { "text": "only", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      {
        "text": " where your company was the prime contractor responsible for the project. For each application implemented, include the number contracted in the last 12 months, total number contracted to date, and total number of live sites.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 175,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "List only the number of implementations of the version being proposed where client was converted from an existing legacy or commercial application, or where a new MWMS was implemented for the first time. Upgrades should not be included in this count and should be identified separately in the second table. Please add additional rows to the table below if proposing multiple applications.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 176,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Application", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "# in last 12 months", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Total # to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "# Live to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 177
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 178,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 179,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "List only the number of upgrades of the version being proposed.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 180,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 181,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "index": 182, "type": "page_break", "html_tag": "", "children": [], "metadata": {} },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Application", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "# in last 12 months", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Total # to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "# Live to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 183
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Provide the number of implementations of the version being proposed for ",
        "style": { "font-size": "1.00em" }
      },
      { "text": "each sub-contractor product", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      {
        "text": " being proposed. Please duplicate the tables below for multiple sub-contractors if needed. For each application/product implemented, include the number contracted in the last 12 months, total number contracted to date, and total number of live sites for each sub-contractor.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 184,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "List only the number of implementations of the version being proposed where client was a “new install” (upgrades should not be included in this count and should be identified separately in the second table). Please add additional rows to the table below if proposing multiple applications.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 185,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Application", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "# in last 12 months", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Total # to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "# Live to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 186
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 187,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 188,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "List only the number of upgrades of the version being proposed.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 189,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 190,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Application", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "# in last 12 months", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Total # to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "# Live to Date", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 191
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Product Line Profile\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 192,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 193,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The date and version number of the last two releases for each application proposed. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 194,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 195,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "index": 196, "type": "page_break", "html_tag": "", "children": [], "metadata": {} },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Application", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Release Date and\nVersion # (Most Current Version)",
                "style": { "font-weight": "bold", "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [
              {
                "text": "Release Date and\nVersion # (Prior Version)",
                "style": { "font-weight": "bold", "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#F3F3F3", "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 197
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Policies Regarding Regulatory Requirements", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 198,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Describe:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 199,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "How your solution meets regulatory requirements and best practices for protecting usage, location, and customer data.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 200,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "How you monitor regulatory proposals and mandates in order to identify required changes to application code.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 201,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 202,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [
      {
        "text": "Research And Development Activities / Strategic Product Direction\t ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 203,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 204,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Describe:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 205,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Your current research and development activities. Include percentage of total revenue reinvested in product development annually and provide the actual dollar figure invested/reinvested annually for each of the past five years.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 206,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Your strategic direction for each of the product lines being proposed, including a development timeline through the life of the current version. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 207,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 208,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Implementation Services - Scope Of Work\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 209,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The vendor must describe its proposed implementation approach and services. The proposed solution must include a comprehensive implementation plan for the total Mobile Workforce Management Solution complete with a description of all activities, activity schedule and resources proposed for a successful product(s) implementation.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 210,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will provide project management, test management, business process reengineering, quality assurance resources and user resources to support JEA’s obligation under the project contract in accordance with the vendor’s installation approach and associated activities. However, if the vendor expects JEA to provide other resources in addition to those mentioned above, JEA may ask the vendor to provide those supplemental onsite resources during the implementation process. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 211,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Provide your views and description (# of staff, project roles, base location, and time commitment) of your proposed implementation team as well as your expectations for the JEA provided project staff. ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "Please be candid in your response",
        "style": { "text-decoration": "underline", "font-size": "1.00em" }
      },
      { "text": ".", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 212,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Please provide 2 examples of implementations where your ", "style": { "font-size": "1.00em" } },
      { "text": "recommended", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      { "text": " implementation team has provided similar services. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 213,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 214 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The implementation of the proposed solution will need to include the following deliverables at a minimum. Describe your capability and approach to provide",
        "style": { "font-size": "1.00em" }
      },
      { "text": " ", "style": { "font-weight": "bold", "font-size": "1.00em" } },
      {
        "text": "the following implementation services (if you are not proposing or capable of providing the services, please explain):",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 215,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Initiation Activities", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 216,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Installation of all applications on JEA’s hardware and certification that the applications are ready for use.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 217,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Creation of two environments. JEA anticipates that there will be a need for at least the following environments: Development and Production. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 218,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Vendor will present a high-level overview of each functional area covered by the licensed software.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 219,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Vendor will provide core team training for the applications to be covered by this phase of the project.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 220,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Vendor will identify all the appropriate functional areas and interfaces to be analyzed in the Analysis Phase and determine to what extent the analysis should be carried out. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 221,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Interfaces will be identified, the current systems’ environment will be presented, and a future state will be defined as the goal of the Project. Vendor will deliver any appropriate standard interfaces. If modifications are needed or “one off” interfaces required, custom development hours for each interface should be included in your RFP.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 222,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Initial drafts of strategy documents will be prepared for conversion and interfaces. ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "Vendor will provide all standard reports",
        "style": { "text-decoration": "underline", "font-size": "1.00em" }
      },
      { "text": " from within the product. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 223,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review of operating requirements, hardware configuration and requirements, processing speed and capacity based on any revised requirements required for initial project environments identified in item ii above.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 224,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The scope of the Project and results of the Initiation Phase will be documented in a report to be approved by the project sponsor and project manager.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 225,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Analysis Activities", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 226,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Conduct an overview of functionality with end user departments.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 227,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Vendor will lead functional workshops in which the appropriate focus groups are taken through business processes supported by the standard configured software, and issues regarding the use of the software at JEA's site are identified. The functional areas and interfaces to be analyzed will be those identified in the Initiation Phase.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 228,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review of business processes as supported by the base product. Any functional modifications or interface changes resulting from changes in requirements are out of scope and will need to be documented by a change request.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 229,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 230 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review standard configuration and codes/rates/control tables with functional subject matter experts (SMEs). ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 231,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review internal procedures to identify issues and define a resolution plan.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 232,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Identify, suggest, and incorporate preferred business practices consistent with other implementations of the application software product. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 233,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Document issues, including potential solutions, using these categories: business process re-engineering; configuration of the base modules; specialized configuration through a customized algorithm or building a workflow or work list; or submitting a product enhancement request for additional functionality to be built into a future release of a module. All specialized configuration through a customized algorithm or building a workflow or work list will have to be documented and cost justified by the JEA and will be submitted to JEA management for change control approval. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 234,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Review all related data for any necessary conversion mapping. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 235,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review and define any necessary data cleansing activities. Identified data cleansing will be done by JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 236,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Review and define required Reports/Dashboards. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 237,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Identify related workflow, work list, and script building requirements. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 238,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review operating requirements, hardware configuration, processing speed and capacity based on any revised requirements.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 239,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review and publish vendor provided test plans and operational readiness plans.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 240,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Review the Vendor and overall project schedules at the end of the Analysis phase.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 241,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Assembly Requirements", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 242,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "These areas will be the focus during the Assembly Phase. Primarily, the team should be getting configuration and code-based extensions (if needed) ready for testing and implementation. The following topics should be addressed, and deliverables developed as needed, at a minimum:",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 243,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Application Functionality checked and cross referenced to JEA’s documented Requirements",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 244,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Business Transformation/Business Consulting/Public Relation activities",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 245,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Conversion and Conversion Balancing", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 246,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Vendor provided Interfaces", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 247,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "JEA side Interfaces", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 248,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "IT Infrastructure", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 249,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 250 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Training", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 251,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Testing including an overall testing plan and schedule", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 252,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Acceptance Requirements", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 253,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Application functionality fully matches product documentation. In addition, application meets requirements as specified in Vendor’s response to the functional requirements, the Analysis Report and any subsequent approved Change Requests. Determination of application alignment with the above will be based on test scripts developed during Analysis and Assembly. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 254,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Interfaces are tested and confirmed to perform substantially as specified in the Analysis Report. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 255,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Conversion procedures are run to provide current data for testing as required. This also enables the conversion procedures to be tested.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 256,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Vendor provides training sessions as defined in the proposal response.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 257,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Acceptance testing is conducted according to the Acceptance Test Plan.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 258,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Final Hardware platform requirements for live operations are installed.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 259,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Contingency and Cutover/Rollout plans are finalized.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 260,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Note: The system will be deemed to have passed the Acceptance Test at the earlier of (a) when all acceptance criteria defined in the Acceptance Test Plan have been satisfied, or (b) JEA puts the complete system into live commercial operation.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 261,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Before Go-Live", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 262,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The preparations for formal cutover will be included in the Cutover Plan, to be approved by project sponsors and project manager before the Go-Live process can be initiated. Most of the activities will have started during earlier phases of the Project but must be completed before the cutover can be accomplished. The following list includes the minimum types of activities that are necessary for this preparation. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 263,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA end users' complete application training and training in business and operating procedures as delivered by Vendor.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 264,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA notifies customers of any anticipated changes in communication procedures or documents. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 265,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA Board approves enabling policies, procedures, or eligible change requests as needed.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 266,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA prepares system for live operation with Vendor guidance, including migration of system to production environment.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 267,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "JEA reviews deployment and contingency plans.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 268,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Cutover", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 269,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 270 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The actual cutover will probably be scheduled to take place over a long weekend and will take place on mutually agreed upon dates. The Cutover Plan will include hourly scripting of the activities that need to take place. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 271,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The vendor will lead in developing the software Cutover Plan. Plan must be approved by project sponsor(s) and project manager.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 272,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Vendor will provide assistance during the cutover. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 273,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The objective of cutover is to perform those conversion and support activities necessary to begin operation of the new system in a production environment. By the end of this task, the application system should be formally operational in the production environment.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 274,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Post Go-Live", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 275,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA to be supported by defined vendor personnel for 30 calendar days. After that, application support will transition to the vendor’s normal offsite support team.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 276,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Final System Acceptance occurs upon the completion of 60 calendar days of production use of associated applications without any outstanding Priority 1 or 2 issues and at least 30 calendar days since the last Priority 1 or Priority 2 issue resolution.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 277,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "JEA signs off on the Certificate of Project Completion.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 278,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Outstanding monies retained by JEA are released to the Vendor.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 279,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "A follow-up report is completed.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 280,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The transition takes place between the Project team and the customer support services group.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 281,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 282,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The names and positions of your proposed implementation team. Include a resume for each team member showing qualifications, experience, and base location, including the name and contact phone number for the last two sites worked by each proposed team member and their role at that location.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 283,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description by individual (name and title) of the general tasks your personnel will perform during implementation.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 284,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The typical delivery lead-time from contract execution to (a) the delivery of the Application Documentation and (b) the onsite installation of the base Application Software.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 285,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "The anticipated conversion/implementation time in weeks. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 286,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A suggested implementation schedule for the proposed system (e.g., a time-phased Gantt chart). Include installation, planning, fit analysis, conversion, training, testing, interfaces, and system modifications noted in the functional requirements, critical milestones, and data conversion tasks (all of the activities identified above).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 287,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "JEA’s responsibilities and JEA manpower requirements for each implementation task.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 288,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 289 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The type of implementation support you normally provide as part of your proposed implementation services. Include site preparation, best practices recommendations, system testing, user training, data conversion, parallel running, custom/specific documentation, and any other implementation items.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 290,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A Software Specification/Requirements Document outline that will be used in any ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "proposed custom modification process",
        "style": { "text-decoration": "underline", "font-size": "1.00em" }
      },
      {
        "text": ". Also provide a description of your standard requirements definition/approval procedures used for custom work.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 291,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A Software Specification/Requirements Document outline that will be used in the ",
        "style": { "font-size": "1.00em" }
      },
      { "text": "custom interface process", "style": { "text-decoration": "underline", "font-size": "1.00em" } },
      {
        "text": ". Also provide a description of your standard requirements definition/approval procedures used for custom work.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 292,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "How vendor staff assesses and defines business processes to ensure that technological solutions meet best business practices for JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 293,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 294,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Vendor Supplied Training\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 295,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 296,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A list of available training courses for all proposed products and your specific training plan for JEA. The training must be comprehensive enough to ensure that JEA’s user base can effectively use and maintain the system. It should also include a competency evaluation of each trainee and remedial training as needed. Areas that should be included (but not limited to) in the training are: ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 297,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Overview of the system.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 298,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "System administration and operations.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 299,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Record inquiry, data entry, and similar routine operations.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 300,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "MDMS processes as outlined in this RFP package.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 301,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Routine and ad hoc report generation.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 302,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Year-end and other periodic processing.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 303,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Interfacing with other systems and programs.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 304,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The following details for each available course (onsite or offsite; functional or technical):",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 305,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Target audience.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 306,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Maximum number of attendees per session.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 307,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Course contents.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 308,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Prerequisites.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 309,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Instructional Medium (classroom, self-study, computer tutorial, on-the-job instruction).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 310,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Duration.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 311,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Materials required other than manuals (e.g., audiovisual equipment).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 312,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "List training facility requirements by course type:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 313,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 314 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "JEA facility - Computer Lab, Conference Room.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 315,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Vendor’s facility - Specify location.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 316,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "If on-site training is included in your proposed implementation, given the following information: ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 317,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The audience to be trained in a phased approach includes, as an estimate: 4 Information Services staff, 5 Core Team members, 10 supervisory staff or key personnel, 5 special function staff (full system users maintaining control tables and troubleshooting system problems or enhancements), and 30 end users.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 318,
    "is_list": true,
    "list_type": "ul",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A suggested schedule for user training, including an option on a “train the trainer” approach. JEA requires the vendor to provide all team and end user training prior to final system acceptance. An appropriate JEA Core Team member will participate in each training session in an effort to support the vendor supplied training and address business related questions that may occur.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 319,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 320,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Maintenance And Technical Support\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 321,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 322,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that your company will provide product corrections, without charge, for any implementation/conversion errors including but not limited to programs, configuration, data, objects, interfaces, etc. discovered after installation of the application. Will this support continue for the contract warranty period and as long as JEA maintains an active Maintenance/Support Agreement with Vendor?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 323,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that your company will provide corrections (e.g., patches) for JEA’s system if program errors are discovered at another user’s site. Explain in detail. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 324,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A list of all known outstanding errors/system deficiencies and the anticipated schedule for their resolution. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 325,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of how customer requested enhancements to the system are handled. Include any applicable pricing structure.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 326,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The date you anticipate the next update of your current release. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 327,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "How long do you support each version? ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 328,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "What is your policy for updating the MWMS? ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 329,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "How often do you provide new product version releases? Is this on a scheduled or flexible basis? ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 330,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Describe and include any policies related to ancillary products (e.g., cash receipting, bill printing, portal implementation/expansion) included in your RFP.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 331,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of the policy for updating and/or certifying the application when new releases of system software becomes available (e.g., new releases of SQL, Windows, UNIX).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 332,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 333 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of the policy for updating and/or certifying the application when new releases of third-party application software becomes available.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 334,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An explanation of how a new version or release of the product is implemented. Provide a detailed explanation of the upgrading process (e.g., overall implementation timeframe, vendor effort/time/resources, JEA effort/time/resources, and estimated cost).\t",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 335,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of cost considerations (if any) to your current customers when one of your proposed applications undergoes major redesign and a customer wishes to implement a new release. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 336,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of your product licensing policy including base product(s) and any customized code. JEA requires a perpetual license for the proposed product.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 337,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An explanation of procedures to retrofit all (if any) customizations made by the vendor, for JEA, into new releases of the application or new releases of a third-party application in the case of interfaces. Describe regression-testing procedures used to ensure previous changes are not impacted by the new release.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 338,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verify that your company provides telephone support on an on-going basis. What are the times during the day that it is available? Is the cost of this support included in the maintenance costs? Provide the location of your product support team.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 339,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A guaranteed response time for support calls: during work hours (6am to 7pm CST), both average and guaranteed response time, and during off-hours (7pm to 6am CST), both average and guaranteed response time.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 340,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that the Vendor will provide on-site support if there is a “production down” situation and the remote diagnostics prove inconclusive. Provide your procedures for this situation.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 341,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Your company’s Web site capabilities. What customer service/support features are available at this site?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 342,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that all point releases are made available to all customers.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 343,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that all patches are made available to all customers.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 344,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that all modifications performed for any customer are made available to all customers.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 345,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An explanation of how problems are classified according to degree of urgency. For example: (very urgent) is responded to within two hours; (urgent) within eight hours; (somewhat urgent) within 48 hours; and (not critical) within five working days. Who determines the priority of the problem? Are these response times average or guaranteed?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 346,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that the Vendor has escalation procedures if they are unable to resolve a problem within the established response times.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 347,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The minimum and recommended staffing levels, including expertise and roles needed to support your MDMS application, for a client of the size and complexity of JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 348,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that your organization can provide the recommended level of technical staff to support your MDMS product if JEA was unable to provide the in-house support staff recommended in 3.9 (13) above. Also provide expected lead time to provide such resources.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 349,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 350 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will entertain hosted/cloud/SaaS offerings in addition to on-premise models. ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "If you propose an on-premise model, please see below. ",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 351,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Complete the following hardware and software specifications section of this RFP based on each of the environments proposed in Section A.3.9 (1) (ii) for the JEA implementation. It should be noted that JEA is currently an Intel/Windows based shop and uses virtualization with Hyper-V. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 352,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Database Server", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 353,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 354,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Based on JEA’s size and complexity, the recommended hardware/software configuration for the Database Server.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 355,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Describe your product’s support of server virtualization.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 356,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Describe your product’s support of a clustered configuration. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 357,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Hardware Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 358,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Processor type (specify).", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 359,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Processor/MHZ, type, number of CPUs and Cores.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 360,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "RAM capacity needed for initial application execution.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 361,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Type of Hard Drive and RAID configuration.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 362,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application programs.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 363,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application data.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 364,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "The details of any other hardware requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 365,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Software Requirements:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 366,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "The Server Operating System and minimum version required. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 367,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "32 and/or 64-bit version support. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 368,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Network Protocols Supported: TCP/IP.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 369,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Database Software supported (including edition) and minimum version required (e.g., SQL 2016 standard edition). ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 370,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other software requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 371,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 372,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 373,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 374 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": " Application Server(s)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 375,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 376,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Based on JEA’s size and complexity, the recommended hardware/software configuration for the Application Server(s).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 377,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Describe your product’s support for virtualization.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 378,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Describe your product’s support of a clustered configuration.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 379,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Hardware Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 380,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Processor/MHZ, type, number of CPUs and Cores. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 381,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "RAM capacity needed for initial application execution.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 382,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Type of Hard Drive and RAID configuration.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 383,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application programs.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 384,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application data.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 385,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "The details of any other hardware requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 386,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Operating System Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 387,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Operating System and minimum version if required. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 388,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "32 and/or 64-bit version support.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 389,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Application server software requirements (e.g., WebSphere, JBoss, etc.). ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 390,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other software requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 391,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Network Protocols Supported: TCP/IP.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 392,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other Network requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 393,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 394,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": " Web/Workstation Server(s)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 395,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 396,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Based on JEA’s size and complexity, the recommended hardware/software configuration for the Web/Workstation Server(s):",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 397,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Hardware Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 398,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Processor/MHZ, type, number of CPUs and Cores. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 399,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "RAM capacity needed for initial application execution.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 400,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Hard Drive type and RAID configuration.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 401,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application programs.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 402,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 403 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application data.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 404,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "The details of any other hardware requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 405,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Operating System Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 406,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Operating System and minimum version.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 407,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "32 and/or 64-bit version support. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 408,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Web Server software requirements (e.g., Apache, IIS Server, etc.).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 409,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other software requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 410,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Network Protocols Supported: TCP/IP.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 411,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other Network requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 412,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 413,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": " Client PCs/Workstations", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 414,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 415,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Based on JEA’s size and complexity, the recommended hardware/software configuration for Client workstations.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 416,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Hardware Requirements: ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 417,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Processor/MHZ, type, number of CPUs and Cores. ", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 418,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "RAM capacity needed for application execution.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 419,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Hard Drive capacity needed for initial application programs.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 420,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Other Hardware requirements (e.g., video card specifications).", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 421,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Software Requirements:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 422,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Operating System and minimum version. Must support Windows 10.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 423,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "32 and/or 64-bit version support.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 424,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Network Protocols Supported: TCP/IP.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 425,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The software required for the client workstation (e.g., run-time libraries, DLLs required, and license fees for the run-time environment).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 426,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Other Software requirements.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 427,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "The middleware used between the Client and the Database server.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 428,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Confirmation that your product supports distributed processing in a client/server environment.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 429,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Identify Antivirus Software products that are compatible with your product.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 430,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 431 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Required Modifications to Client:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 432,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "The changes required to the existing Client control files (e.g., config.sys, autoexec.bat, win.ini). Provide examples if possible.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 433,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of the Client installation (initial and new release) procedures.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 434,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Can your product be executed on a workstation without the user having administrative rights?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 435,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Is your product MS Windows certified?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 436,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "The list of required drivers and their memory requirements.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 437,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 438,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": " Other Hardware/Software Requirements", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 439,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 440,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of the recommended back-up and recovery procedures for all servers, including recommended hardware, configuration, software, and frequency.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 441,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of batch job scheduling requirements including any third-party software.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 442,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Describe any APIs or Web services that are provided with the application to facilitate integration with other products.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 443,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "In addition to the other questions in this section, a list of all platforms, operating systems, RDBMS, and other appropriate hardware/software (e.g., barcode scanners, printers, e-mail, imaging, meters, ERTs, PLCs, etc.), which you certify your application to run on.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 444,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that your product uses a true browser-based frontend. Describe your client/workstation technology. If so, list all compatible browsers.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 445,
    "is_list": true,
    "list_type": "ol",
    "list_level": 2
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 446,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Performance And Availability\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 447,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 448,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification that the system will be available 24 hours per day, seven days per week for updates and inquiries.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 449,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Verification of Performance and Scalability. Include a description of your system’s typical on-line transaction response times.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 450,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Indicate the typical nightly complete batch window time. Include specific run times for specific processes. Use the assumptions you list under 2 above. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 451,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 452 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "A description of how your solution will allow JEA to perform inquiries and do reporting without affecting the performance of the system. Use the assumptions listed under 2 above.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 453,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "An explanation of full backup procedures.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 454,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An explanation of your recommended operation/monitored thresholds to be used by JEA for determining when action is needed.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 455,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Describe your product’s archiving/purge/restore procedures and capabilities.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 456,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "An explanation of batch processing performance based upon recommended hardware.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 457,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 458,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Application Software Characteristics And Features\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 459,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Provide and/or answer the following:", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 460,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "What programming language(s) is your product written in?", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 461,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Is your product’s source code provided and licensed to JEA?", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 462,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "What development tools are required to support configuration and/or modifications to the system?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 463,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Is your product based on a two- or three-tiered or N-tiered client/server architecture? Describe in detail.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 464,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Is your product a 64-bit application?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 465,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Does your product fully use the Microsoft SQL Server relational database (i.e., all required application data is stored in the Microsoft SQL Server database)?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 466,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Does your product utilize a normalized database (N3 or above)? If so, describe.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 467,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 468,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Documentation\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 469,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "For each of the following documents, specify if the document is provided and indicate whether it is provided in hard copy and/or electronic format:",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 470,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Major system overviews for all components?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 471,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "System flowcharts and/or Data Flow Diagrams?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 472,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Entity Relationship Diagrams?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 473,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Workflow Diagrams?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 474,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Data Dictionary?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 475,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Program documentation?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 476,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Program source code documentation?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 477,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 478 },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Installation and configuration procedure manuals for servers and workstations including the setting of the server environment. (These define ports to be defined, space limitations)? ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 479,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Manuals describing the use of the application software (User’s Manual)?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 480,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Training materials (two complete sets, including one reproduction-ready copy)?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 481,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Input screen/panel documentation with data entry explanations?", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 482,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Samples of all standard reports with narrative descriptions of all fields displayed on the report, input parameters and an explanation of how to execute each report?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 483,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "All error codes, messages and their explanations displayed on-line and/or on reports with action required or options available?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 484,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Samples of all on-line screens with a narrative description of all fields displayed on the screen?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 485,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "System Administration and Security Manuals?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 486,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Computer operations documentation? (e.g., scheduling information, job and restart/recovery information, error codes, job dependencies and flow charts).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 487,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Procedures for updating technical and user documentation for new releases?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 488,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      { "text": "Standardized user exits/plug-ins for custom enhancements?", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 489,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Help desk documentation for internal user support?", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 490,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Is all updated system documentation available concurrent with the release of new software updates? ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 491,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Will JEA be authorized to make or print additional copies of the documentation for their own use without incurring any additional fees?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 492,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "Is all the documentation available in Microsoft Word or Adobe Acrobat? ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 493,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [
      {
        "text": "May vendor-training classes be recorded for JEA use? Is an additional cost involved in this situation?",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 494,
    "is_list": true,
    "list_type": "ol",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 495 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "SECTION B\nTECHNICAL AND FUNCTIONAL REQUIREMENTS", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 496,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 497,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "Response Requirements", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 498,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Vendor responses to the questions in Section B should pertain to a version of the product or system that is in production use in an environment that is comparable to JEA as of the proposal due date. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 499,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 500,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Specify the Product (module) and version number that satisfies the requirement. The Product (module) must be listed on the software cost sheet in Section C. All products proposed to satisfy the requirement shall be available and in production as of the date of the response. Any proposed products so satisfy the requirement and not yet available/in production must clearly state the future date of availability for each specific product proposed.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 501,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 502,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "In the ", "style": { "font-size": "1.00em" } },
      { "text": "Response", "style": { "font-weight": "bold", "font-size": "1.00em" } },
      { "text": " sections:", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 503,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 504,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Vendor is to provide an explanation for each functional requirement that the Vendor feels has restrictions, limitations, or needs clarification, or where additional details were requested by JEA. Include whether the requirement is satisfied by a configuration change through a rule or system table, a configuration change through a user exit, or a custom modification. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 505,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 506,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Vendor must also provide a brief explanation of each enhancement or modification with a cross-reference to the Enhancements & Modifications Cost Sheet in Section C. Bidders may use a separate attachment for details, provided the detail is easily identifiable back to the appropriate requirement(s).",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 507,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 508,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Any modifications should also be reflected in the project plan timeline. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 509,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 510,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Functional Requirements Sections are categorized as follows:",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 511,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 512,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "General Requirements (GR)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 513,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Cyber Security (CS)", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 514,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 515,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 516,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Instructions:", "style": { "font-weight": "bold", "font-size": "1.00em" } },
      {
        "text": " The “Response” field below each section should be filled out by the Vendor with a targeted assessment of ways the proposed solution matches (or does not match) the functional requirement. The “Item” code will assist in tracking and referencing each requirement. For Functional Requirements, please indicate whether your proposed solution meets JEA’s “must” or “want” criteria out-of-the-box or whether it requires customization. If the requirement has already been addressed in other sections of your RFP response, please provide a reference (page number or section/paragraph) in the “Response” field below. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 517,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 518,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 519,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 520,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 521 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "General Requirements (GR)", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 522,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "index": 523, "type": "page_break", "html_tag": "", "children": [], "metadata": {} },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "Item", "style": { "font-weight": "bold", "font-size": "1.00em" } },
              { "text": " ", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Requirement Description",
                "style": { "font-weight": "bold", "font-size": "1.00em", "color": "#000000" }
              },
              { "text": " ", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "left" }
          },
          {
            "index": 2,
            "children": [
              { "text": "Must", "style": { "font-weight": "bold", "font-size": "1.00em" } },
              { "text": " ", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "center" }
          },
          {
            "index": 3,
            "children": [
              { "text": "Want", "style": { "font-weight": "bold", "font-size": "1.00em" } },
              { "text": " ", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "center" }
          },
          {
            "index": 4,
            "children": [
              { "text": "Out of the Box", "style": { "font-weight": "bold", "font-size": "1.00em" } },
              { "text": " ", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "center" }
          },
          {
            "index": 5,
            "children": [
              { "text": "Custom", "style": { "font-weight": "bold", "font-size": "1.00em" } },
              { "text": " ", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "background-color": "#D9D9D9", "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR01", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "SQL Server DB Platform preferred if internally hosted",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR02", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Hyper V Virtualization Support if internally hosted",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR02", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Native iOS App – iPhone, iPad, Android",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR04", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [{ "text": "Offline Capable", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR05", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Robust and fully documented API to allow other source systems to generate work",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR06", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Outbound messaging capabilities via REST API as preferred method – for example when closing an order, send information to another system",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR07", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Display pertinent data from source system such as service order history",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR08", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Realtime notifications (audible / push) for priority work",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 16,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 17,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR09", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Fully segment and isolate workgroups into different domains – Telecom, Field Service, Installs, Meter Reading",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 18,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 19,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR10", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "GPS tracking of technicians", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 20,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 21,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR11", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [{ "text": "Skills based routing", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 22,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 23,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR12", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Capable of appointment scheduling", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 24,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 25,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR13", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Customer Self Service capability – JEA does not currently do this, but is interested",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 26,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 27,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR14", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "View/maintain resource availability easily on a calendar for purposes of handling evening rotations, weekends, on-call, vacations, and holidays of resources",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 28,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 29,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR15", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Ability to prioritize work based on Service Order Type and / or customer type",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 30,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 31,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR16", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Automated scheduling and optimization with ability to reassign work as needed.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 32,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 33,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR17", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Segment resources by geospatial districts",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 34,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 35,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR18", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Assign incoming work by geospatial districts",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 36,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 37,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR19", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Robust reporting (i.e., technician efficiency, weekly metrics, etc.) with ability to customize and export results to various formats..",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 38,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 39,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR20", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Display multiple meters of same service type if applicable (i.e., if customer has 2 electric meters on account)",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 40,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 41,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR21", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Define alerts by order type", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 42,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 43,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR22", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Confirmation of orders before canceling",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 44,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 45,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR23", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Automatically adjust schedule and available work districts based on time of day, i.e., after office hours rules",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 46,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 47,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR24", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Key Dashboards / KPIs for display of pertinent information (configurable)",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 48,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 49,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR25", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Ability to generate Ad Hoc work – example – when present work at location requires further tasks to be completed – this may or may not tie back into source system (CIS)",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 50,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 51,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR26", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Robust Map functionality that shows both worker and order statuses in real-time or near real time. This includes filtering criteria such as date range, order type, technician, etc.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 52,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 53,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR27", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Ability to overlay ESRI base maps", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 54,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 55,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR28", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Automatic background syncing for all interfaces with sync schedules less than 5 minutes and durations less than 30 seconds",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 56,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 57,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR29", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Intelligent scheduling engine with configurable parameters such as call priority, skills, districts, distance, drivetime, workload, etc. Must be able to maximize worker efficiency while minimizing worker downtime.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 58,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 59,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR30", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Ability to drip feed work order assignments",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 60,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 61,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR31", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Child order workflow that creates sub-orders which refer back to parent orders upon completion.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 62,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 63,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR32", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Intuitive and configurable dispatch environment that allows for easy viewing of both scheduled and unscheduled work.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 64,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 65,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR33", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Ability to create and edit order templates internally",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 66,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 67,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR34", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Ability to schedule future work", "style": { "font-size": "1.00em", "color": "#000000" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 68,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 69,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR35", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Asset management system that enables workers to view and manage account assets from the field.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 70,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 71,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR36", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Robust security measures that protect sensitive information",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 72,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 73,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "GR37", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Ability to switch between district routing and distance routing.",
                "style": { "font-size": "1.00em", "color": "#000000" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em", "color": "#000000" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 74,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 524
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 525,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 526,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 527,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [{ "text": "Cyber Security (CS)", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 528,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 529,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "ITEM", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [{ "text": "Information Requests", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS00", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Describe information security encryption and data integrity precautions.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS01", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Describe process for access and authorization.", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS02", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Describe current policies and future plans within your company regarding cyber security.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS03", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Describe monitoring and reporting of any security breach, including current policies and future roadmap.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 530
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "center",
    "metadata": {},
    "index": 531,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "index": 532, "type": "page_break", "html_tag": "", "children": [], "metadata": {} },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "ITEM", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Functional Requirements", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "Must", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 3,
            "children": [{ "text": "Want", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 4,
            "children": [{ "text": "Out of Box", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 5,
            "children": [{ "text": "Custom", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS04", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide access control based on user logins, responsibilities, and roles.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS05", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide a system for change control and administration/management of configuration.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS06", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Monitor and log failed user login attempts and other system intrusions – detect, monitor, log, report, and notify. Address on-prem or cloud-specific details.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS07", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Integrate with JEA’s existing Active Directory Authentication. Describe.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS08", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "JEA has the ability to access, control, and manage data, as well as migrate or move should service end with the current vendor.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS09", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide Access Control Lists (ACLs) for system hardening.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          { "index": 2, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          {
            "index": 3,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          { "index": 4, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS10", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 1,
            "children": [{ "text": "Allow for or provide patch management.", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#FFFFFF", "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#FFFFFF", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS11", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Allow for or provide malware and anti-virus prevention system(s). Address on-prem or cloud-specific details.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 16,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 17,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS12", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Allow for or provide AAA Security/Account Management.", "style": { "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 18,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 19,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "ITEM", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              { "text": "Functional Requirements", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "Must", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 3,
            "children": [{ "text": "Want", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 4,
            "children": [{ "text": "Out of Box", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 5,
            "children": [{ "text": "Custom", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          }
        ],
        "is_header": false
      },
      {
        "index": 20,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS13", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide event and log monitoring/notification for all Incidents. Address on-prem or cloud-specific details.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 21,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 22,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS14", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide automated reporting of cybersecurity breaches/incidents based on log monitoring. Address on-prem or cloud-specific details.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 23,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 24,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "CS15", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 1,
            "children": [
              {
                "text": "Provide backup/restore capabilities and DR roadmap. Address on-prem or cloud-specific details.",
                "style": { "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "center" }
          },
          {
            "index": 2,
            "children": [{ "text": "x", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "center" }
          },
          { "index": 3, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "center" } }
        ],
        "is_header": false
      },
      {
        "index": 25,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "Response", "style": { "font-size": "1.00em" } }],
            "cell_style": { "background-color": "#DDD9C3", "text-align": "center" }
          },
          { "index": 1, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 2, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 3, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 4, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } },
          { "index": 5, "children": [], "cell_style": { "background-color": "#DDD9C3", "text-align": "center" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 533
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 534,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 535 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "SECTION C\nRESPONSE COST FORMS", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 536,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h1",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 537,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h1",
    "children": [
      { "text": "Cost of Software, Implementation and Support Services", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 538,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Please Note: This section (Section C: Cost) ",
        "style": { "font-style": "italic", "font-size": "1.00em" }
      },
      {
        "text": "should be submitted twice if you are selected as a finalist. Once with the regular RFP response (submitted by all responding vendors) providing an initial cost estimate for the JEA project (products & services all inclusive)",
        "style": { "font-style": "italic", "text-decoration": "underline", "font-size": "1.00em" }
      },
      {
        "text": ". Then, the short-listed finalists will submit the Cost Section a second time. After each finalist completes fact finding, they shall again complete Section C with their “best and final” fixed price proposal. This Final Pricing & associated Statement of Work shall be due in accordance with the schedule as outlined in section A1.6, the Anticipated Calendar of Events. In addition to the cost information forms below, ",
        "style": { "font-style": "italic", "font-size": "1.00em" }
      },
      {
        "text": "each proposer shall submit a draft Project Plan and a draft detailed Statement of Work",
        "style": { "font-weight": "bold", "font-style": "italic", "font-size": "1.00em" }
      },
      {
        "text": " as a part of Section C’s final submission. Failure to do so may result in the proposer being deemed non-responsive.",
        "style": { "font-style": "italic", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 539,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Instructions:\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 540,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "All prices quoted must be a fixed price for software, training, customization, implementation, and support services. JEA, at its sole discretion, may elect to add additional features or remove requested features prior to finalizing costs during contract negotiations. JEA will require the proposer to submit a brief statement of understanding for each modification/configuration and interface with their final costs. Modification Costs should be itemized (per customization) and also indicate hours/cost per hour. ",
        "style": { "font-size": "1.00em" }
      },
      {
        "text": "All costs or hourly rates quoted herein shall be inclusive of all travel and living expenses for the entire project.",
        "style": { "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 541,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Specify the Product (module) and version number that satisfies the requirement. The Product (module) must be listed on the software cost sheet.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 542,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The proposer may include any additional price sheets or pricing information as deemed necessary to fully inform JEA of all costs and options associated with the Project.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 543,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "ALL COSTS ARE TO BE QUOTED IN U.S. DOLLARS",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      },
      { "text": ".", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 544,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 545,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "For cost comparison / analysis, please consider the following (subject to change)",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 546,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Technicians",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 547,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Utilities – 30", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 548,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Telecom – 30", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 549,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Dispatchers",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 550,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Utilities – 10", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 551,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Telecom – 30 (all technicians are allowed to dispatch their own work – proper automation could handle this)",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 552,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 553 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Read Only ",
        "style": { "font-weight": "bold", "text-decoration": "underline", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 554,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Customer Service - 20", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 555,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "li",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 556,
    "is_list": true,
    "list_type": "ul",
    "list_level": 1
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 557 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "C1.\tApplication Software\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 558,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Indicate below the cost of the products, including application software, database, and 3rd party software, included as part of this proposal.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 559,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 560,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "APPLICATION", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [
              { "text": "One-Time Base Package License Fee", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [
              { "text": "SUPPORT & MAINTENANCE", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [
              { "text": "SUPPORT & MAINTENANCE", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 4,
            "children": [
              { "text": "SUPPORT & MAINTENANCE", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 5,
            "children": [
              { "text": "SUPPORT & MAINTENANCE", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 6,
            "children": [
              { "text": "SUPPORT & MAINTENANCE", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "(Name of Application/Product)", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "(Cost of License)", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "1st Year", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "2nd Year", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 4,
            "children": [{ "text": "3rd Year", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 5,
            "children": [{ "text": "4th Year", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 6,
            "children": [{ "text": "5th Year", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "TOTAL COST", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 5, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 6, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 561
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 562,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 563,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 564 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [
      { "text": "C2.\tOne Time Implementation Services Cost Summary\t", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 565,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Indicate below the implementation services included as part of the proposal package. Note costs by area of service & remember to use inclusive hourly rates.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 566,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 567,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "SERVICE CATEGORY", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "Hours", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "Hourly Rates", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "TOTAL FIXED COST", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "1.  Software Installation", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "2.  Business Process/Gap Analysis", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "3.  Table Setup/System Configuration", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "4.  Data Conversion", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "5.  Interface(s) Provided", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "6.  Customization/Modifications", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "7.  Report/Query Development", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "8.  Testing/Testing Support", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "9.  Training", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "10. Post-Implementation Support", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "11. Other (please specify):", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "TOTAL COST OF IMPLEMENTATION SERVICES", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 568
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 569,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 570,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 571 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "C3.\tItemized Interface Costs\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 572,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Vendors should provide comprehensive responses how these interfaces and integrations will be provided for in their solution.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 573,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 574,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Please add rows to the table as necessary.", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 575,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 576,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "NAME OF INTERFACE", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "FIXED COST", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 16,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "TOTAL COST OF ALL INTERFACES", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 577
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 578,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 579 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [
      {
        "text": "C4.\tItemized Cost For All Proposed Enhancements And Modifications\t",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 580,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 581,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [
              {
                "text": "NAME OF ENHANCEMENT OR MODIFICATION",
                "style": { "font-weight": "bold", "font-size": "1.00em" }
              }
            ],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "FIXED COST", "style": { "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 16,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 17,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 18,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "TOTAL COST OF ENHANCEMENTS", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 582
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 583,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 584 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "C5.\tItemized Training Costs\t", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 585,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "List below, by course, number of sessions proposed, the maximum number of attendees per session, the total hours, and total cost of the proposed training services. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 586,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 587,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 588,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "table",
    "html_tag": "table",
    "rows": [
      {
        "index": 0,
        "cells": [
          {
            "index": 0,
            "children": [{ "text": "COURSE", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 1,
            "children": [{ "text": "# SESSIONS", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 2,
            "children": [{ "text": "MAXIMUM ATTEND.", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 3,
            "children": [{ "text": "TOTAL HOURS", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          },
          {
            "index": 4,
            "children": [{ "text": "TOTAL COST", "style": { "font-weight": "bold", "font-size": "1.00em" } }],
            "cell_style": { "text-align": "left" }
          }
        ],
        "is_header": false
      },
      {
        "index": 1,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 2,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 3,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 4,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 5,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 6,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 7,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 8,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 9,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 10,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 11,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 12,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 13,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 14,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 15,
        "cells": [
          { "index": 0, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      },
      {
        "index": 16,
        "cells": [
          {
            "index": 0,
            "children": [
              { "text": "TOTAL COST OF TRAINING", "style": { "font-weight": "bold", "font-size": "1.00em" } }
            ],
            "cell_style": { "text-align": "left" }
          },
          { "index": 1, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 2, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 3, "children": [], "cell_style": { "text-align": "left" } },
          { "index": 4, "children": [], "cell_style": { "text-align": "left" } }
        ],
        "is_header": false
      }
    ],
    "table_style": {},
    "metadata": {},
    "index": 589
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 590,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 591,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 592,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 593,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 594,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 595 },
  {
    "type": "paragraph",
    "html_tag": "h2",
    "children": [{ "text": "SECTION D \nLEGAL MATTERS", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 596,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [],
    "alignment": "left",
    "metadata": {},
    "index": 597,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D1.\tOverview", "style": { "font-size": "1.27em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 598,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "This section discusses legal matters regarding this Request for Proposals and the procurement process.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 599,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D2.\tExamination and Interpretation of Documents", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 600,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Each Respondent is responsible for ensuring that it has all the information necessary to respond to this Request for Proposals and for independently informing and satisfying itself with respect to any and all information contained in this Request for Proposals, and any or all conditions that may in any way affect its Submission.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 601,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D3.\tUse of Information", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 602,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "By delivering a Proposal in response to this Request for Proposals, the Respondent acknowledges that it has received all information and conducted all analyses it deems necessary to make a Proposal. In furnishing this Request for Proposals, JEA undertakes no obligation to provide Respondents with access to any additional information or to entertain negotiations regarding the contract except as specifically set out herein.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 603,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA shall not be liable for any information or advice or any errors or omissions that may be contained in the Request for Proposals or the addenda, appendices, data, materials, or documents disclosed or provided to the Respondent pursuant to this Request for Proposals or otherwise. JEA makes no representation or warranty, either express or implied, in fact or in law, with respect to the accuracy or completeness of this Request for Proposals or such addenda, appendices, data, materials, or documents, and JEA shall not be responsible for any claim, action, price, loss, damage, or liability whatsoever arising from the Respondent's reliance of use of this Request for Proposals or any addenda, appendices, data, materials, or documents provided by JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 604,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Respondent is responsible for obtaining its own independent financial, legal, accounting, engineering, environmental, technical and other advice on any information included in this Request for Proposals and in any addenda, appendices, data, materials, or documents provided or required by JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 605,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D4.\tTreatment of Information", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 606,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will consider each Proposal as confidential, subject to the provisions and the disclosure requirements of the State of Tennessee public record laws,",
        "style": { "font-size": "1.00em" }
      },
      { "text": " ", "style": { "font-style": "italic", "font-size": "1.00em" } },
      { "text": "and to any other disclosure requirements imposed by law. ", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 607,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 608 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "All information included in the response to the RFP containing proprietary or trade secret information shall contain a cover sheet stating in bold type \"DOCUMENT CONTAINS CONFIDENTIAL PROPRIETARY OR TRADE SECRET INFORMATION\". Information so identified shall be determined as in fact proprietary or trade secret information by the agency within thirty days of receipt. Upon a request for Public Information, the owner of such information shall be notified prior to disclosure, including the agency's determination of whether such information is subject to disclosure.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 609,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA will, however, have the right to make copies of all Proposals for its internal review process and to provide such copies to the staff, Advisors, and representatives of the utility, and other parties as they deem fit. Other than as expressly permitted or required in this Request for Proposals, Respondents or their representatives must not make any public comment, respond to questions in a public forum, or carry out any activities to publicly promote or advertise their qualifications, Proposal, or interest in this procurement.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 610,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D5.\tConfidentiality", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 611,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Respondents must not at any time before, during or after completion of this procurement, divulge any confidential information communicated to or acquired by the Respondent or disclosed by JEA in the course of carrying out this procurement.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 612,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D6.\tOwnership of Documents", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 613,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "All correspondence, documentation, and information provided to the staff of JEA by any Respondent in connection with, or arising out of, this Request for Proposals, will become the property of JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 614,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Respondents further acknowledge that, if they become the Successful Respondent, all reports, data, documents, materials and information of any kind whatsoever prepared in the course of fulfilling the Contract are the sole and exclusive property of JEA and must not be disclosed or released to any person or organization without the prior written consent of the utility.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 615,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D7.\tCosts and Expenses of Respondent", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 616,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "All costs and expenses incurred by a Respondent in the preparation and delivery of its Proposal or in its involvement in facility visits (if any) or in providing any additional information necessary for the evaluation of its Proposal shall be borne solely by the Respondent.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 617,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D8.\tChanges to Respondent", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 618,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "If there is any addition, deletion, or change in the members of a Respondent's team, or a change in the effective control in a Respondent or in any of the members of a Respondent's team, or a material adverse change to a Respondent or any member of a Respondent's team, at any time after a Submission of the Proposal to JEA, the Respondent shall notify JEA in writing by delivery or facsimile at the address set forth in this Request for Proposals within five (5) business days of such addition, deletion, or change. JEA reserves the right to disqualify such Respondent or disallow such addition, deletion, or change in the members of the Respondent's team if, in the sole opinion of JEA, the addition, deletion, or change would have a material impact on the ability of the Respondent to carry out its obligations under the Contract, or on the Proposal submitted by the Respondent to JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 619,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 620 },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D9.\tRights of JEA", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 621,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "It is required that all submissions made pursuant to this Request for Proposals shall be in accordance with the laws applicable of the State of Tennessee and the United States of America.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 622,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Notwithstanding anything else in this Request for Proposals, JEA has the right to change the dates, schedule, deadlines, process, and requirements described in this Request for Proposals or to reject any or all of the Proposals or to change the limits and scope of the procurement, or to cancel this Request for Proposals or the procurement for any reason whatsoever, without incurring any liability for costs and damages incurred by any Respondent.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 623,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "In particular, JEA reserves the right to reject any Proposal whether or not completed properly and whether or not it contains all required information. JEA is not bound to evaluate or accept any Proposal. JEA may proceed as, in their sole and absolute discretion, they determine, following receipt of the Proposal, including, without limitation, issuing a second or modified Request for Proposals for the procurement.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 624,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA expressly reserves the right to require clarification or accept new or additional information from any or all of the Respondents without offering other Respondents the opportunity to amend their Proposals or to provide new or additional information and to conduct facility visits with any or all Respondents. ",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 625,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA reserves the right to waive any irregularity, nonconformity, or non-compliance in or by any Submission.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 626,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA reserves the right to consider or not consider comments made in any Respondent submission and to make changes at the sole discretion of JEA.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 627,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D10.\tNo Lobbying and Points of Contact", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 628,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Other than as expressly permitted or required in this Request for Proposals, Respondents or their representatives must not make any public comment, respond to questions in a public forum, or carry out any activities to publicly promote or advertise their qualifications, their proposal, or their interest in this competitive procurement process.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 629,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Other than as expressly permitted or required in this Request for Proposals, any attempt on the part of any Respondent or any of its employees, servants, agents, contractors, or representatives to contact any of the following persons, directly or indirectly, with respect to this procurement, will lead to disqualification:",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 630,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Any member of the working group or evaluation team", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 631,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Any expert or other advisor assisting JEA or the evaluation team", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 632,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Any member of JEA", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 633,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [{ "text": "Any JEA Board member", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 634,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      { "text": "Any other persons connected in any way with this procurement.", "style": { "font-size": "1.00em" } }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 635,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  { "type": "page_break", "html_tag": "", "children": [], "metadata": {}, "index": 636 },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "For greater certainty, Respondents may not communicate with JEA regarding this procurement except through the single point of contact as identified in this Request for Proposals.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 637,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D.11\tNo Collusion", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 638,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "No Respondent shall discuss or communicate with any other Respondent (or any employee, representative, or agent of any other Respondent) about the preparation of the Proposals. Each Respondent's Proposal shall be prepared without any connection, knowledge, comparison of information, or arrangement with any other Respondent (or any employee, representative, or agent thereof) and each Respondent will be responsible to ensure that its participation in this Request for Proposals process is conducted fairly and without collusion or fraud.",
        "style": { "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 639,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D.12\tNo Conflict", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 640,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "Respondents are required to disclose in their Proposals, and on an ongoing basis thereafter, any conflict of interest, real or perceived, that exists now or may exist in the future, with respect to this procurement.",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      },
      { "text": " ", "style": { "font-size": "1.00em" } },
      {
        "text": "JEA reserves the right to disqualify any Respondent that, in the sole opinion of JEA, has a conflict of interest with respect to this competitive procurement process, whether such a conflict exists now or arises in the future. Other firms or persons so found that may be contracted or retained by JEA to work on the project will also be ineligible.",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 641,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D.13\tLitigation", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 642,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "At the discretion of JEA, JEA reserves the right not to accept or evaluate a Proposal or to enter into the Contract where:",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 643,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "The Respondent, any member of the Respondent's team identified in its Proposal, any corporation that is an affiliate of or successor to the Respondent or member of the Respondent's team, or an officer, director or shareholder of any of the foregoing, has been involved in litigation with JEA, within the five year period immediately preceding the date on which this Request for Proposals was published;",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 644,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "JEA has made a claim against the Respondent, a member of the Respondent's team identified in its Proposal, any corporation that is an affiliate of or successor to the Respondent or member of the Respondent's team, or an officer, director or shareholder of any of the foregoing, under a Proposal bond, performance bond or a warranty bond within the five year period immediately preceding the date on which this Request for Proposals was published;",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 645,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "In the opinion of Legal Counsel or its staff, there are reasonable grounds to believe that it would not be in the best interest of JEA to enter into a Contract with a Respondent or any person or entity including (without limiting the foregoing) the conviction of that Respondent or any person or entity with whom that Respondent is not at arm's length within the meaning of the Laws of the State of ",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      },
      { "text": "Tennessee ", "style": { "font-size": "1.00em" } },
      {
        "text": "and the United States of America of any offence.",
        "style": { "font-size": "1.00em", "color": "#1D1D1D" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 646,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "h3",
    "children": [{ "text": "D.14\tFailure to Comply", "style": { "font-size": "1.00em" } }],
    "alignment": "left",
    "metadata": {},
    "index": 647,
    "is_list": false,
    "list_type": null,
    "list_level": null
  },
  {
    "type": "paragraph",
    "html_tag": "p",
    "children": [
      {
        "text": "FAILURE TO COMPLY WITH ANY REQUIREMENT OF THIS REQUEST FOR PROPOSALS MAY RESULT IN DISQUALIFICATION OF THE RESPONDENT.",
        "style": { "font-weight": "bold", "font-size": "1.00em" }
      }
    ],
    "alignment": "left",
    "metadata": {},
    "index": 648,
    "is_list": false,
    "list_type": null,
    "list_level": null
  }
]`
