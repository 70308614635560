/// <reference types="./questions.d.mts" />
import * as $proposal from "../../../../common/data/proposal.mjs";
import * as $question from "../../../../common/data/question.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $data from "../../../data/model/data.mjs";
import * as $route from "../../../data/route.mjs";
import { Ok, Error, makeError, isEqual } from "../../../gleam.mjs";

function filter_questions_on_status(questions, status) {
  return $bool.guard(
    isEqual(status, new $proposal.AllStatus()),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question_obj = _use0[1];
          if (status instanceof $proposal.Validated) {
            return question_obj.validated;
          } else if (status instanceof $proposal.InReview) {
            return !question_obj.validated;
          } else {
            throw makeError(
              "panic",
              "data/model/computed/questions",
              30,
              "",
              "Should be handled earlier",
              {}
            )
          }
        },
      );
    },
  );
}

function filter_questions_on_owner(questions, owner) {
  return $bool.guard(
    $option.is_none(owner),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question = _use0[1];
          return isEqual(question.owner, owner);
        },
      );
    },
  );
}

export function displayed(data) {
  let $ = data.route;
  if ($ instanceof $route.Projects &&
  $[0] instanceof $route.ProjectProposals &&
  $[0].proposal instanceof $route.ShowProposal) {
    let id = $[0].proposal.id;
    let _pipe = $data.get_proposal_questions(data, id);
    let _pipe$1 = filter_questions_on_status(
      _pipe,
      data.proposal_filters.status,
    );
    let _pipe$2 = filter_questions_on_owner(
      _pipe$1,
      data.proposal_filters.owner,
    );
    return new Ok(_pipe$2);
  } else {
    return new Error(undefined);
  }
}
