/// <reference types="./add.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $proposal_builder from "../../../common/data/ephemeral/proposal_builder.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $dropdown from "../../../ds/ds/components/dropdown.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $pp from "../../../ds/ds/components/profile_picture.mjs";
import * as $stepper from "../../../ds/ds/components/progress/stepper.mjs";
import * as $table from "../../../ds/ds/components/table.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $illustrations from "../../../ds/ds/illustrations.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $sk from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $model from "../../data/model.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { Ok, Error, toList, prepend as listPrepend, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import * as $common from "../../view/common.mjs";
import { select_columns } from "../../view/components/spreadsheet_selector.mjs";
import * as $dropdown_styles from "../../view/styles/dropdowns.mjs";
import * as $s from "../../view/styles/proposals.mjs";

function wizard_stepper(model) {
  let data = $bright.data(model);
  return $stepper.steps(
    data.proposal_builder.step,
    toList([
      (() => {
        let _pipe = $stepper.step();
        return $stepper.description(_pipe, "Enter project details");
      })(),
      (() => {
        let _pipe = $stepper.step();
        return $stepper.description(_pipe, "Upload file");
      })(),
      (() => {
        let _pipe = $stepper.step();
        return $stepper.description(_pipe, "Select columns");
      })(),
    ]),
  );
}

function center(children) {
  return $layout.column(
    toList([
      $layout.align("center"),
      $layout.justify("center"),
      $layout.flex("1"),
      $layout.gap(12),
    ]),
    toList([]),
    children,
  );
}

function loading() {
  return center(
    toList([
      $layout.row(
        toList([$layout.align("baseline")]),
        toList([]),
        toList([
          $h.div(
            toList([$a.class$($magic.class_name($display.md()))]),
            toList([$h.text("Loading")]),
          ),
          $s.typing_indicator(4),
        ]),
      ),
      $h.div(
        toList([$a.class$($magic.class_name($text.md($text.regular)))]),
        toList([$h.text("Please wait")]),
      ),
    ]),
  );
}

function update_date(content) {
  let $ = $utils.parse_input_time(content);
  if ($.isOk()) {
    let time = $[0];
    return new $msg.UserUpdatedProjectDeadline(time);
  } else {
    throw makeError(
      "panic",
      "view/projects/add",
      100,
      "update_date",
      "Invalid date from browser",
      {}
    )
  }
}

function project_attachments(model) {
  let data = $bright.data(model);
  return $layout.column(
    toList([$layout.gap(6)]),
    toList([]),
    toList([
      $s.details_owner_label(toList([]), toList([$h.text("Attachments")])),
      $layout.column(
        toList([$layout.gap(12)]),
        toList([]),
        toList([
          $h.label(
            toList([]),
            toList([
              $s.file_input(toList([]), toList([$h.text("Select files")])),
              $h.input(
                toList([
                  $a.type_("file"),
                  $a.style(toList([["display", "none"]])),
                  $a.attribute("multiple", ""),
                  (() => {
                    let _pipe = toList([
                      ".xls",
                      ".xlsx",
                      $mime_types.to_string(new $mime_types.Xlsx()),
                    ]);
                    let _pipe$1 = $string.join(_pipe, ",");
                    return ((_capture) => {
                      return $a.attribute("accept", _capture);
                    })(_pipe$1);
                  })(),
                  $utils.on_files_change(
                    (var0) => {
                      return new $msg.UserAddedProjectAttachments(var0);
                    },
                  ),
                ]),
              ),
            ]),
          ),
          $layout.row(
            toList([$layout.gap(10), $layout.wrap("wrap")]),
            toList([]),
            (() => {
              let attachments = data.proposal_builder.project_attachments;
              return $list.index_map(
                attachments,
                (file, index) => {
                  let on_remove = $e.on_click(
                    new $msg.UserRemovedProjectAttachment(index),
                  );
                  return $s.file_card(
                    toList([]),
                    toList([
                      $layout.row(
                        toList([$layout.justify("space-between")]),
                        toList([]),
                        toList([
                          $icons.small($common.select_icon(file.mime_type)),
                          $s.close_button(toList([on_remove])),
                        ]),
                      ),
                      $h.text(file.name),
                    ]),
                  );
                },
              );
            })(),
          ),
        ]),
      ),
    ]),
  );
}

function on_remove_collaborator(user_id) {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      $e.stop_propagation(event);
      return new Ok(new $msg.UserRemovedCollaborator(user_id));
    },
  );
}

function add_collaborators(collaborators, users, owner_id) {
  let _pipe = toList([
    $layout.gap(6),
    $layout.align("start"),
    $layout.position("relative"),
  ]);
  return $layout.column(
    _pipe,
    toList([]),
    toList([
      $s.details_owner_label(toList([]), toList([$h.text("Collaborators")])),
      $dropdown.dropdown(
        toList([
          $dropdown.placeholder("Add collaborators"),
          $dropdown.icon($icons.add_user()),
          $dropdown.input_class($s.details_collaborators_class()),
          $dropdown.on_selected(
            (var0) => { return new $msg.UserAddedCollaborator(var0); },
          ),
        ]),
        listPrepend(
          $dropdown.section("Select collaborators"),
          $list.filter_map(
            users,
            (user) => {
              let is_owner = user.id === owner_id;
              let is_contributor = $list.any(
                user.roles,
                (r) => { return r.name === "Contributor"; },
              );
              let is_not_selectable = !is_contributor || is_owner;
              return $bool.guard(
                is_not_selectable,
                new Error(undefined),
                () => {
                  let id = user.id;
                  let name = user.name;
                  let picture = user.picture;
                  let initials = user.initials;
                  let icon = $pp.from_user(picture, initials, new $pp.Medium());
                  return new Ok(
                    (() => {
                      let _pipe$1 = $dropdown.choice(name, id);
                      return $dropdown.with_icon(_pipe$1, icon);
                    })(),
                  );
                },
              );
            },
          ),
        ),
      ),
      $s.collaborators_list(
        toList([]),
        $list.map(
          collaborators,
          (user_id) => {
            let user = $list.find(users, (u) => { return u.id === user_id; });
            let picture = $result.map(user, (u) => { return u.picture; });
            let initials = $result.map(user, (u) => { return u.initials; });
            let name = $result.map(user, (u) => { return u.name; });
            return $s.details_owner_picture_deletion(
              toList([]),
              toList([
                $pp.from_user(
                  $result.unwrap(picture, new $option.None()),
                  $result.unwrap(initials, "?"),
                  new $pp.Small(),
                ),
                $h.text($result.unwrap(name, "Unknown name")),
                $s.details_collaborators_icon_delete(
                  toList([on_remove_collaborator(user_id)]),
                  $icons.cross(),
                ),
              ]),
            );
          },
        ),
      ),
    ]),
  );
}

function choice_class(bordered, bordered_cl, cl) {
  let $ = (() => {
    if (bordered) {
      return [bordered_cl(), $sk.border_radius($size.px(8))];
    } else {
      return [cl(), $sk.none()];
    }
  })();
  let base = $[0];
  let border = $[1];
  return $sk.class$(
    toList([$sk.compose(base), $sk.padding($size.px(10)), border]),
  );
}

function success_class(bordered) {
  return choice_class(
    bordered,
    $dropdown_styles.bordered_success,
    $dropdown_styles.success,
  );
}

function error_class(bordered) {
  return choice_class(
    bordered,
    $dropdown_styles.bordered_error,
    $dropdown_styles.error,
  );
}

function qualification_matrix_actions(disabled) {
  let confirm = $e.on_click(new $msg.UserConfirmedProposalQualificationMatrix());
  return $layout.row(
    toList([$layout.justify("space-between"), $layout.gap(16)]),
    toList([]),
    toList([
      $button.secondary(
        toList([$a.disabled(true)]),
        toList([$h.text("Customize your matrix")]),
      ),
      $layout.row(
        toList([$layout.gap(16)]),
        toList([]),
        toList([
          $button.primary(
            toList([confirm, $a.disabled(disabled)]),
            toList([$h.text("Confirm")]),
          ),
        ]),
      ),
    ]),
  );
}

function total_questions(wrapper, wording, amount, total) {
  let amount$1 = $int.to_string($float.round(amount));
  let total$1 = $int.to_string($float.round(total));
  return wrapper(
    toList([]),
    toList([
      $h.text(((((amount$1 + "/") + total$1) + " ") + wording) + " flags"),
    ]),
  );
}

function qualification_matrix_progress_bar(success, failure, total) {
  return $layout.row(
    toList([$layout.gap(16), $layout.align("center")]),
    toList([]),
    toList([
      total_questions($s.success_text, "Green", success, total),
      $s.progress_bar(success, failure, total),
      total_questions($s.error_text, "Red", failure, total),
    ]),
  );
}

function qualification_matrix_decision() {
  let use_qualification_matrix = "Would you like to use a Go/No-Go Matrix?";
  let save_time = "This will help you determine whether to respond to the request and prioritize it effectively.";
  return $s.layout_wrapper(
    toList([]),
    toList([
      $illustrations.lightbulb_flash(),
      $layout.column(
        toList([$layout.gap(16)]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(8)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text(use_qualification_matrix)]),
              ),
              $s.details_subtitle(toList([]), toList([$h.text(save_time)])),
            ]),
          ),
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $button.primary(
                toList([
                  $e.on_click(new $msg.UserUsedProposalQualificationMatrix()),
                ]),
                toList([$h.text("Yes")]),
              ),
              $button.secondary(
                toList([
                  $e.on_click(new $msg.UserSkippedProposalQualificationMatrix()),
                ]),
                toList([
                  $h.text("Maybe next time"),
                  $button.icon($icons.right_arrow()),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function qualification_matrix(builder) {
  let $ = builder.proposal_qualification_matrix;
  if ($ instanceof $option.None) {
    return qualification_matrix_decision();
  } else {
    let qualification_matrix$1 = $[0];
    let $1 = $proposal.count_qualification_matrix_answers(
      qualification_matrix$1,
    );
    let success = $1[0];
    let failure = $1[1];
    let total = $1[2];
    return $el.fragment(
      toList([
        $table.table(
          toList([
            $table.headers(
              toList([
                $table.header("Qualification Item"),
                $table.header("Clarifying question"),
                (() => {
                  let _pipe = $table.header("Answer");
                  return $table.fit(_pipe);
                })(),
              ]),
            ),
            $table.body(
              $list.map(
                qualification_matrix$1,
                (_use0) => {
                  let item = _use0[0];
                  let answer = _use0[1];
                  return $table.row(
                    toList([
                      $table.cell($h.text(item.title)),
                      $table.cell($h.text(item.content)),
                      $table.cell(
                        $dropdown.dropdown(
                          toList([
                            $dropdown.selected(new $option.Some(answer)),
                            $dropdown.show_arrow(),
                            $dropdown.placeholder("Choose answer"),
                            $dropdown.on_selected(
                              ((_capture) => {
                                return new $msg.UserSelectedProposalQualificationMatrix(
                                  item.id,
                                  _capture,
                                );
                              }),
                            ),
                            $dropdown.panel_class(
                              $sk.class$(
                                toList([
                                  $sk.gap($size.px(10)),
                                  $sk.padding($size.px(10)),
                                ]),
                              ),
                            ),
                            $dropdown.input_class(
                              (() => {
                                if (answer instanceof $qualification_matrix.NotSelected) {
                                  return $sk.class$(toList([]));
                                } else if (answer instanceof $qualification_matrix.Yes) {
                                  return success_class(true);
                                } else {
                                  return error_class(true);
                                }
                              })(),
                            ),
                          ]),
                          toList([
                            (() => {
                              let _pipe = $dropdown.choice(
                                "Yes",
                                new $qualification_matrix.Yes(),
                              );
                              return $dropdown.with_class(
                                _pipe,
                                success_class(false),
                              );
                            })(),
                            (() => {
                              let _pipe = $dropdown.choice(
                                "No",
                                new $qualification_matrix.No(),
                              );
                              return $dropdown.with_class(
                                _pipe,
                                error_class(false),
                              );
                            })(),
                          ]),
                        ),
                      ),
                    ]),
                  );
                },
              ),
            ),
          ]),
        ),
        qualification_matrix_progress_bar(success, failure, total),
        qualification_matrix_actions((success + failure) === 0.0),
      ]),
    );
  }
}

function on_remove_language(language) {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      $e.stop_propagation(event);
      return new Ok(new $msg.UserRemovedProjectLanguage(language));
    },
  );
}

function add_languages(model, languages) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let is_hidden = $data.is_translations_hidden(data);
  return $bool.lazy_guard(
    is_hidden,
    $el.none,
    () => {
      let _pipe = toList([
        $layout.gap(6),
        $layout.align("start"),
        $layout.position("relative"),
      ]);
      return $layout.column(
        _pipe,
        toList([]),
        toList([
          $s.details_owner_label(toList([]), toList([$h.text("Languages")])),
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("Add language"),
              $dropdown.icon($icons.translate()),
              $dropdown.input_class($s.details_collaborators_class()),
              $dropdown.on_selected(
                (var0) => { return new $msg.UserAddedProjectLanguage(var0); },
              ),
            ]),
            listPrepend(
              $dropdown.section("Select language"),
              $list.map(
                languages,
                (language) => {
                  return $dropdown.choice(
                    $translate.display_language(language),
                    language,
                  );
                },
              ),
            ),
          ),
          $s.collaborators_list(
            toList([]),
            $list.map(
              data.proposal_builder.project_languages,
              (language) => {
                return $s.details_owner_picture_deletion(
                  toList([]),
                  toList([
                    $h.text($translate.display_language(language)),
                    $s.details_collaborators_icon_delete(
                      toList([on_remove_language(language)]),
                      $icons.cross(),
                    ),
                  ]),
                );
              },
            ),
          ),
        ]),
      );
    },
  );
}

function on_remove_tag(tag) {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      $e.stop_propagation(event);
      return new Ok(new $msg.UserRemovedProjectTag(tag));
    },
  );
}

function add_tags(model, tags) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let _pipe = toList([
    $layout.gap(6),
    $layout.align("start"),
    $layout.position("relative"),
  ]);
  return $layout.column(
    _pipe,
    toList([]),
    toList([
      $s.details_owner_label(toList([]), toList([$h.text("Tags")])),
      $dropdown.dropdown(
        toList([
          $dropdown.placeholder("Add tag"),
          $dropdown.icon($icons.price_tag()),
          $dropdown.input_class($s.details_collaborators_class()),
          $dropdown.on_selected(
            (var0) => { return new $msg.UserAddedProjectTag(var0); },
          ),
        ]),
        listPrepend(
          $dropdown.section("Select tag"),
          $list.map(tags, (tag) => { return $dropdown.choice(tag.name, tag); }),
        ),
      ),
      $s.collaborators_list(
        toList([]),
        $list.map(
          data.proposal_builder.project_tags,
          (tag) => {
            return $s.details_owner_picture_deletion(
              toList([]),
              toList([
                $h.text(tag.name),
                $s.details_collaborators_icon_delete(
                  toList([on_remove_tag(tag)]),
                  $icons.cross(),
                ),
              ]),
            );
          },
        ),
      ),
    ]),
  );
}

function enter_project_details(model) {
  let data = $bright.data(model);
  let $ = data.user;
  if (!($ instanceof Some)) {
    throw makeError(
      "let_assert",
      "view/projects/add",
      106,
      "enter_project_details",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let owner = $[0];
  let user = $list.find(data.users, (u) => { return u.id === owner.sub; });
  let name = (() => {
    let _pipe = $result.map(user, (u) => { return u.name; });
    return $result.unwrap(_pipe, "Unknown");
  })();
  return $s.details_wrapper(
    toList([$e.on_submit(new $msg.UserSubmittedProjectDetails())]),
    toList([
      $illustrations.documents_stack(),
      $layout.column(
        toList([$layout.gap(64), $layout.flex("1"), $layout.overflow("auto")]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text("Enter proposal details")]),
              ),
              $input.input(
                toList([
                  $input.label("Name"),
                  $input.value(data.proposal_builder.project_name),
                  $input.on_input(
                    (var0) => { return new $msg.UserUpdatedProjectName(var0); },
                  ),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Due Date"),
                  $input.type_("date"),
                  $input.on_input(update_date),
                  (() => {
                    let _pipe = data.proposal_builder.project_deadline;
                    let _pipe$1 = $utils.time_to_normalized_date(_pipe, "-");
                    return $input.value(_pipe$1);
                  })(),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Client"),
                  $input.value(data.proposal_builder.project_client),
                  $input.on_input(
                    (var0) => { return new $msg.UserUpdatedProjectClient(var0); },
                  ),
                ]),
              ),
              $layout.column(
                toList([$layout.gap(6)]),
                toList([]),
                toList([
                  $s.details_owner_label(toList([]), toList([$h.text("Owner")])),
                  $s.details_owner_picture(
                    toList([]),
                    toList([
                      $pp.from_user(
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.picture; },
                          );
                          return $result.unwrap(_pipe, new $option.None());
                        })(),
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.initials; },
                          );
                          return $result.unwrap(_pipe, "?");
                        })(),
                        new $pp.Medium(),
                      ),
                      $h.text(name),
                    ]),
                  ),
                ]),
              ),
              (() => {
                let _pipe = data.proposal_builder.project_collaborators;
                let _pipe$1 = add_collaborators(_pipe, data.users, owner.sub);
                return $el.map(
                  _pipe$1,
                  (var0) => {
                    return new $msg.UserEditedProjectCollaborator(var0);
                  },
                );
              })(),
              add_languages(model, $translate.language_list),
              add_tags(model, data.tags),
            ]),
          ),
          $layout.row(
            toList([$layout.justify("end")]),
            toList([]),
            toList([
              $button.primary(
                toList([$a.type_("submit")]),
                toList([$h.text("Confirm")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function upload_file(_) {
  return $s.layout_wrapper(
    toList([]),
    toList([
      $s.drag_and_drop_zone(
        toList([]),
        toList([
          $s.icon_wrapper(toList([]), toList([$icons.upload()])),
          $layout.column(
            toList([$layout.gap(16), $layout.align("center")]),
            toList([]),
            toList([
              $s.drop_title(
                toList([]),
                toList([$h.text("Select your file to upload")]),
              ),
              $s.drop_subtitle(
                toList([]),
                toList([$h.text("We support excel questionnaires today.")]),
              ),
            ]),
          ),
          $h.label(
            toList([]),
            toList([
              $s.file_input(toList([]), toList([$h.text("Select files")])),
              $h.input(
                toList([
                  $a.type_("file"),
                  $a.style(toList([["display", "none"]])),
                  $a.attribute(
                    "accept",
                    (() => {
                      let _pipe = toList([
                        ".xls",
                        ".xlsx",
                        $mime_types.to_string(new $mime_types.Xlsx()),
                      ]);
                      return $string.join(_pipe, ",");
                    })(),
                  ),
                  $utils.on_files_change(
                    (files) => {
                      let $ = $list.first(files);
                      if (!$.isOk()) {
                        throw makeError(
                          "let_assert",
                          "view/projects/add",
                          477,
                          "",
                          "Pattern match failed, no pattern matched the value.",
                          { value: $ }
                        )
                      }
                      let file = $[0];
                      let _pipe = file;
                      let _pipe$1 = new $msg.UserSelectedXlsx(_pipe);
                      return new $msg.ProposalBuilderHandledSpreadsheetSelector(
                        _pipe$1,
                      );
                    },
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_body(model, project_id, is_loading) {
  let data = $bright.data(model);
  return $bool.guard(
    is_loading,
    loading(),
    () => {
      let $ = data.proposal_builder.step;
      if ($ === 1) {
        return enter_project_details(model);
      } else if ($ === 2) {
        return upload_file(project_id);
      } else if ($ === 3) {
        let selector = data.proposal_builder.proposal_spreadsheet;
        let cta_text = "Generate your responses";
        let _pipe = select_columns(selector, cta_text, false);
        return $el.map(
          _pipe,
          (var0) => {
            return new $msg.ProposalBuilderHandledSpreadsheetSelector(var0);
          },
        );
      } else {
        throw makeError(
          "panic",
          "view/projects/add",
          68,
          "",
          "Unexpected step",
          {}
        )
      }
    },
  );
}

export function view(model, project_id, is_loading) {
  return $el.fragment(
    toList([wizard_stepper(model), view_body(model, project_id, is_loading)]),
  );
}
