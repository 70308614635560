/// <reference types="./organization.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function members(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(16))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function invitation_block(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("start"),
      $s.gap(px(16)),
    ]),
  );
  return $h.form(_pipe, attrs, children);
}

export function connectors_block(attrs, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.gap(px(32))]));
  return $h.div(_pipe, attrs, children);
}

export function connectors_card(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("center"),
      $s.padding_("0px 48px 32px 48px"),
      $s.gap(px(16)),
      $s.flex("1"),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_spacer(attrs, children) {
  let _pipe = $s.class$(toList([$s.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function connector_titles(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(8))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connector_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.xl($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function connector_content(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.regular)),
      $s.color($colors.light.paragraph),
      $s.text_align("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_row(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(32)),
      $s.justify_content("space-between"),
      $s.align_items("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_description(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.gap(px(16)), $s.align_items("center")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function connectors_main_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.md($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function connector_row_actions(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(12))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_icon(icon) {
  let _pipe = $s.class$(toList([$s.width(px(60)), $s.height(px(60))]));
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function connectors_card_icon(icon) {
  let _pipe = $s.class$(toList([$s.width(px(128)), $s.height(px(128))]));
  return $h.div(_pipe, toList([]), toList([icon]));
}
