/// <reference types="./question.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $question from "../../../common/data/question.mjs";
import { Question } from "../../../common/data/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

export function ask_ai_rewording(data, proposal_id, question_id, asked, custom) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (_) => {
          let at = toList([
            "proposals",
            proposal_id,
            "questions",
            question_id,
            "ai",
          ]);
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Patch());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          let _pipe$3 = $ask.with$(
            _pipe$2,
            $json.object(
              toList([
                ["kind", $json.string(asked)],
                ["custom", $json.string(custom)],
              ]),
            ),
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}

export function fetch_data_point_translation(
  data_point_id,
  model,
  dispatch,
  access_token
) {
  let exist = (() => {
    let _pipe = model.translations;
    let _pipe$1 = $dict.values(_pipe);
    return $list.find(
      _pipe$1,
      (translation) => { return translation.target_id === data_point_id; },
    );
  })();
  if (!exist.isOk()) {
    let _pipe = $ask.to(
      new $ask.Heimdall(),
      toList(["translations", "point", data_point_id]),
    );
    let _pipe$1 = $ask.bearing(_pipe, access_token);
    let _pipe$2 = $ask.expect(
      _pipe$1,
      $dynamic.list($translate.decode_translation),
    );
    let _pipe$3 = $ask.notify(
      _pipe$2,
      (q) => { return dispatch(new $msg.ApiReturnedTranslations(q)); },
    );
    $ask.run(_pipe$3)
    return undefined;
  } else {
    return undefined;
  }
}

export function query_proposal_questions(data, timeout, proposal_id) {
  let $ = data.access_token;
  if ($ instanceof $option.None) {
    return $effect.from(
      (dispatch) => {
        return $utils.set_nil_timeout(
          timeout,
          () => {
            let _pipe = new $msg.ApplicationQueriedProposalQuestions(
              1000,
              proposal_id,
            );
            return dispatch(_pipe);
          },
        );
      },
    );
  } else {
    let access_token = $[0];
    return $effect.from(
      (dispatch) => {
        let at = toList(["proposals", proposal_id, "questions"]);
        let _pipe = $ask.to(new $ask.Heimdall(), at);
        let _pipe$1 = $ask.bearing(_pipe, access_token);
        let _pipe$2 = $ask.expect(
          _pipe$1,
          $dynamic.decode3(
            (a, b, c) => { return [a, b, c]; },
            $dynamic.field("questions", $dynamic.list($question.decode)),
            $dynamic.field(
              "data_sources",
              $dynamic.dict($dynamic.string, $dynamic.list($data_source.decode)),
            ),
            $dynamic.field(
              "data_points",
              $dynamic.dict($dynamic.string, $dynamic.list($data_point.decode)),
            ),
          ),
        );
        let _pipe$3 = $ask.notify(
          _pipe$2,
          (q) => {
            let _pipe$3 = q[2];
            let _pipe$4 = $dict.values(_pipe$3);
            let _pipe$5 = $list.flatten(_pipe$4);
            let _pipe$6 = $list.map(_pipe$5, (dp) => { return dp.id; });
            let _pipe$7 = $list.unique(_pipe$6);
            $list.map(
              _pipe$7,
              (_capture) => {
                return fetch_data_point_translation(
                  _capture,
                  data,
                  dispatch,
                  access_token,
                );
              },
            )
            return dispatch(
              new $msg.ApiReturnedProposalsQuestions(q[0], q[1], q[2]),
            );
          },
        );
        let _pipe$4 = $ask.error(
          _pipe$3,
          (_) => { return dispatch(new $msg.ApiTimeoutedProposalsQuestions()); },
        );
        $ask.run(_pipe$4)
        return undefined;
      },
    );
  }
}

function should_update_question(question, content) {
  let content$1 = $string.trim(content);
  let answer = (() => {
    let _pipe = question.answer.custom;
    return $option.or(_pipe, question.answer.long);
  })();
  return !isEqual(answer, new $option.Some(content$1));
}

export function prepare_updating_question(data, question, content) {
  let should_update = should_update_question(question, content);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.display_modal,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.questions,
        _record.route,
        _record.language,
        running_requests,
        _record.tags,
        _record.translations,
        _record.user,
        _record.users,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
      );
    },
  );
}

export function update_question(data, question, content) {
  let should_update = should_update_question(question, content);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        data,
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              let at = toList([
                "proposals",
                question.proposal_id,
                "questions",
                question.id,
              ]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(toList([["answer", $json.string(content)]])),
              );
              let _pipe$4 = $ask.expect(_pipe$3, $question.decode);
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (q) => {
                  return dispatch(new $msg.ApiReturnedProposalQuestion(q));
                },
              );
              let _pipe$6 = $ask.error(
                _pipe$5,
                (_) => {
                  return dispatch(
                    new $msg.ApiRejectedProposalQuestion(question),
                  );
                },
              );
              let _pipe$7 = $ask.finally$(
                _pipe$6,
                () => {
                  return dispatch(
                    new $msg.ApplicationCompletedRequest(
                      "question-" + question.id,
                    ),
                  );
                },
              );
              $ask.run(_pipe$7)
              return undefined;
            },
          );
        },
      );
    },
  );
}

function should_update_question_yes_no(question, yes_no) {
  let answer = question.answer.yes_no;
  return !isEqual(answer, new $option.Some(yes_no));
}

export function prepare_updating_question_yes_no(data, question, yes_no) {
  let should_update = should_update_question_yes_no(question, yes_no);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-yes-no-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.display_modal,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.questions,
        _record.route,
        _record.language,
        running_requests,
        _record.tags,
        _record.translations,
        _record.user,
        _record.users,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
      );
    },
  );
}

export function update_question_yes_no(data, question, yes_no) {
  let should_update = should_update_question_yes_no(question, yes_no);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        data,
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              let at = toList([
                "proposals",
                question.proposal_id,
                "questions",
                question.id,
              ]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(toList([["yes_no", $json.string(yes_no)]])),
              );
              let _pipe$4 = $ask.expect(_pipe$3, $question.decode);
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (q) => {
                  return dispatch(new $msg.ApiReturnedProposalQuestion(q));
                },
              );
              let _pipe$6 = $ask.finally$(
                _pipe$5,
                () => {
                  return dispatch(
                    new $msg.ApplicationCompletedRequest(
                      "question-yes-no-" + question.id,
                    ),
                  );
                },
              );
              $ask.run(_pipe$6)
              return undefined;
            },
          );
        },
      );
    },
  );
}

function should_update_question_choice(question, choice) {
  let answer = question.answer.choice;
  return !isEqual(answer, new $option.Some(choice));
}

export function prepare_updating_question_choice(data, question, choice) {
  let should_update = should_update_question_choice(question, choice);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-choice-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.display_modal,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.questions,
        _record.route,
        _record.language,
        running_requests,
        _record.tags,
        _record.translations,
        _record.user,
        _record.users,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
      );
    },
  );
}

export function update_question_choice(data, question, choice) {
  let should_update = should_update_question_choice(question, choice);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        data,
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              let at = toList([
                "proposals",
                question.proposal_id,
                "questions",
                question.id,
              ]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(toList([["choice", $json.string(choice)]])),
              );
              let _pipe$4 = $ask.expect(_pipe$3, $question.decode);
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (q) => {
                  return dispatch(new $msg.ApiReturnedProposalQuestion(q));
                },
              );
              let _pipe$6 = $ask.finally$(
                _pipe$5,
                () => {
                  return dispatch(
                    new $msg.ApplicationCompletedRequest(
                      "question-choice-" + question.id,
                    ),
                  );
                },
              );
              $ask.run(_pipe$6)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function update_question_owner(data, question, user_id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let id = question.id;
          let proposal_id = question.proposal_id;
          let at = toList(["proposals", proposal_id, "questions", id, "owner"]);
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
          let _pipe$3 = $ask.expect(_pipe$2, $data_source.decode);
          let _pipe$4 = $ask.with$(
            _pipe$3,
            $json.object(toList([["owner_id", $json.string(user_id)]])),
          );
          let _pipe$5 = $ask.notify(
            _pipe$4,
            (s) => {
              return dispatch(
                new $msg.ContentLibrary(new $msg.ApiReturnedSource(s)),
              );
            },
          );
          $ask.run(_pipe$5)
          return undefined;
        },
      );
    },
  );
}

export function mark_question_as_validated(data, question) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let id = question.id;
          let proposal_id = question.proposal_id;
          let at = toList([
            "proposals",
            proposal_id,
            "questions",
            id,
            "validate",
          ]);
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Patch());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          let _pipe$3 = $ask.expect(_pipe$2, $question.decode);
          let _pipe$4 = $ask.notify(
            _pipe$3,
            (_) => { return dispatch(new $msg.UpsertQuestion(question)); },
          );
          $ask.run(_pipe$4)
          return undefined;
        },
      );
    },
  );
}
